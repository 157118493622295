import { Col, Row, Table, Tag, Tooltip } from 'antd';
import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useListAllVehiclesQuery } from '../../generated/graphql';
import { Vehicle } from '../../types';
import { ContainerTitle, Title } from '../../components/PageTitle/styles';
import { Subtitle } from '../../components/PageSubtitle/styles';

const ContainerIcon = styled.div`
  display: inline-flexbox;
  align-items: center;
`;

const CustomCol = styled(Col)`
  height: 50vh;
  overflow-y: scroll;
  margin: 1rem 0;
`;

export default function Vehicles() {
  const [result] = useListAllVehiclesQuery();

  const { data } = result;

  const vehicles = data?.vehicle ?? [];

  const formatVehicles = vehicles
    .filter((vehicle: Vehicle) => vehicle.type !== 'equipment')
    .map((vehicle: Vehicle, index: number) => ({
      ...vehicle,
      key: index + 1,
    }));

  const formatEquipments = vehicles
    .filter((vehicle: Vehicle) => vehicle.type === 'equipment')
    .map((vehicle: Vehicle, index: number) => ({
      ...vehicle,
      key: index + 1,
    }));

  const columnsTable = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Placa',
      dataIndex: 'plate',
      key: 'plate',
      render: (text: string, record: Vehicle) => {
        return (
          <Link
            to={{
              pathname: `/vehicles-and-equipments/${record.id}`,
            }}
          >
            <Tag style={{ cursor: 'pointer' }} color="#1C6880">
              {record.plate}
            </Tag>
          </Link>
        );
      },
    },
    {
      title: 'Marca',
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: 'Modelo',
      dataIndex: 'model',
      key: 'model',
    },
    // TODO: Implement vehicles form
    // {
    //   title: 'Ações',
    //   key: 'action',
    //   render: (text: string, record: Vehicle) => (
    //     <Tooltip title="Para edição dos veículos, contate o suporte.">
    //       <ContainerIcon>
    //         <FaExclamationTriangle
    //           style={{ color: '#ffe100', marginRight: 8 }}
    //         />
    //         <span>Em manutenção</span>
    //       </ContainerIcon>
    //     </Tooltip>
    //   ),
    // },
  ];

  return (
    <div>
      <ContainerTitle>
        <Title>Veículos e Equipamentos</Title>
      </ContainerTitle>
      <Subtitle>Veículos</Subtitle>
      <Row>
        <CustomCol span={24}>
          <Table
            pagination={false}
            dataSource={formatVehicles}
            columns={columnsTable}
          />
        </CustomCol>
      </Row>
      <Subtitle>Equipamentos</Subtitle>
      <Row>
        <CustomCol span={24}>
          <Table
            pagination={false}
            dataSource={formatEquipments}
            columns={columnsTable}
          />
        </CustomCol>
      </Row>
    </div>
  );
}
