import styled from 'styled-components';

export const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #aaaaaa;
`;

export const Subtitle = styled.h3`
  font-size: 16px;
  margin-bottom: 2rem;
`;
