import { groupBy } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { FaDollarSign } from 'react-icons/fa';
import styled from 'styled-components';
import { Order } from '../../../../helpers/types';
import { useGeneralContext } from '../../contexts/GeneralContext';
import {
  Container,
  Indicator,
  IndicatorContainer,
  Title,
  TitleContainer,
  Value
} from '../AutonomyChart/styles';
import OrdersChartCanvas from './OrdersChartCanvas';

const Icon = styled(FaDollarSign)`
  margin-top: 0.25rem;
`;

interface Props {}

export default function OrdersChart(props: Props) {
  const { orders } = useGeneralContext();

  const totalCost = useMemo(() => {
    return orders
      .reduce((sum: number, order: Order) => sum + order.cost, 0)
      .toFixed(2);
  }, [orders]);

  const data = useMemo(() => {
    const groupedOrders = groupBy(orders, (order: Order) => {
      return moment(order.invoiceDate).format('DD/MM/YY');
    });

    return Object.entries(groupedOrders).map((group) => ({
      date: group[0],
      Valor: group[1].reduce(
        (sum: number, order: Order) => sum + order.cost,
        0
      ),
    }));
  }, [orders]);

  return (
    <Container>
      <TitleContainer>
        <Title>Compras x Período</Title>
      </TitleContainer>
      <IndicatorContainer>
        <Indicator>
          <Icon />
          Total gasto:
          <Value> R$ {totalCost}</Value>
        </Indicator>
      </IndicatorContainer>
      <OrdersChartCanvas data={data} />
    </Container>
  );
}
