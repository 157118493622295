import {
  DatePicker,
  Form,
  InputNumber,
  Select,
  SubmitButton,
} from 'formik-antd';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import * as Yup from 'yup';
import { useTanks } from '../../helpers/hooks';
import { AuthContext } from '../../contexts/AuthProvider';

const { Option } = Select;

const OrderSchema = Yup.object().shape({
  invoice: Yup.number().required('Campo obrigatório'),
  invoiceDate: Yup.date().required('Campo obrigatório'),
  cost: Yup.number().required('Campo obrigatório'),
  quantity: Yup.number().required('Campo obrigatório'),
  tank: Yup.string().required('Campo obrigatorio'),
});

const OrderForm = ({ handleSubmit, selectedOrder }) => {
  const { firebaseOrganizationId } = useContext(AuthContext);

  const { tanks } = useTanks(firebaseOrganizationId);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={
          selectedOrder
            ? {
                ...selectedOrder,
                tank:
                  selectedOrder && selectedOrder.tank && selectedOrder.tank.id,
              }
            : {
                invoice: '',
                invoiceDate: new Date().toISOString(),
                cost: 0,
                quantity: 0,
                tank: undefined,
              }
        }
        validationSchema={OrderSchema}
        onSubmit={handleSubmit}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{
            span: 24,
          }}
        >
          <Form.Item name="invoice" label="Número da nota:">
            <InputNumber
              style={{ width: '100%' }}
              name="invoice"
              placeholder="Insira o número da nota"
            />
          </Form.Item>

          <Form.Item name="invoiceDate" label="Data de compra:">
            <DatePicker
              style={{ width: '100%' }}
              name="invoiceDate"
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
            />
          </Form.Item>

          <Form.Item name="cost" label="Valor:">
            <InputNumber
              style={{ width: '100%' }}
              name="cost"
              placeholder="Insira o valor em R$"
            />
          </Form.Item>

          <Form.Item name="quantity" label="Quantidade:">
            <InputNumber
              style={{ width: '100%' }}
              name="quantity"
              placeholder="Insira a quantidade em litros"
            />
          </Form.Item>

          <Form.Item name="tank" label="Tanque:">
            <Select name="tank" placeholder="Selecione o tanque">
              {tanks.map((tank, key) => (
                <Option value={tank.id} key={key}>
                  {tank.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="submit">
            <SubmitButton style={{ float: 'right' }} type="primary">
              Enviar
            </SubmitButton>
          </Form.Item>
        </Form>
      </Formik>
    </div>
  );
};

export default OrderForm;
