import { DeleteFilled } from '@ant-design/icons';
import { Col, message, Popconfirm, Row, Table, Tooltip } from 'antd';
import { groupBy, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthProvider';
import { db } from '../../firebaseConfig';
import { useEquipmentFuellings } from '../../helpers/hooks';
import { Fuelling } from '../../helpers/types';
import ExcelExportEquipments from './ExcelExportEquipments';
import { EditFuelling } from './FuellingForm';
import { Container, Title } from './styles';

const { Column } = Table;

export function EquipmentsFuellings({ startDate, finalDate }: any) {
  const { firebaseOrganizationId } = useContext(AuthContext);

  const { equipmentFuellings } = useEquipmentFuellings();

  const sortedFuellings = equipmentFuellings
    .filter(({ date }) => date >= startDate && date <= finalDate)
    .sort((a, b) => b.date.getTime() - a.date.getTime());

  const fuellingsByEquipment = groupBy(sortedFuellings, 'car.plate');

  const equipmentsFilters = uniqBy(
    equipmentFuellings
      .map(({ car }) => ({
        text: car.plate,
        value: car.plate || '',
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1)),
    'text'
  );

  const handleDelete = (fuelling: Fuelling) => {
    const orgRef = db.collection('organizations').doc(firebaseOrganizationId);
    const fuellingsRef = orgRef.collection('fuellings');

    fuellingsRef
      .doc(fuelling.id)
      .delete()
      .then(() => {
        message.success('Abastecimento removido com sucesso.');
      });
  };

  return (
    <div className="table-container">
      <Container>
        <Title>Abastecimentos de equipamentos</Title>
        <ExcelExportEquipments fuellings={sortedFuellings} />
      </Container>
      <Row>
        <Col
          style={{
            height: '60vh',
            overflowY: 'auto',
          }}
          span={24}
        >
          <Table pagination={false} dataSource={sortedFuellings} rowKey="id">
            <Column
              title="Data"
              key="date"
              dataIndex="date"
              render={(date: string) => moment(date).format('DD/MM/YY')}
            />
            <Column
              title="Equipamento"
              filters={equipmentsFilters}
              onFilter={(value, record: any) =>
                record.car.plate.indexOf(value) === 0
              }
              render={(value, record: Fuelling) => record.car.plate}
            />
            <Column
              title="Motorista"
              render={(value, record: Fuelling) => {
                return record?.driver?.name ?? '-';
              }}
            />
            <Column
              title="Quantidade"
              key="fuelAmount"
              dataIndex="fuelAmount"
            />
            <Column
              title="Horímetro (h)"
              key="horimeter"
              dataIndex="horimeter"
            />
            <Column
              title="Fornecedor"
              render={(value, record: Fuelling) => {
                if (record.provider) {
                  return record.provider.trade;
                } else {
                  return record?.tank?.name || value;
                }
              }}
            />
            <Column
              title="Valor do litro (R$/L)"
              key="literValue"
              dataIndex="literValue"
              render={(_, record: Fuelling) => {
                const literValue =
                  record.value &&
                  (record?.value / record.fuelAmount).toFixed(2);
                return record?.value ? (
                  <Tooltip title={'Valor total abastecido: R$ ' + record.value}>
                    <span>{literValue + ' *'}</span>
                  </Tooltip>
                ) : (
                  <span>-</span>
                );
              }}
            />
            <Column
              title="Horas corridas (h)"
              dataIndex="hours"
              key="hours"
              render={(_, record: Fuelling) => {
                const equipmentFuellings =
                  fuellingsByEquipment[record?.car?.plate!];

                const equipmentFuellingsIndex = equipmentFuellings.findIndex(
                  (obj) => obj.id === record.id
                );

                const previousFuelling =
                  equipmentFuellings[equipmentFuellingsIndex + 1];

                if (!previousFuelling) return '-';

                return (
                  (record.horimeter! - previousFuelling.horimeter!) /
                  record.fuelAmount
                ).toFixed(2);
              }}
            />
            <Column
              title="Autonomia média (h/L)"
              dataIndex="autonomy"
              key="autonomy"
              render={(_, record: Fuelling) => {
                const equipmentFuellings =
                  fuellingsByEquipment[record?.car?.plate!];

                const equipmentFuellingsIndex = equipmentFuellings.findIndex(
                  (obj) => obj.id === record.id
                );

                const previousFuelling =
                  equipmentFuellings[equipmentFuellingsIndex + 1];

                if (record.filledUp && previousFuelling?.filledUp) {
                  return (
                    (record.horimeter! - previousFuelling.horimeter!) /
                    record.fuelAmount
                  ).toFixed(2);
                } else {
                  return '-';
                }
              }}
            />
            <Column
              title="Ação"
              key="action"
              render={(_: any, record: Fuelling) => (
                <>
                  <EditFuelling fuelling={record} type="EQUIPMENT" />
                  <Popconfirm
                    title="Confirmar remoção?"
                    onConfirm={(_) => handleDelete(record)}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <DeleteFilled style={{ cursor: 'pointer' }} />
                  </Popconfirm>
                </>
              )}
            />
          </Table>
        </Col>
      </Row>
    </div>
  );
}
