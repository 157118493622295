import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { app } from '../../firebaseConfig';
import { message } from 'antd';
import { Container, FormContainer, FormTitle, StyledField, SubmitButton } from './styles';
import authClient from '../../auth-client';

const SigninSchema = Yup.object().shape({
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
  password: Yup.string().required('Campo obrigatório'),
});

interface ILoginForm {
  email: string;
  password: string;
}

export default function SignIn() {
  const handleSubmit = async (values: ILoginForm, { setSubmitting }: FormikHelpers<ILoginForm>) => {
    const { email, password } = values;
    try {
      await Promise.all([
        app.auth().signInWithEmailAndPassword(email, password),
        authClient.signInWithCredential(email, password),
      ]);
    } catch ({ code }) {
      app.auth().signOut();
      authClient.signOut();
      if (code === 'auth/user-not-found') {
        message.warn('Usuário não encontrado');
        return;
      }

      if (code === 'auth/wrong-password') {
        message.warn('Senha incorreta');
        return;
      }

      message.warn('Erro fazer login. Contate o suporte');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <FormContainer>
        <FormTitle>Infleet | Abastecimento</FormTitle>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={SigninSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="form-signin">
              <label
                htmlFor="email"
                style={{
                  display: 'block',
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                E-mail
              </label>
              <StyledField
                id="email"
                name="email"
                type="email"
                placeholder="Insira seu e-mail"
                className="ant-input"
              />
              <ErrorMessage name="email" render={(msg) => <p className="yup-error">{msg}</p>} />

              <label
                htmlFor="password"
                style={{
                  display: 'block',
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                Senha
              </label>
              <StyledField
                id="password"
                name="password"
                type="password"
                className="ant-input"
                placeholder="Insira sua senha"
              />
              <ErrorMessage name="password" render={(msg) => <p className="yup-error">{msg}</p>} />

              <SubmitButton type="submit" disabled={isSubmitting}>
                ENTRAR
              </SubmitButton>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </Container>
  );
}
