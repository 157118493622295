import { Col, DatePicker, Row, Tabs } from 'antd';
import { Moment } from 'moment';
import React, { useState } from 'react';
import Cards from './components/Cards';
import AutonomyChart from './components/AutonomyChart';
import CostsAndQuantityChart from './components/CostsAndQuantityChart';
import DistanceOrHorimeterChart from './components/DistanceOrHorimeterChart';
import OrdersChart from './components/OrdersChart';
import {
  EQUIPMENTS,
  EQUIPMENT_FUELLINGS,
  FUELLINGS,
  VEHICLES,
} from './constants';
import { useGeneralContext } from './contexts/GeneralContext';
import { useSpecificContext } from './contexts/SpecificContext';
import {
  Container,
  FilterContainer,
  FilterTitle,
  GeneralTitle,
} from './styles';
import { Title, ContainerTitle } from '../../components/PageTitle/styles';

const { TabPane } = Tabs;

const { RangePicker } = DatePicker;

interface Props {}

const DashboardContent = (props: Props) => {
  const { setRangeDate, rangeDate, orders } = useGeneralContext();
  const { specificRangeDate, setSpecificRangeDate } = useSpecificContext();

  const [activeKey, setActiveKey] = useState(VEHICLES);

  return (
    <div>
      <ContainerTitle>
        <Title>Dashboard</Title>
      </ContainerTitle>
      <Cards />
      <Container>
        <GeneralTitle>Indicadores gerais</GeneralTitle>
        <FilterContainer>
          <FilterTitle>Filtrar abastecimentos por:</FilterTitle>
          <RangePicker
            defaultValue={rangeDate}
            value={rangeDate}
            allowClear={false}
            format="DD-MM-YY"
            onChange={(dates: any) => {
              setRangeDate(dates);
            }}
          />
        </FilterContainer>
        <Row gutter={32}>
          <Col span={!!orders.length ? 12 : 24}>
            <CostsAndQuantityChart />
          </Col>
          {!!orders.length && (
            <Col span={12}>
              <OrdersChart />
            </Col>
          )}
        </Row>
      </Container>
      <Container>
        <GeneralTitle>Indicadores específicos</GeneralTitle>
        <Tabs activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
          <TabPane tab="Veículos" key={VEHICLES}>
            <FilterContainer>
              <FilterTitle>Filtrar abastecimentos por:</FilterTitle>
              <RangePicker
                defaultValue={specificRangeDate}
                value={specificRangeDate}
                allowClear={false}
                format="DD-MM-YY"
                onChange={(dates: any) => {
                  setSpecificRangeDate(dates);
                }}
              />
            </FilterContainer>
            <Row gutter={32}>
              <Col span={12}>
                <AutonomyChart type={FUELLINGS} />
              </Col>
              <Col span={12}>
                <DistanceOrHorimeterChart type={FUELLINGS} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Equipamentos" key={EQUIPMENTS}>
            <FilterContainer>
              <FilterTitle>Filtrar abastecimentos por:</FilterTitle>
              <RangePicker
                defaultValue={rangeDate}
                value={rangeDate}
                allowClear={false}
                format="DD-MM-YY"
                onChange={(dates: any) => {
                  setSpecificRangeDate(dates);
                }}
              />
            </FilterContainer>
            <Row gutter={32}>
              <Col span={12}>
                <AutonomyChart type={EQUIPMENT_FUELLINGS} />
              </Col>
              <Col span={12}>
                <DistanceOrHorimeterChart type={EQUIPMENT_FUELLINGS} />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Container>
    </div>
  );
};

export default DashboardContent;
