import React from 'react';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { FaPlusCircle } from 'react-icons/fa';

export const AddItemButton = styled(FaPlusCircle)`
  color: rgb(50, 182, 80);
  font-size: 1.25rem;
  margin-top: 0.25rem;
  margin-left: 0.5rem;
`;

export default AddItemButton;