import styled from 'styled-components';
import { LogoutOutlined } from '@ant-design/icons';

export const Container = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 2rem;
  width: 370px;
`;

export const InfoContainer = styled.div`
  padding: 0 1rem;
`;

export const UserName = styled.h3`
  font-size: 24;
  font-weight: bold;
  margin-bottom: 0;
`;

export const UserRole = styled.h4`
  color: #909090;
  font-size: 20;
`;

export const Logout = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

export const LogoutButton = styled.button`
  background-color: #fff;
  border: none;
  color: red;
  cursor: pointer;
`;

export const LogoutIcon = styled(LogoutOutlined)`
  margin-right: 0.5rem;
`;
