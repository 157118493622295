import { Modal } from 'antd';
import { Select } from 'formik-antd';
import 'moment/locale/pt-br';
import React, { useState } from 'react';
import { FaPen } from 'react-icons/fa';
import { Fuelling } from '../../helpers/types';
import ProviderFuellingForm from './ProviderFuellingForm';
import { TankFuellingForm } from './TankFuellingForm';

export const Option = Select.Option;

enum Options {
  Menu = 'MENU',
  Tank = 'TANK',
  Provider = 'PROVIDER',
}

type MenuProps = {
  selectOption: (option: Options) => void;
};

function Menu(props: MenuProps) {
  const { selectOption } = props;
  return (
    <div className="modal-container">
      <p className="modal-instruction">
        Olá, você gostaria de lançar um abastecimento em:
      </p>
      <div className="modal-buttons">
        <button
          onClick={() => selectOption(Options.Tank)}
          style={{
            marginRight: '1rem',
          }}
          className="modal-button"
        >
          Posto Interno
        </button>
        <button
          onClick={() => selectOption(Options.Provider)}
          className="modal-button"
        >
          Posto Comercial
        </button>
      </div>
    </div>
  );
}

interface EditProps {
  fuelling: Fuelling;
  type: 'EQUIPMENT' | 'VEHICLE';
}

export function EditFuelling(props: EditProps) {
  const { fuelling, type } = props;
  const fuellingType: 'TANK' | 'PROVIDER' = fuelling.tank ? 'TANK' : 'PROVIDER';

  const [modal, setModal] = useState(false);

  const handleOpen = () => setModal(true);
  const handleClose = () => {
    setModal(false);
  };

  return (
    <>
      <FaPen
        style={{ cursor: 'pointer', marginRight: 12 }}
        onClick={handleOpen}
      />
      <Modal
        destroyOnClose
        visible={modal}
        footer={null}
        onCancel={handleClose}
      >
        {
          {
            TANK: (
              <TankFuellingForm
                fuelling={fuelling}
                type={type}
                handleCloseModal={handleClose}
              />
            ),
            PROVIDER: (
              <ProviderFuellingForm
                fuelling={fuelling}
                type={type}
                handleCloseModal={handleClose}
              />
            ),
          }[fuellingType]
        }
      </Modal>
    </>
  );
}

export default function NewFuelling() {
  const [modal, setModal] = useState(false);
  const [display, setDisplay] = useState<Options>(Options.Menu);

  const handleOpen = () => setModal(true);
  const handleClose = () => {
    setDisplay(Options.Menu);
    setModal(false);
  };

  const selectOption = (option: Options) => setDisplay(option);

  return (
    <>
      <button className="newFuelling" onClick={handleOpen}>
        Novo abastecimento
      </button>
      <Modal
        destroyOnClose
        visible={modal}
        footer={null}
        onCancel={handleClose}
      >
        {
          {
            MENU: <Menu selectOption={selectOption} />,
            TANK: <TankFuellingForm handleCloseModal={handleClose} />,
            PROVIDER: <ProviderFuellingForm handleCloseModal={handleClose} />,
          }[display]
        }
      </Modal>
    </>
  );
}
