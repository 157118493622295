import { message } from 'antd';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/AuthProvider';
import { db } from '../firebaseConfig';
import { VehicleFragment } from '../generated/graphql';
import { Fuelling, Provider, Tank, Transfer } from './types';

// TODO: remove loafding attribute from hooks.

export const useProviders = () => {
  const { firebaseOrganizationId } = useContext(AuthContext);

  const [providers, setProviders] = useState<Provider[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firebaseOrganizationId) {
      return;
    }

    db.collection('organizations')
      .doc(firebaseOrganizationId)
      .collection('providers')
      .onSnapshot((querySnapshot) => {
        const providers = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setProviders((providers as unknown) as Provider[]);
        setLoading(false);
      });
  }, [firebaseOrganizationId]);

  return {
    loading,
    providers,
  };
};

export const useReferenceOdometer = (
  vehicle?: VehicleFragment,
  date?: Date,
  fuellingId?: string
) => {
  const { firebaseOrganizationId } = useContext(AuthContext);

  const [minOdometer, setMinOdometer] = useState<number>(0);
  const [maxOdometer, setMaxOdometer] = useState<number>(0);
  const [recentOdometer, setRecentOdometer] = useState<number>(0);

  useEffect(() => {
    if (!vehicle || !date) {
      setMinOdometer(0);
      setMaxOdometer(0);
      setRecentOdometer(0);

      return;
    }

    const day = moment(date).toDate();
    let orgRef = db.collection('organizations').doc(firebaseOrganizationId);

    const fuellingsRefDesc = orgRef
      .collection('fuellings')
      .orderBy('date', 'desc');
    const fuellingsRefAsc = orgRef
      .collection('fuellings')
      .orderBy('date', 'asc');

    const fuellingsByVehicleDesc = fuellingsRefDesc.where(
      'car.id',
      '==',
      vehicle.id
    );
    const fuellingsByVehicleAsc = fuellingsRefAsc.where(
      'car.id',
      '==',
      vehicle.id
    );

    fuellingsByVehicleDesc.limit(1).onSnapshot((querySnapshot) => {
      const odometer = querySnapshot.docs.map(
        (doc) => doc.data().odometer || doc.data().horimeter
      )[0];

      setRecentOdometer(odometer || 0);
    });

    if (fuellingId) {
      fuellingsByVehicleDesc
        .where('date', '<=', day)
        .limit(2)
        .onSnapshot((querySnapshot) => {
          const odometer = querySnapshot.docs.map(
            (doc) => doc.data().odometer || doc.data().horimeter
          )[1];

          setMinOdometer(odometer || 0);
        });

      fuellingsByVehicleAsc
        .where('date', '>=', day)
        .limit(2)
        .onSnapshot((querySnapshot) => {
          const odometer = querySnapshot.docs.map(
            (doc) => doc.data().odometer || doc.data().horimeter
          )[1];

          setMaxOdometer(odometer || Infinity);
        });
    } else {
      fuellingsByVehicleDesc
        .where('date', '<=', day)
        .limit(1)
        .onSnapshot((querySnapshot) => {
          const odometer = querySnapshot.docs.map(
            (doc) => doc.data().odometer || doc.data().horimeter
          )[0];

          setMinOdometer(odometer || 0);
        });

      fuellingsByVehicleAsc
        .where('date', '>=', day)
        .limit(1)
        .onSnapshot((querySnapshot) => {
          const odometer = querySnapshot.docs.map(
            (doc) => doc.data().odometer || doc.data().horimeter
          )[0];

          setMaxOdometer(odometer || Infinity);
        });
    }
  }, [firebaseOrganizationId, vehicle, date, fuellingId]);

  return { minOdometer, maxOdometer, recentOdometer };
};

export const useTransfer = (id: string) => {
  const { firebaseOrganizationId } = useContext(AuthContext);

  const [error, setError] = useState<Error | undefined>(undefined);
  const [transfer, setTransfer] = useState<Transfer | undefined>(undefined);

  useEffect(() => {
    if (!firebaseOrganizationId || !id) {
      return;
    }

    db.collection('organizations')
      .doc(firebaseOrganizationId)
      .collection('transfers')
      .doc(id)
      .onSnapshot(
        (doc) => {
          setTransfer((doc.data() as unknown) as Transfer);
        },
        (err) => {
          setError(err);
          throw err;
        }
      );
  }, [firebaseOrganizationId, id]);

  return {
    transfer,
    error,
  };
};

export const useFuellings = () => {
  const { firebaseOrganizationId } = useContext(AuthContext);

  const [fuellings, setFuellings] = useState<Fuelling[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firebaseOrganizationId) {
      return;
    }

    db.collection('organizations')
      .doc(firebaseOrganizationId)
      .collection('fuellings')
      .where('car.type', '!=', 'equipment')
      .onSnapshot((querySnapshot) => {
        const fuellings = querySnapshot.docs.map((doc) => {
          const filledUp = doc.data().hasOwnProperty('filledUp')
            ? doc.data().filledUp
            : true;
          return {
            ...doc.data(),
            date: doc.data().date.toDate(),
            filledUp: filledUp,
            id: doc.id,
          } as Fuelling;
        });
        setFuellings(fuellings);
        setLoading(false);
      });
  }, [firebaseOrganizationId]);

  return { fuellings, loading };
};

export const useEquipmentFuellings = () => {
  const { firebaseOrganizationId } = useContext(AuthContext);
  const [equipmentFuellings, setFuellings] = useState<Fuelling[]>([]);

  useEffect(() => {
    if (!firebaseOrganizationId) {
      return;
    }

    db.collection('organizations')
      .doc(firebaseOrganizationId)
      .collection('fuellings')
      .where('car.type', '==', 'equipment')
      .onSnapshot((querySnapshot) => {
        const fuellings = querySnapshot.docs.map((doc) => {
          const filledUp = doc.data().hasOwnProperty('filledUp')
            ? doc.data().filledUp
            : true;
          return {
            ...doc.data(),
            date: doc.data().date.toDate(),
            filledUp: filledUp,
            id: doc.id,
          } as Fuelling;
        });
        setFuellings(fuellings);
      });
  }, [firebaseOrganizationId]);

  return { equipmentFuellings };
};

export const useTanks = () => {
  const { firebaseOrganizationId } = useContext(AuthContext);

  const [tanks, setTanks] = useState<Tank[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firebaseOrganizationId) {
      return;
    }
    db.collection('organizations')
      .doc(firebaseOrganizationId)
      .collection('tanks')
      .onSnapshot((querySnapshot) => {
        const tanks = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setTanks((tanks as unknown) as Tank[]);
        setLoading(false);
      });
  }, [firebaseOrganizationId]);

  return { loading, tanks };
};

export const useOrders = () => {
  const { firebaseOrganizationId } = useContext(AuthContext);

  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firebaseOrganizationId) {
      return;
    }

    db.collection('organizations')
      .doc(firebaseOrganizationId)
      .collection('orders')
      .onSnapshot((querySnapshot) => {
        const orders = querySnapshot.docs.map((doc: any) => ({
          ...doc.data(),
          id: doc.id,
          invoiceDate: doc.data().invoiceDate.toDate().toISOString(),
        }));
        setOrders(orders);
        setLoading(false);
      });
  }, [firebaseOrganizationId]);

  return { orders, loading };
};

export const useTransfers = () => {
  const { firebaseOrganizationId } = useContext(AuthContext);

  const [transfers, setTransfers] = useState<Transfer[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firebaseOrganizationId) {
      return;
    }

    db.collection('organizations')
      .doc(firebaseOrganizationId)
      .collection('transfers')
      .onSnapshot((querySnapshot) => {
        const transfers = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTransfers((transfers as unknown) as Transfer[]);
        setLoading(false);
      });
  }, [firebaseOrganizationId]);

  return { loading, transfers };
};

export const useLatestFuelling = (vehicleId: string) => {
  const [fuelling, setFuelling] = useState<Fuelling | undefined>(undefined);
  const { firebaseOrganizationId } = useContext(AuthContext);

  useEffect(() => {
    if (!vehicleId && !firebaseOrganizationId) return;

    db.collection('organizations')
      .doc(firebaseOrganizationId)
      .collection('fuellings')
      .where('car.id', '==', vehicleId)
      .orderBy('date', 'desc')
      .limit(1)
      .onSnapshot(
        (querySnapshot) => {
          const fuelling = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))[0] as Fuelling;
          setFuelling(fuelling);
        },
        (err) => {
          message.error('Falha ao buscar abastecimento');
          console.error(err);
          setFuelling(undefined);
        }
      );
  }, [vehicleId, firebaseOrganizationId]);

  return {
    fuelling,
  };
};

// export const useVehicle = (vehicleId: string) => {
//   const [vehicle, setVehicle] = useState<Vehicle | undefined>(undefined);
//   const { firebaseOrganizationId } = useContext(AuthContext);

//   useEffect(() => {
//     if (!vehicleId) return;

//     db.collection('organizations')
//       .doc(firebaseOrganizationId)
//       .collection('cars')
//       .doc(vehicleId)
//       .onSnapshot(
//         (doc) => {
//           const vehicle = {
//             id: doc.id,
//             ...doc.data(),
//           } as Vehicle;
//           setVehicle(vehicle);
//         },
//         (err) => {
//           message.error('Falha ao buscar veículo');
//           console.error(err);
//           setVehicle(undefined);
//         }
//       );
//   }, [vehicleId, firebaseOrganizationId]);

//   return {
//     vehicle,
//   };
// };
