import { groupBy } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { FaDollarSign } from 'react-icons/fa';
import styled from 'styled-components';
import { EquipmentFuelling, Fuelling } from '../../../../helpers/types';
import { useGeneralContext } from '../../contexts/GeneralContext';
import {
  Container,
  Indicator,
  IndicatorContainer,
  Title,
  TitleContainer,
  Value,
} from '../AutonomyChart/styles';
import CostsAndQuantityChartCanvas from './CostsAndQuantityChartCanvas';

interface Props {}

const Icon = styled(FaDollarSign)`
  margin-top: 0.25rem;
`;

export default function CostsAndQuantityChart(props: Props) {
  const { fuellings, equipmentFuellings } = useGeneralContext();

  const totalCost = useMemo(() => {
    const formattedFuellings = fuellings
      .filter((fuelling: Fuelling) => !!fuelling.value)
      .map(({ value }) => Number(value));

    const formattedEquipmentFuellings = equipmentFuellings
      .filter((fuelling: EquipmentFuelling) => !!fuelling.value)
      .map(({ value }) => Number(value));

    const allFuellings = [
      ...formattedFuellings,
      ...formattedEquipmentFuellings,
    ];

    return allFuellings.reduce((sum: number, value: number) => value + sum, 0);
  }, [fuellings, equipmentFuellings]);

  const totalQuantity = useMemo(() => {
    return [...fuellings, ...equipmentFuellings].reduce(
      (sum, fuelling: Fuelling | EquipmentFuelling) =>
        sum + fuelling.fuelAmount,
      0
    );
  }, [fuellings, equipmentFuellings]);

  const data = useMemo(() => {
    const allFuellings = [...fuellings, ...equipmentFuellings];

    const groupedFuellings = groupBy(
      allFuellings,
      (fuelling: Fuelling | EquipmentFuelling) => {
        return moment(fuelling.date).format('DD/MM/YY');
      }
    );

    return Object.entries(groupedFuellings).map((group) => ({
      date: group[0],
      Custo: group[1]
        .filter((fuelling: Fuelling | EquipmentFuelling) => !!fuelling.value)
        .reduce((sum, fuelling) => sum + Number(fuelling.value), 0),
    }));
  }, [fuellings, equipmentFuellings]);

  return (
    <Container>
      <TitleContainer>
        <Title>Custos de Abastecimento x Período</Title>
      </TitleContainer>
      <IndicatorContainer>
        <Indicator>
          <Icon />
          Custo total:
          <Value> R$ {totalCost.toFixed(2)}</Value>
        </Indicator>
        <Indicator>
          Quantidade de litros:
          <Value> {totalQuantity.toFixed(2)} L</Value>
        </Indicator>
      </IndicatorContainer>
      <CostsAndQuantityChartCanvas data={data} />
    </Container>
  );
}
