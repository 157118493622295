import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import React, { useContext } from 'react';
import { Provider } from 'urql';
import Routes from './components/Layout/Routes';
import { AuthContext } from './contexts/AuthProvider';
import client from './graphql/client';
import SignIn from './modules/Login/SignIn';

function App() {
  const { isLoggedIn } = useContext(AuthContext);

  if (!isLoggedIn) return <SignIn />;

  return (
    <Provider value={client}>
      <ConfigProvider locale={ptBR}>
        <Routes />
      </ConfigProvider>
    </Provider>
  );
}

export default App;
