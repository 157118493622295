import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  citext: any;
  float8: number;
  json: any;
  jsonb: any;
  timestamptz: any;
  uuid: any;
};

export type AddressOutput = {
  __typename?: 'AddressOutput';
  address: Scalars['String'];
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

/** expression to compare columns of type citext. All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: Maybe<Scalars['citext']>;
  _gt?: Maybe<Scalars['citext']>;
  _gte?: Maybe<Scalars['citext']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['citext']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['citext']>;
  _lte?: Maybe<Scalars['citext']>;
  _neq?: Maybe<Scalars['citext']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['citext']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "driver" */
export type Driver = {
  __typename?: 'driver';
  birthday: Scalars['timestamptz'];
  cnh: Scalars['String'];
  cnh_category: Scalars['String'];
  cpf: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  organization_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "driver" */
export type Driver_Aggregate = {
  __typename?: 'driver_aggregate';
  aggregate?: Maybe<Driver_Aggregate_Fields>;
  nodes: Array<Driver>;
};

/** aggregate fields of "driver" */
export type Driver_Aggregate_Fields = {
  __typename?: 'driver_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Driver_Max_Fields>;
  min?: Maybe<Driver_Min_Fields>;
};

/** aggregate fields of "driver" */
export type Driver_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Driver_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "driver" */
export type Driver_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Driver_Max_Order_By>;
  min?: Maybe<Driver_Min_Order_By>;
};

/** input type for inserting array relation for remote table "driver" */
export type Driver_Arr_Rel_Insert_Input = {
  data: Array<Driver_Insert_Input>;
  on_conflict?: Maybe<Driver_On_Conflict>;
};

/** Boolean expression to filter rows from the table "driver". All fields are combined with a logical 'AND'. */
export type Driver_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Driver_Bool_Exp>>>;
  _not?: Maybe<Driver_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Driver_Bool_Exp>>>;
  birthday?: Maybe<Timestamptz_Comparison_Exp>;
  cnh?: Maybe<String_Comparison_Exp>;
  cnh_category?: Maybe<String_Comparison_Exp>;
  cpf?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "driver" */
export enum Driver_Constraint {
  /** unique or primary key constraint */
  DriverCnhOrganizationIdKey = 'driver_cnh_organization_id_key',
  /** unique or primary key constraint */
  DriverCpfOrganizationIdKey = 'driver_cpf_organization_id_key',
  /** unique or primary key constraint */
  DriverPkey = 'driver_pkey',
}

/** input type for inserting data into table "driver" */
export type Driver_Insert_Input = {
  birthday?: Maybe<Scalars['timestamptz']>;
  cnh?: Maybe<Scalars['String']>;
  cnh_category?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Driver_Max_Fields = {
  __typename?: 'driver_max_fields';
  birthday?: Maybe<Scalars['timestamptz']>;
  cnh?: Maybe<Scalars['String']>;
  cnh_category?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "driver" */
export type Driver_Max_Order_By = {
  birthday?: Maybe<Order_By>;
  cnh?: Maybe<Order_By>;
  cnh_category?: Maybe<Order_By>;
  cpf?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Driver_Min_Fields = {
  __typename?: 'driver_min_fields';
  birthday?: Maybe<Scalars['timestamptz']>;
  cnh?: Maybe<Scalars['String']>;
  cnh_category?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "driver" */
export type Driver_Min_Order_By = {
  birthday?: Maybe<Order_By>;
  cnh?: Maybe<Order_By>;
  cnh_category?: Maybe<Order_By>;
  cpf?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "driver" */
export type Driver_Mutation_Response = {
  __typename?: 'driver_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Driver>;
};

/** input type for inserting object relation for remote table "driver" */
export type Driver_Obj_Rel_Insert_Input = {
  data: Driver_Insert_Input;
  on_conflict?: Maybe<Driver_On_Conflict>;
};

/** on conflict condition type for table "driver" */
export type Driver_On_Conflict = {
  constraint: Driver_Constraint;
  update_columns: Array<Driver_Update_Column>;
  where?: Maybe<Driver_Bool_Exp>;
};

/** ordering options when selecting data from "driver" */
export type Driver_Order_By = {
  birthday?: Maybe<Order_By>;
  cnh?: Maybe<Order_By>;
  cnh_category?: Maybe<Order_By>;
  cpf?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "driver" */
export type Driver_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "driver" */
export enum Driver_Select_Column {
  /** column name */
  Birthday = 'birthday',
  /** column name */
  Cnh = 'cnh',
  /** column name */
  CnhCategory = 'cnh_category',
  /** column name */
  Cpf = 'cpf',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "driver" */
export type Driver_Set_Input = {
  birthday?: Maybe<Scalars['timestamptz']>;
  cnh?: Maybe<Scalars['String']>;
  cnh_category?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "driver" */
export enum Driver_Update_Column {
  /** column name */
  Birthday = 'birthday',
  /** column name */
  Cnh = 'cnh',
  /** column name */
  CnhCategory = 'cnh_category',
  /** column name */
  Cpf = 'cpf',
  /** column name */
  Name = 'name',
}

/** columns and relationships of "equipment" */
export type Equipment = {
  __typename?: 'equipment';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  horimeter_histories: Array<Horimeter_History>;
  /** An aggregated array relationship */
  horimeter_histories_aggregate: Horimeter_History_Aggregate;
  id: Scalars['uuid'];
  identification: Scalars['citext'];
  organization_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "equipment" */
export type EquipmentHorimeter_HistoriesArgs = {
  distinct_on?: Maybe<Array<Horimeter_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Horimeter_History_Order_By>>;
  where?: Maybe<Horimeter_History_Bool_Exp>;
};

/** columns and relationships of "equipment" */
export type EquipmentHorimeter_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Horimeter_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Horimeter_History_Order_By>>;
  where?: Maybe<Horimeter_History_Bool_Exp>;
};

/** aggregated selection of "equipment" */
export type Equipment_Aggregate = {
  __typename?: 'equipment_aggregate';
  aggregate?: Maybe<Equipment_Aggregate_Fields>;
  nodes: Array<Equipment>;
};

/** aggregate fields of "equipment" */
export type Equipment_Aggregate_Fields = {
  __typename?: 'equipment_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Equipment_Max_Fields>;
  min?: Maybe<Equipment_Min_Fields>;
};

/** aggregate fields of "equipment" */
export type Equipment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Equipment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "equipment" */
export type Equipment_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Equipment_Max_Order_By>;
  min?: Maybe<Equipment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "equipment" */
export type Equipment_Arr_Rel_Insert_Input = {
  data: Array<Equipment_Insert_Input>;
  on_conflict?: Maybe<Equipment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "equipment". All fields are combined with a logical 'AND'. */
export type Equipment_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Equipment_Bool_Exp>>>;
  _not?: Maybe<Equipment_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Equipment_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  horimeter_histories?: Maybe<Horimeter_History_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  identification?: Maybe<Citext_Comparison_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "equipment" */
export enum Equipment_Constraint {
  /** unique or primary key constraint */
  EquipmentOrganizationIdIdentificationKey = 'equipment_organization_id_identification_key',
  /** unique or primary key constraint */
  EquipmentPkey = 'equipment_pkey',
}

/** input type for inserting data into table "equipment" */
export type Equipment_Insert_Input = {
  horimeter_histories?: Maybe<Horimeter_History_Arr_Rel_Insert_Input>;
  identification?: Maybe<Scalars['citext']>;
};

/** aggregate max on columns */
export type Equipment_Max_Fields = {
  __typename?: 'equipment_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identification?: Maybe<Scalars['citext']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "equipment" */
export type Equipment_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Equipment_Min_Fields = {
  __typename?: 'equipment_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identification?: Maybe<Scalars['citext']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "equipment" */
export type Equipment_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "equipment" */
export type Equipment_Mutation_Response = {
  __typename?: 'equipment_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Equipment>;
};

/** input type for inserting object relation for remote table "equipment" */
export type Equipment_Obj_Rel_Insert_Input = {
  data: Equipment_Insert_Input;
  on_conflict?: Maybe<Equipment_On_Conflict>;
};

/** on conflict condition type for table "equipment" */
export type Equipment_On_Conflict = {
  constraint: Equipment_Constraint;
  update_columns: Array<Equipment_Update_Column>;
  where?: Maybe<Equipment_Bool_Exp>;
};

/** ordering options when selecting data from "equipment" */
export type Equipment_Order_By = {
  created_at?: Maybe<Order_By>;
  horimeter_histories_aggregate?: Maybe<Horimeter_History_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  identification?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "equipment" */
export type Equipment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "equipment" */
export enum Equipment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Identification = 'identification',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "equipment" */
export type Equipment_Set_Input = {
  identification?: Maybe<Scalars['citext']>;
};

/** update columns of table "equipment" */
export enum Equipment_Update_Column {
  /** column name */
  Identification = 'identification',
}

/** expression to compare columns of type float8. All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};

export type Get_User_By_Credentials_Args = {
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** columns and relationships of "hermes.device" */
export type Hermes_Device = {
  __typename?: 'hermes_device';
  /** An array relationship */
  event_traccars: Array<Hermes_Event_Traccar>;
  /** An aggregated array relationship */
  event_traccars_aggregate: Hermes_Event_Traccar_Aggregate;
  /** An array relationship */
  events: Array<Hermes_Event>;
  /** An aggregated array relationship */
  events_aggregate: Hermes_Event_Aggregate;
  last_update?: Maybe<Scalars['timestamptz']>;
  organization_id: Scalars['uuid'];
  /** An array relationship */
  positions: Array<Hermes_Position>;
  /** An aggregated array relationship */
  positions_aggregate: Hermes_Position_Aggregate;
  unique_id: Scalars['String'];
  /** An object relationship */
  vehicle: Vehicle;
  vehicle_id: Scalars['uuid'];
};

/** columns and relationships of "hermes.device" */
export type Hermes_DeviceEvent_TraccarsArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Traccar_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Traccar_Order_By>>;
  where?: Maybe<Hermes_Event_Traccar_Bool_Exp>;
};

/** columns and relationships of "hermes.device" */
export type Hermes_DeviceEvent_Traccars_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Traccar_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Traccar_Order_By>>;
  where?: Maybe<Hermes_Event_Traccar_Bool_Exp>;
};

/** columns and relationships of "hermes.device" */
export type Hermes_DeviceEventsArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Order_By>>;
  where?: Maybe<Hermes_Event_Bool_Exp>;
};

/** columns and relationships of "hermes.device" */
export type Hermes_DeviceEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Order_By>>;
  where?: Maybe<Hermes_Event_Bool_Exp>;
};

/** columns and relationships of "hermes.device" */
export type Hermes_DevicePositionsArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Order_By>>;
  where?: Maybe<Hermes_Position_Bool_Exp>;
};

/** columns and relationships of "hermes.device" */
export type Hermes_DevicePositions_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Order_By>>;
  where?: Maybe<Hermes_Position_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "hermes.device". All fields are combined with a logical 'AND'. */
export type Hermes_Device_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Hermes_Device_Bool_Exp>>>;
  _not?: Maybe<Hermes_Device_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Hermes_Device_Bool_Exp>>>;
  event_traccars?: Maybe<Hermes_Event_Traccar_Bool_Exp>;
  events?: Maybe<Hermes_Event_Bool_Exp>;
  last_update?: Maybe<Timestamptz_Comparison_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  positions?: Maybe<Hermes_Position_Bool_Exp>;
  unique_id?: Maybe<String_Comparison_Exp>;
  vehicle?: Maybe<Vehicle_Bool_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
};

/** ordering options when selecting data from "hermes.device" */
export type Hermes_Device_Order_By = {
  event_traccars_aggregate?: Maybe<Hermes_Event_Traccar_Aggregate_Order_By>;
  events_aggregate?: Maybe<Hermes_Event_Aggregate_Order_By>;
  last_update?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  positions_aggregate?: Maybe<Hermes_Position_Aggregate_Order_By>;
  unique_id?: Maybe<Order_By>;
  vehicle?: Maybe<Vehicle_Order_By>;
  vehicle_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "hermes.device" */
export type Hermes_Device_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hermes.device" */
export enum Hermes_Device_Select_Column {
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UniqueId = 'unique_id',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** columns and relationships of "hermes.event" */
export type Hermes_Event = {
  __typename?: 'hermes_event';
  address?: Maybe<Scalars['String']>;
  attributes: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  device?: Maybe<Hermes_Device>;
  device_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  is_alarm: Scalars['Boolean'];
  latitude: Scalars['float8'];
  longitude: Scalars['float8'];
  organization_id: Scalars['uuid'];
  origin: Scalars['String'];
  reported_at: Scalars['timestamptz'];
  slug_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicle;
  vehicle_id: Scalars['uuid'];
};

/** columns and relationships of "hermes.event" */
export type Hermes_EventAttributesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "hermes.event" */
export type Hermes_Event_Aggregate = {
  __typename?: 'hermes_event_aggregate';
  aggregate?: Maybe<Hermes_Event_Aggregate_Fields>;
  nodes: Array<Hermes_Event>;
};

/** aggregate fields of "hermes.event" */
export type Hermes_Event_Aggregate_Fields = {
  __typename?: 'hermes_event_aggregate_fields';
  avg?: Maybe<Hermes_Event_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hermes_Event_Max_Fields>;
  min?: Maybe<Hermes_Event_Min_Fields>;
  stddev?: Maybe<Hermes_Event_Stddev_Fields>;
  stddev_pop?: Maybe<Hermes_Event_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hermes_Event_Stddev_Samp_Fields>;
  sum?: Maybe<Hermes_Event_Sum_Fields>;
  var_pop?: Maybe<Hermes_Event_Var_Pop_Fields>;
  var_samp?: Maybe<Hermes_Event_Var_Samp_Fields>;
  variance?: Maybe<Hermes_Event_Variance_Fields>;
};

/** aggregate fields of "hermes.event" */
export type Hermes_Event_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hermes_Event_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hermes.event" */
export type Hermes_Event_Aggregate_Order_By = {
  avg?: Maybe<Hermes_Event_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Hermes_Event_Max_Order_By>;
  min?: Maybe<Hermes_Event_Min_Order_By>;
  stddev?: Maybe<Hermes_Event_Stddev_Order_By>;
  stddev_pop?: Maybe<Hermes_Event_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Hermes_Event_Stddev_Samp_Order_By>;
  sum?: Maybe<Hermes_Event_Sum_Order_By>;
  var_pop?: Maybe<Hermes_Event_Var_Pop_Order_By>;
  var_samp?: Maybe<Hermes_Event_Var_Samp_Order_By>;
  variance?: Maybe<Hermes_Event_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Hermes_Event_Avg_Fields = {
  __typename?: 'hermes_event_avg_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hermes.event" */
export type Hermes_Event_Avg_Order_By = {
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hermes.event". All fields are combined with a logical 'AND'. */
export type Hermes_Event_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Hermes_Event_Bool_Exp>>>;
  _not?: Maybe<Hermes_Event_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Hermes_Event_Bool_Exp>>>;
  address?: Maybe<String_Comparison_Exp>;
  attributes?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  device?: Maybe<Hermes_Device_Bool_Exp>;
  device_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_alarm?: Maybe<Boolean_Comparison_Exp>;
  latitude?: Maybe<Float8_Comparison_Exp>;
  longitude?: Maybe<Float8_Comparison_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  origin?: Maybe<String_Comparison_Exp>;
  reported_at?: Maybe<Timestamptz_Comparison_Exp>;
  slug_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vehicle?: Maybe<Vehicle_Bool_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Hermes_Event_Max_Fields = {
  __typename?: 'hermes_event_max_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  origin?: Maybe<Scalars['String']>;
  reported_at?: Maybe<Scalars['timestamptz']>;
  slug_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "hermes.event" */
export type Hermes_Event_Max_Order_By = {
  address?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  device_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  origin?: Maybe<Order_By>;
  reported_at?: Maybe<Order_By>;
  slug_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hermes_Event_Min_Fields = {
  __typename?: 'hermes_event_min_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  origin?: Maybe<Scalars['String']>;
  reported_at?: Maybe<Scalars['timestamptz']>;
  slug_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "hermes.event" */
export type Hermes_Event_Min_Order_By = {
  address?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  device_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  origin?: Maybe<Order_By>;
  reported_at?: Maybe<Order_By>;
  slug_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
};

/** ordering options when selecting data from "hermes.event" */
export type Hermes_Event_Order_By = {
  address?: Maybe<Order_By>;
  attributes?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  device?: Maybe<Hermes_Device_Order_By>;
  device_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_alarm?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  origin?: Maybe<Order_By>;
  reported_at?: Maybe<Order_By>;
  slug_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle?: Maybe<Vehicle_Order_By>;
  vehicle_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "hermes.event" */
export type Hermes_Event_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hermes.event" */
export enum Hermes_Event_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsAlarm = 'is_alarm',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Origin = 'origin',
  /** column name */
  ReportedAt = 'reported_at',
  /** column name */
  SlugName = 'slug_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** aggregate stddev on columns */
export type Hermes_Event_Stddev_Fields = {
  __typename?: 'hermes_event_stddev_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hermes.event" */
export type Hermes_Event_Stddev_Order_By = {
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hermes_Event_Stddev_Pop_Fields = {
  __typename?: 'hermes_event_stddev_pop_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hermes.event" */
export type Hermes_Event_Stddev_Pop_Order_By = {
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hermes_Event_Stddev_Samp_Fields = {
  __typename?: 'hermes_event_stddev_samp_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hermes.event" */
export type Hermes_Event_Stddev_Samp_Order_By = {
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Hermes_Event_Sum_Fields = {
  __typename?: 'hermes_event_sum_fields';
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "hermes.event" */
export type Hermes_Event_Sum_Order_By = {
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** columns and relationships of "hermes.event_traccar" */
export type Hermes_Event_Traccar = {
  __typename?: 'hermes_event_traccar';
  address?: Maybe<Scalars['String']>;
  attributes: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  device: Hermes_Device;
  device_id: Scalars['uuid'];
  id: Scalars['uuid'];
  latitude: Scalars['float8'];
  longitude: Scalars['float8'];
  server_time: Scalars['timestamptz'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "hermes.event_traccar" */
export type Hermes_Event_TraccarAttributesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "hermes.event_traccar" */
export type Hermes_Event_Traccar_Aggregate = {
  __typename?: 'hermes_event_traccar_aggregate';
  aggregate?: Maybe<Hermes_Event_Traccar_Aggregate_Fields>;
  nodes: Array<Hermes_Event_Traccar>;
};

/** aggregate fields of "hermes.event_traccar" */
export type Hermes_Event_Traccar_Aggregate_Fields = {
  __typename?: 'hermes_event_traccar_aggregate_fields';
  avg?: Maybe<Hermes_Event_Traccar_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hermes_Event_Traccar_Max_Fields>;
  min?: Maybe<Hermes_Event_Traccar_Min_Fields>;
  stddev?: Maybe<Hermes_Event_Traccar_Stddev_Fields>;
  stddev_pop?: Maybe<Hermes_Event_Traccar_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hermes_Event_Traccar_Stddev_Samp_Fields>;
  sum?: Maybe<Hermes_Event_Traccar_Sum_Fields>;
  var_pop?: Maybe<Hermes_Event_Traccar_Var_Pop_Fields>;
  var_samp?: Maybe<Hermes_Event_Traccar_Var_Samp_Fields>;
  variance?: Maybe<Hermes_Event_Traccar_Variance_Fields>;
};

/** aggregate fields of "hermes.event_traccar" */
export type Hermes_Event_Traccar_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hermes_Event_Traccar_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Aggregate_Order_By = {
  avg?: Maybe<Hermes_Event_Traccar_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Hermes_Event_Traccar_Max_Order_By>;
  min?: Maybe<Hermes_Event_Traccar_Min_Order_By>;
  stddev?: Maybe<Hermes_Event_Traccar_Stddev_Order_By>;
  stddev_pop?: Maybe<Hermes_Event_Traccar_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Hermes_Event_Traccar_Stddev_Samp_Order_By>;
  sum?: Maybe<Hermes_Event_Traccar_Sum_Order_By>;
  var_pop?: Maybe<Hermes_Event_Traccar_Var_Pop_Order_By>;
  var_samp?: Maybe<Hermes_Event_Traccar_Var_Samp_Order_By>;
  variance?: Maybe<Hermes_Event_Traccar_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Hermes_Event_Traccar_Avg_Fields = {
  __typename?: 'hermes_event_traccar_avg_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Avg_Order_By = {
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hermes.event_traccar". All fields are combined with a logical 'AND'. */
export type Hermes_Event_Traccar_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Hermes_Event_Traccar_Bool_Exp>>>;
  _not?: Maybe<Hermes_Event_Traccar_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Hermes_Event_Traccar_Bool_Exp>>>;
  address?: Maybe<String_Comparison_Exp>;
  attributes?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  device?: Maybe<Hermes_Device_Bool_Exp>;
  device_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  latitude?: Maybe<Float8_Comparison_Exp>;
  longitude?: Maybe<Float8_Comparison_Exp>;
  server_time?: Maybe<Timestamptz_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Hermes_Event_Traccar_Max_Fields = {
  __typename?: 'hermes_event_traccar_max_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  server_time?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Max_Order_By = {
  address?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  device_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  server_time?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hermes_Event_Traccar_Min_Fields = {
  __typename?: 'hermes_event_traccar_min_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  server_time?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Min_Order_By = {
  address?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  device_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  server_time?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** ordering options when selecting data from "hermes.event_traccar" */
export type Hermes_Event_Traccar_Order_By = {
  address?: Maybe<Order_By>;
  attributes?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  device?: Maybe<Hermes_Device_Order_By>;
  device_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  server_time?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "hermes.event_traccar" */
export type Hermes_Event_Traccar_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hermes.event_traccar" */
export enum Hermes_Event_Traccar_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  ServerTime = 'server_time',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** aggregate stddev on columns */
export type Hermes_Event_Traccar_Stddev_Fields = {
  __typename?: 'hermes_event_traccar_stddev_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Stddev_Order_By = {
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hermes_Event_Traccar_Stddev_Pop_Fields = {
  __typename?: 'hermes_event_traccar_stddev_pop_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Stddev_Pop_Order_By = {
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hermes_Event_Traccar_Stddev_Samp_Fields = {
  __typename?: 'hermes_event_traccar_stddev_samp_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Stddev_Samp_Order_By = {
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Hermes_Event_Traccar_Sum_Fields = {
  __typename?: 'hermes_event_traccar_sum_fields';
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Sum_Order_By = {
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Hermes_Event_Traccar_Var_Pop_Fields = {
  __typename?: 'hermes_event_traccar_var_pop_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Var_Pop_Order_By = {
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hermes_Event_Traccar_Var_Samp_Fields = {
  __typename?: 'hermes_event_traccar_var_samp_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Var_Samp_Order_By = {
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Hermes_Event_Traccar_Variance_Fields = {
  __typename?: 'hermes_event_traccar_variance_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Variance_Order_By = {
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** columns and relationships of "hermes.event_type" */
export type Hermes_Event_Type = {
  __typename?: 'hermes_event_type';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  icon_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  slug_name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  workshift_events: Array<Hermes_Workshift_Event>;
};

/** columns and relationships of "hermes.event_type" */
export type Hermes_Event_TypeWorkshift_EventsArgs = {
  distinct_on?: Maybe<Array<Hermes_Workshift_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Workshift_Event_Order_By>>;
  where?: Maybe<Hermes_Workshift_Event_Bool_Exp>;
};

/** aggregated selection of "hermes.event_type" */
export type Hermes_Event_Type_Aggregate = {
  __typename?: 'hermes_event_type_aggregate';
  aggregate?: Maybe<Hermes_Event_Type_Aggregate_Fields>;
  nodes: Array<Hermes_Event_Type>;
};

/** aggregate fields of "hermes.event_type" */
export type Hermes_Event_Type_Aggregate_Fields = {
  __typename?: 'hermes_event_type_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hermes_Event_Type_Max_Fields>;
  min?: Maybe<Hermes_Event_Type_Min_Fields>;
};

/** aggregate fields of "hermes.event_type" */
export type Hermes_Event_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hermes_Event_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hermes.event_type" */
export type Hermes_Event_Type_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Hermes_Event_Type_Max_Order_By>;
  min?: Maybe<Hermes_Event_Type_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "hermes.event_type". All fields are combined with a logical 'AND'. */
export type Hermes_Event_Type_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Hermes_Event_Type_Bool_Exp>>>;
  _not?: Maybe<Hermes_Event_Type_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Hermes_Event_Type_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  icon_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  slug_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  workshift_events?: Maybe<Hermes_Workshift_Event_Bool_Exp>;
};

/** aggregate max on columns */
export type Hermes_Event_Type_Max_Fields = {
  __typename?: 'hermes_event_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  icon_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hermes.event_type" */
export type Hermes_Event_Type_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  icon_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  slug_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hermes_Event_Type_Min_Fields = {
  __typename?: 'hermes_event_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  icon_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "hermes.event_type" */
export type Hermes_Event_Type_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  icon_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  slug_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** ordering options when selecting data from "hermes.event_type" */
export type Hermes_Event_Type_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  icon_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  slug_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "hermes.event_type" */
export type Hermes_Event_Type_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hermes.event_type" */
export enum Hermes_Event_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  IconName = 'icon_name',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SlugName = 'slug_name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** aggregate var_pop on columns */
export type Hermes_Event_Var_Pop_Fields = {
  __typename?: 'hermes_event_var_pop_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hermes.event" */
export type Hermes_Event_Var_Pop_Order_By = {
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hermes_Event_Var_Samp_Fields = {
  __typename?: 'hermes_event_var_samp_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hermes.event" */
export type Hermes_Event_Var_Samp_Order_By = {
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Hermes_Event_Variance_Fields = {
  __typename?: 'hermes_event_variance_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hermes.event" */
export type Hermes_Event_Variance_Order_By = {
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
};

/** columns and relationships of "hermes.position" */
export type Hermes_Position = {
  __typename?: 'hermes_position';
  accuracy: Scalars['float8'];
  address?: Maybe<Scalars['String']>;
  altitude: Scalars['float8'];
  attributes: Scalars['jsonb'];
  course: Scalars['float8'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  device?: Maybe<Hermes_Device>;
  device_id?: Maybe<Scalars['uuid']>;
  device_time: Scalars['timestamptz'];
  fix_time: Scalars['timestamptz'];
  gadget_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  latitude: Scalars['float8'];
  longitude: Scalars['float8'];
  organization_id: Scalars['uuid'];
  server_time: Scalars['timestamptz'];
  speed: Scalars['float8'];
  updated_at: Scalars['timestamptz'];
  valid: Scalars['Boolean'];
  /** An object relationship */
  vehicle: Vehicle;
  vehicle_id: Scalars['uuid'];
  weight?: Maybe<Scalars['float8']>;
};

/** columns and relationships of "hermes.position" */
export type Hermes_PositionAttributesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "hermes.position" */
export type Hermes_Position_Aggregate = {
  __typename?: 'hermes_position_aggregate';
  aggregate?: Maybe<Hermes_Position_Aggregate_Fields>;
  nodes: Array<Hermes_Position>;
};

/** aggregate fields of "hermes.position" */
export type Hermes_Position_Aggregate_Fields = {
  __typename?: 'hermes_position_aggregate_fields';
  avg?: Maybe<Hermes_Position_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hermes_Position_Max_Fields>;
  min?: Maybe<Hermes_Position_Min_Fields>;
  stddev?: Maybe<Hermes_Position_Stddev_Fields>;
  stddev_pop?: Maybe<Hermes_Position_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hermes_Position_Stddev_Samp_Fields>;
  sum?: Maybe<Hermes_Position_Sum_Fields>;
  var_pop?: Maybe<Hermes_Position_Var_Pop_Fields>;
  var_samp?: Maybe<Hermes_Position_Var_Samp_Fields>;
  variance?: Maybe<Hermes_Position_Variance_Fields>;
};

/** aggregate fields of "hermes.position" */
export type Hermes_Position_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hermes_Position_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hermes.position" */
export type Hermes_Position_Aggregate_Order_By = {
  avg?: Maybe<Hermes_Position_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Hermes_Position_Max_Order_By>;
  min?: Maybe<Hermes_Position_Min_Order_By>;
  stddev?: Maybe<Hermes_Position_Stddev_Order_By>;
  stddev_pop?: Maybe<Hermes_Position_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Hermes_Position_Stddev_Samp_Order_By>;
  sum?: Maybe<Hermes_Position_Sum_Order_By>;
  var_pop?: Maybe<Hermes_Position_Var_Pop_Order_By>;
  var_samp?: Maybe<Hermes_Position_Var_Samp_Order_By>;
  variance?: Maybe<Hermes_Position_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Hermes_Position_Avg_Fields = {
  __typename?: 'hermes_position_avg_fields';
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hermes.position" */
export type Hermes_Position_Avg_Order_By = {
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hermes.position". All fields are combined with a logical 'AND'. */
export type Hermes_Position_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Hermes_Position_Bool_Exp>>>;
  _not?: Maybe<Hermes_Position_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Hermes_Position_Bool_Exp>>>;
  accuracy?: Maybe<Float8_Comparison_Exp>;
  address?: Maybe<String_Comparison_Exp>;
  altitude?: Maybe<Float8_Comparison_Exp>;
  attributes?: Maybe<Jsonb_Comparison_Exp>;
  course?: Maybe<Float8_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  device?: Maybe<Hermes_Device_Bool_Exp>;
  device_id?: Maybe<Uuid_Comparison_Exp>;
  device_time?: Maybe<Timestamptz_Comparison_Exp>;
  fix_time?: Maybe<Timestamptz_Comparison_Exp>;
  gadget_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  latitude?: Maybe<Float8_Comparison_Exp>;
  longitude?: Maybe<Float8_Comparison_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  server_time?: Maybe<Timestamptz_Comparison_Exp>;
  speed?: Maybe<Float8_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  valid?: Maybe<Boolean_Comparison_Exp>;
  vehicle?: Maybe<Vehicle_Bool_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  weight?: Maybe<Float8_Comparison_Exp>;
};

/** columns and relationships of "hermes.position_gadget" */
export type Hermes_Position_Gadget = {
  __typename?: 'hermes_position_gadget';
  acceleration: Scalars['float8'];
  accuracy: Scalars['float8'];
  altitude: Scalars['float8'];
  attributes: Scalars['jsonb'];
  course: Scalars['float8'];
  created_at: Scalars['timestamptz'];
  fix_time: Scalars['timestamptz'];
  gadget_id: Scalars['String'];
  id: Scalars['uuid'];
  latitude: Scalars['float8'];
  longitude: Scalars['float8'];
  organization_id: Scalars['uuid'];
  server_time: Scalars['timestamptz'];
  speed: Scalars['float8'];
  speed_accuracy: Scalars['float8'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicle;
  vehicle_id: Scalars['uuid'];
  weight?: Maybe<Scalars['float8']>;
};

/** columns and relationships of "hermes.position_gadget" */
export type Hermes_Position_GadgetAttributesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "hermes.position_gadget" */
export type Hermes_Position_Gadget_Aggregate = {
  __typename?: 'hermes_position_gadget_aggregate';
  aggregate?: Maybe<Hermes_Position_Gadget_Aggregate_Fields>;
  nodes: Array<Hermes_Position_Gadget>;
};

/** aggregate fields of "hermes.position_gadget" */
export type Hermes_Position_Gadget_Aggregate_Fields = {
  __typename?: 'hermes_position_gadget_aggregate_fields';
  avg?: Maybe<Hermes_Position_Gadget_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hermes_Position_Gadget_Max_Fields>;
  min?: Maybe<Hermes_Position_Gadget_Min_Fields>;
  stddev?: Maybe<Hermes_Position_Gadget_Stddev_Fields>;
  stddev_pop?: Maybe<Hermes_Position_Gadget_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hermes_Position_Gadget_Stddev_Samp_Fields>;
  sum?: Maybe<Hermes_Position_Gadget_Sum_Fields>;
  var_pop?: Maybe<Hermes_Position_Gadget_Var_Pop_Fields>;
  var_samp?: Maybe<Hermes_Position_Gadget_Var_Samp_Fields>;
  variance?: Maybe<Hermes_Position_Gadget_Variance_Fields>;
};

/** aggregate fields of "hermes.position_gadget" */
export type Hermes_Position_Gadget_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hermes_Position_Gadget_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Aggregate_Order_By = {
  avg?: Maybe<Hermes_Position_Gadget_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Hermes_Position_Gadget_Max_Order_By>;
  min?: Maybe<Hermes_Position_Gadget_Min_Order_By>;
  stddev?: Maybe<Hermes_Position_Gadget_Stddev_Order_By>;
  stddev_pop?: Maybe<Hermes_Position_Gadget_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Hermes_Position_Gadget_Stddev_Samp_Order_By>;
  sum?: Maybe<Hermes_Position_Gadget_Sum_Order_By>;
  var_pop?: Maybe<Hermes_Position_Gadget_Var_Pop_Order_By>;
  var_samp?: Maybe<Hermes_Position_Gadget_Var_Samp_Order_By>;
  variance?: Maybe<Hermes_Position_Gadget_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hermes_Position_Gadget_Append_Input = {
  attributes?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Arr_Rel_Insert_Input = {
  data: Array<Hermes_Position_Gadget_Insert_Input>;
  on_conflict?: Maybe<Hermes_Position_Gadget_On_Conflict>;
};

/** aggregate avg on columns */
export type Hermes_Position_Gadget_Avg_Fields = {
  __typename?: 'hermes_position_gadget_avg_fields';
  acceleration?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  speed_accuracy?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Avg_Order_By = {
  acceleration?: Maybe<Order_By>;
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  speed_accuracy?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hermes.position_gadget". All fields are combined with a logical 'AND'. */
export type Hermes_Position_Gadget_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Hermes_Position_Gadget_Bool_Exp>>>;
  _not?: Maybe<Hermes_Position_Gadget_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Hermes_Position_Gadget_Bool_Exp>>>;
  acceleration?: Maybe<Float8_Comparison_Exp>;
  accuracy?: Maybe<Float8_Comparison_Exp>;
  altitude?: Maybe<Float8_Comparison_Exp>;
  attributes?: Maybe<Jsonb_Comparison_Exp>;
  course?: Maybe<Float8_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  fix_time?: Maybe<Timestamptz_Comparison_Exp>;
  gadget_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  latitude?: Maybe<Float8_Comparison_Exp>;
  longitude?: Maybe<Float8_Comparison_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  server_time?: Maybe<Timestamptz_Comparison_Exp>;
  speed?: Maybe<Float8_Comparison_Exp>;
  speed_accuracy?: Maybe<Float8_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vehicle?: Maybe<Vehicle_Bool_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  weight?: Maybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "hermes.position_gadget" */
export enum Hermes_Position_Gadget_Constraint {
  /** unique or primary key constraint */
  PositionGadgetPkey = 'position_gadget_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hermes_Position_Gadget_Delete_At_Path_Input = {
  attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hermes_Position_Gadget_Delete_Elem_Input = {
  attributes?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hermes_Position_Gadget_Delete_Key_Input = {
  attributes?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Inc_Input = {
  acceleration?: Maybe<Scalars['float8']>;
  accuracy?: Maybe<Scalars['float8']>;
  altitude?: Maybe<Scalars['float8']>;
  course?: Maybe<Scalars['float8']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  speed?: Maybe<Scalars['float8']>;
  speed_accuracy?: Maybe<Scalars['float8']>;
  weight?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Insert_Input = {
  acceleration?: Maybe<Scalars['float8']>;
  accuracy?: Maybe<Scalars['float8']>;
  altitude?: Maybe<Scalars['float8']>;
  attributes?: Maybe<Scalars['jsonb']>;
  course?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fix_time?: Maybe<Scalars['timestamptz']>;
  gadget_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  server_time?: Maybe<Scalars['timestamptz']>;
  speed?: Maybe<Scalars['float8']>;
  speed_accuracy?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle?: Maybe<Vehicle_Obj_Rel_Insert_Input>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  weight?: Maybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Hermes_Position_Gadget_Max_Fields = {
  __typename?: 'hermes_position_gadget_max_fields';
  acceleration?: Maybe<Scalars['float8']>;
  accuracy?: Maybe<Scalars['float8']>;
  altitude?: Maybe<Scalars['float8']>;
  course?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fix_time?: Maybe<Scalars['timestamptz']>;
  gadget_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  server_time?: Maybe<Scalars['timestamptz']>;
  speed?: Maybe<Scalars['float8']>;
  speed_accuracy?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  weight?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Max_Order_By = {
  acceleration?: Maybe<Order_By>;
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  fix_time?: Maybe<Order_By>;
  gadget_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  server_time?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  speed_accuracy?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hermes_Position_Gadget_Min_Fields = {
  __typename?: 'hermes_position_gadget_min_fields';
  acceleration?: Maybe<Scalars['float8']>;
  accuracy?: Maybe<Scalars['float8']>;
  altitude?: Maybe<Scalars['float8']>;
  course?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fix_time?: Maybe<Scalars['timestamptz']>;
  gadget_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  server_time?: Maybe<Scalars['timestamptz']>;
  speed?: Maybe<Scalars['float8']>;
  speed_accuracy?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  weight?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Min_Order_By = {
  acceleration?: Maybe<Order_By>;
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  fix_time?: Maybe<Order_By>;
  gadget_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  server_time?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  speed_accuracy?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** response of any mutation on the table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Mutation_Response = {
  __typename?: 'hermes_position_gadget_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Hermes_Position_Gadget>;
};

/** input type for inserting object relation for remote table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Obj_Rel_Insert_Input = {
  data: Hermes_Position_Gadget_Insert_Input;
  on_conflict?: Maybe<Hermes_Position_Gadget_On_Conflict>;
};

/** on conflict condition type for table "hermes.position_gadget" */
export type Hermes_Position_Gadget_On_Conflict = {
  constraint: Hermes_Position_Gadget_Constraint;
  update_columns: Array<Hermes_Position_Gadget_Update_Column>;
  where?: Maybe<Hermes_Position_Gadget_Bool_Exp>;
};

/** ordering options when selecting data from "hermes.position_gadget" */
export type Hermes_Position_Gadget_Order_By = {
  acceleration?: Maybe<Order_By>;
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  attributes?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  fix_time?: Maybe<Order_By>;
  gadget_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  server_time?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  speed_accuracy?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle?: Maybe<Vehicle_Order_By>;
  vehicle_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** primary key columns input for table: "hermes.position_gadget" */
export type Hermes_Position_Gadget_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hermes_Position_Gadget_Prepend_Input = {
  attributes?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "hermes.position_gadget" */
export enum Hermes_Position_Gadget_Select_Column {
  /** column name */
  Acceleration = 'acceleration',
  /** column name */
  Accuracy = 'accuracy',
  /** column name */
  Altitude = 'altitude',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  Course = 'course',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FixTime = 'fix_time',
  /** column name */
  GadgetId = 'gadget_id',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ServerTime = 'server_time',
  /** column name */
  Speed = 'speed',
  /** column name */
  SpeedAccuracy = 'speed_accuracy',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  Weight = 'weight',
}

/** input type for updating data in table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Set_Input = {
  acceleration?: Maybe<Scalars['float8']>;
  accuracy?: Maybe<Scalars['float8']>;
  altitude?: Maybe<Scalars['float8']>;
  attributes?: Maybe<Scalars['jsonb']>;
  course?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fix_time?: Maybe<Scalars['timestamptz']>;
  gadget_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  server_time?: Maybe<Scalars['timestamptz']>;
  speed?: Maybe<Scalars['float8']>;
  speed_accuracy?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  weight?: Maybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Hermes_Position_Gadget_Stddev_Fields = {
  __typename?: 'hermes_position_gadget_stddev_fields';
  acceleration?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  speed_accuracy?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Stddev_Order_By = {
  acceleration?: Maybe<Order_By>;
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  speed_accuracy?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hermes_Position_Gadget_Stddev_Pop_Fields = {
  __typename?: 'hermes_position_gadget_stddev_pop_fields';
  acceleration?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  speed_accuracy?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Stddev_Pop_Order_By = {
  acceleration?: Maybe<Order_By>;
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  speed_accuracy?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hermes_Position_Gadget_Stddev_Samp_Fields = {
  __typename?: 'hermes_position_gadget_stddev_samp_fields';
  acceleration?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  speed_accuracy?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Stddev_Samp_Order_By = {
  acceleration?: Maybe<Order_By>;
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  speed_accuracy?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Hermes_Position_Gadget_Sum_Fields = {
  __typename?: 'hermes_position_gadget_sum_fields';
  acceleration?: Maybe<Scalars['float8']>;
  accuracy?: Maybe<Scalars['float8']>;
  altitude?: Maybe<Scalars['float8']>;
  course?: Maybe<Scalars['float8']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  speed?: Maybe<Scalars['float8']>;
  speed_accuracy?: Maybe<Scalars['float8']>;
  weight?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Sum_Order_By = {
  acceleration?: Maybe<Order_By>;
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  speed_accuracy?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** update columns of table "hermes.position_gadget" */
export enum Hermes_Position_Gadget_Update_Column {
  /** column name */
  Acceleration = 'acceleration',
  /** column name */
  Accuracy = 'accuracy',
  /** column name */
  Altitude = 'altitude',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  Course = 'course',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FixTime = 'fix_time',
  /** column name */
  GadgetId = 'gadget_id',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ServerTime = 'server_time',
  /** column name */
  Speed = 'speed',
  /** column name */
  SpeedAccuracy = 'speed_accuracy',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  Weight = 'weight',
}

/** aggregate var_pop on columns */
export type Hermes_Position_Gadget_Var_Pop_Fields = {
  __typename?: 'hermes_position_gadget_var_pop_fields';
  acceleration?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  speed_accuracy?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Var_Pop_Order_By = {
  acceleration?: Maybe<Order_By>;
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  speed_accuracy?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hermes_Position_Gadget_Var_Samp_Fields = {
  __typename?: 'hermes_position_gadget_var_samp_fields';
  acceleration?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  speed_accuracy?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Var_Samp_Order_By = {
  acceleration?: Maybe<Order_By>;
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  speed_accuracy?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Hermes_Position_Gadget_Variance_Fields = {
  __typename?: 'hermes_position_gadget_variance_fields';
  acceleration?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  speed_accuracy?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Variance_Order_By = {
  acceleration?: Maybe<Order_By>;
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  speed_accuracy?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Hermes_Position_Max_Fields = {
  __typename?: 'hermes_position_max_fields';
  accuracy?: Maybe<Scalars['float8']>;
  address?: Maybe<Scalars['String']>;
  altitude?: Maybe<Scalars['float8']>;
  course?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_id?: Maybe<Scalars['uuid']>;
  device_time?: Maybe<Scalars['timestamptz']>;
  fix_time?: Maybe<Scalars['timestamptz']>;
  gadget_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  server_time?: Maybe<Scalars['timestamptz']>;
  speed?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  weight?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "hermes.position" */
export type Hermes_Position_Max_Order_By = {
  accuracy?: Maybe<Order_By>;
  address?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  device_id?: Maybe<Order_By>;
  device_time?: Maybe<Order_By>;
  fix_time?: Maybe<Order_By>;
  gadget_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  server_time?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hermes_Position_Min_Fields = {
  __typename?: 'hermes_position_min_fields';
  accuracy?: Maybe<Scalars['float8']>;
  address?: Maybe<Scalars['String']>;
  altitude?: Maybe<Scalars['float8']>;
  course?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_id?: Maybe<Scalars['uuid']>;
  device_time?: Maybe<Scalars['timestamptz']>;
  fix_time?: Maybe<Scalars['timestamptz']>;
  gadget_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  server_time?: Maybe<Scalars['timestamptz']>;
  speed?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  weight?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "hermes.position" */
export type Hermes_Position_Min_Order_By = {
  accuracy?: Maybe<Order_By>;
  address?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  device_id?: Maybe<Order_By>;
  device_time?: Maybe<Order_By>;
  fix_time?: Maybe<Order_By>;
  gadget_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  server_time?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** ordering options when selecting data from "hermes.position" */
export type Hermes_Position_Order_By = {
  accuracy?: Maybe<Order_By>;
  address?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  attributes?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  device?: Maybe<Hermes_Device_Order_By>;
  device_id?: Maybe<Order_By>;
  device_time?: Maybe<Order_By>;
  fix_time?: Maybe<Order_By>;
  gadget_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  server_time?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  valid?: Maybe<Order_By>;
  vehicle?: Maybe<Vehicle_Order_By>;
  vehicle_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** primary key columns input for table: "hermes.position" */
export type Hermes_Position_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hermes.position" */
export enum Hermes_Position_Select_Column {
  /** column name */
  Accuracy = 'accuracy',
  /** column name */
  Address = 'address',
  /** column name */
  Altitude = 'altitude',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  Course = 'course',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  DeviceTime = 'device_time',
  /** column name */
  FixTime = 'fix_time',
  /** column name */
  GadgetId = 'gadget_id',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ServerTime = 'server_time',
  /** column name */
  Speed = 'speed',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Valid = 'valid',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  Weight = 'weight',
}

/** aggregate stddev on columns */
export type Hermes_Position_Stddev_Fields = {
  __typename?: 'hermes_position_stddev_fields';
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hermes.position" */
export type Hermes_Position_Stddev_Order_By = {
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hermes_Position_Stddev_Pop_Fields = {
  __typename?: 'hermes_position_stddev_pop_fields';
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hermes.position" */
export type Hermes_Position_Stddev_Pop_Order_By = {
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hermes_Position_Stddev_Samp_Fields = {
  __typename?: 'hermes_position_stddev_samp_fields';
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hermes.position" */
export type Hermes_Position_Stddev_Samp_Order_By = {
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Hermes_Position_Sum_Fields = {
  __typename?: 'hermes_position_sum_fields';
  accuracy?: Maybe<Scalars['float8']>;
  altitude?: Maybe<Scalars['float8']>;
  course?: Maybe<Scalars['float8']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  speed?: Maybe<Scalars['float8']>;
  weight?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "hermes.position" */
export type Hermes_Position_Sum_Order_By = {
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Hermes_Position_Var_Pop_Fields = {
  __typename?: 'hermes_position_var_pop_fields';
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hermes.position" */
export type Hermes_Position_Var_Pop_Order_By = {
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hermes_Position_Var_Samp_Fields = {
  __typename?: 'hermes_position_var_samp_fields';
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hermes.position" */
export type Hermes_Position_Var_Samp_Order_By = {
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Hermes_Position_Variance_Fields = {
  __typename?: 'hermes_position_variance_fields';
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hermes.position" */
export type Hermes_Position_Variance_Order_By = {
  accuracy?: Maybe<Order_By>;
  altitude?: Maybe<Order_By>;
  course?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  speed?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** columns and relationships of "hermes.workshift" */
export type Hermes_Workshift = {
  __typename?: 'hermes_workshift';
  created_at: Scalars['timestamptz'];
  ended_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  organization_id: Scalars['uuid'];
  started_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
  /** An object relationship */
  vehicle: Vehicle;
  vehicle_id: Scalars['uuid'];
  /** An array relationship */
  workshift_events: Array<Hermes_Workshift_Event>;
};

/** columns and relationships of "hermes.workshift" */
export type Hermes_WorkshiftWorkshift_EventsArgs = {
  distinct_on?: Maybe<Array<Hermes_Workshift_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Workshift_Event_Order_By>>;
  where?: Maybe<Hermes_Workshift_Event_Bool_Exp>;
};

/** aggregated selection of "hermes.workshift" */
export type Hermes_Workshift_Aggregate = {
  __typename?: 'hermes_workshift_aggregate';
  aggregate?: Maybe<Hermes_Workshift_Aggregate_Fields>;
  nodes: Array<Hermes_Workshift>;
};

/** aggregate fields of "hermes.workshift" */
export type Hermes_Workshift_Aggregate_Fields = {
  __typename?: 'hermes_workshift_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hermes_Workshift_Max_Fields>;
  min?: Maybe<Hermes_Workshift_Min_Fields>;
};

/** aggregate fields of "hermes.workshift" */
export type Hermes_Workshift_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hermes_Workshift_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hermes.workshift" */
export type Hermes_Workshift_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Hermes_Workshift_Max_Order_By>;
  min?: Maybe<Hermes_Workshift_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hermes.workshift" */
export type Hermes_Workshift_Arr_Rel_Insert_Input = {
  data: Array<Hermes_Workshift_Insert_Input>;
  on_conflict?: Maybe<Hermes_Workshift_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hermes.workshift". All fields are combined with a logical 'AND'. */
export type Hermes_Workshift_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Hermes_Workshift_Bool_Exp>>>;
  _not?: Maybe<Hermes_Workshift_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Hermes_Workshift_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  ended_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  started_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  vehicle?: Maybe<Vehicle_Bool_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  workshift_events?: Maybe<Hermes_Workshift_Event_Bool_Exp>;
};

/** unique or primary key constraints on table "hermes.workshift" */
export enum Hermes_Workshift_Constraint {
  /** unique or primary key constraint */
  WorkshiftPkey = 'workshift_pkey',
}

/** columns and relationships of "hermes.workshift_event" */
export type Hermes_Workshift_Event = {
  __typename?: 'hermes_workshift_event';
  created_at: Scalars['timestamptz'];
  ended_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  event_type: Hermes_Event_Type;
  event_type_id: Scalars['uuid'];
  id: Scalars['uuid'];
  latitude: Scalars['float8'];
  longitude: Scalars['float8'];
  started_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  workshift: Hermes_Workshift;
  workshift_id: Scalars['uuid'];
};

/** input type for inserting array relation for remote table "hermes.workshift_event" */
export type Hermes_Workshift_Event_Arr_Rel_Insert_Input = {
  data: Array<Hermes_Workshift_Event_Insert_Input>;
  on_conflict?: Maybe<Hermes_Workshift_Event_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hermes.workshift_event". All fields are combined with a logical 'AND'. */
export type Hermes_Workshift_Event_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Hermes_Workshift_Event_Bool_Exp>>>;
  _not?: Maybe<Hermes_Workshift_Event_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Hermes_Workshift_Event_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  ended_at?: Maybe<Timestamptz_Comparison_Exp>;
  event_type?: Maybe<Hermes_Event_Type_Bool_Exp>;
  event_type_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  latitude?: Maybe<Float8_Comparison_Exp>;
  longitude?: Maybe<Float8_Comparison_Exp>;
  started_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  workshift?: Maybe<Hermes_Workshift_Bool_Exp>;
  workshift_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "hermes.workshift_event" */
export enum Hermes_Workshift_Event_Constraint {
  /** unique or primary key constraint */
  WorkshiftEventPkey = 'workshift_event_pkey',
}

/** input type for incrementing integer column in table "hermes.workshift_event" */
export type Hermes_Workshift_Event_Inc_Input = {
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "hermes.workshift_event" */
export type Hermes_Workshift_Event_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  event_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  workshift?: Maybe<Hermes_Workshift_Obj_Rel_Insert_Input>;
  workshift_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "hermes.workshift_event" */
export type Hermes_Workshift_Event_Mutation_Response = {
  __typename?: 'hermes_workshift_event_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Hermes_Workshift_Event>;
};

/** input type for inserting object relation for remote table "hermes.workshift_event" */
export type Hermes_Workshift_Event_Obj_Rel_Insert_Input = {
  data: Hermes_Workshift_Event_Insert_Input;
  on_conflict?: Maybe<Hermes_Workshift_Event_On_Conflict>;
};

/** on conflict condition type for table "hermes.workshift_event" */
export type Hermes_Workshift_Event_On_Conflict = {
  constraint: Hermes_Workshift_Event_Constraint;
  update_columns: Array<Hermes_Workshift_Event_Update_Column>;
  where?: Maybe<Hermes_Workshift_Event_Bool_Exp>;
};

/** ordering options when selecting data from "hermes.workshift_event" */
export type Hermes_Workshift_Event_Order_By = {
  created_at?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  event_type?: Maybe<Hermes_Event_Type_Order_By>;
  event_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latitude?: Maybe<Order_By>;
  longitude?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  workshift?: Maybe<Hermes_Workshift_Order_By>;
  workshift_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "hermes.workshift_event" */
export type Hermes_Workshift_Event_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hermes.workshift_event" */
export enum Hermes_Workshift_Event_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  EventTypeId = 'event_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkshiftId = 'workshift_id',
}

/** input type for updating data in table "hermes.workshift_event" */
export type Hermes_Workshift_Event_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  event_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  workshift_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "hermes.workshift_event" */
export enum Hermes_Workshift_Event_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  EventTypeId = 'event_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkshiftId = 'workshift_id',
}

/** input type for inserting data into table "hermes.workshift" */
export type Hermes_Workshift_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle?: Maybe<Vehicle_Obj_Rel_Insert_Input>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  workshift_events?: Maybe<Hermes_Workshift_Event_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Hermes_Workshift_Max_Fields = {
  __typename?: 'hermes_workshift_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "hermes.workshift" */
export type Hermes_Workshift_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hermes_Workshift_Min_Fields = {
  __typename?: 'hermes_workshift_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "hermes.workshift" */
export type Hermes_Workshift_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "hermes.workshift" */
export type Hermes_Workshift_Mutation_Response = {
  __typename?: 'hermes_workshift_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Hermes_Workshift>;
};

/** input type for inserting object relation for remote table "hermes.workshift" */
export type Hermes_Workshift_Obj_Rel_Insert_Input = {
  data: Hermes_Workshift_Insert_Input;
  on_conflict?: Maybe<Hermes_Workshift_On_Conflict>;
};

/** on conflict condition type for table "hermes.workshift" */
export type Hermes_Workshift_On_Conflict = {
  constraint: Hermes_Workshift_Constraint;
  update_columns: Array<Hermes_Workshift_Update_Column>;
  where?: Maybe<Hermes_Workshift_Bool_Exp>;
};

/** ordering options when selecting data from "hermes.workshift" */
export type Hermes_Workshift_Order_By = {
  created_at?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
  vehicle?: Maybe<Vehicle_Order_By>;
  vehicle_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "hermes.workshift" */
export type Hermes_Workshift_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hermes.workshift" */
export enum Hermes_Workshift_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** input type for updating data in table "hermes.workshift" */
export type Hermes_Workshift_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "hermes.workshift" */
export enum Hermes_Workshift_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** columns and relationships of "horimeter_history" */
export type Horimeter_History = {
  __typename?: 'horimeter_history';
  created_at: Scalars['timestamptz'];
  current: Scalars['float8'];
  /** An object relationship */
  equipment: Equipment;
  equipment_id: Scalars['uuid'];
  id: Scalars['uuid'];
  measured_at: Scalars['timestamptz'];
  previous: Scalars['float8'];
  source: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "horimeter_history" */
export type Horimeter_History_Aggregate = {
  __typename?: 'horimeter_history_aggregate';
  aggregate?: Maybe<Horimeter_History_Aggregate_Fields>;
  nodes: Array<Horimeter_History>;
};

/** aggregate fields of "horimeter_history" */
export type Horimeter_History_Aggregate_Fields = {
  __typename?: 'horimeter_history_aggregate_fields';
  avg?: Maybe<Horimeter_History_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Horimeter_History_Max_Fields>;
  min?: Maybe<Horimeter_History_Min_Fields>;
  stddev?: Maybe<Horimeter_History_Stddev_Fields>;
  stddev_pop?: Maybe<Horimeter_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Horimeter_History_Stddev_Samp_Fields>;
  sum?: Maybe<Horimeter_History_Sum_Fields>;
  var_pop?: Maybe<Horimeter_History_Var_Pop_Fields>;
  var_samp?: Maybe<Horimeter_History_Var_Samp_Fields>;
  variance?: Maybe<Horimeter_History_Variance_Fields>;
};

/** aggregate fields of "horimeter_history" */
export type Horimeter_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Horimeter_History_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "horimeter_history" */
export type Horimeter_History_Aggregate_Order_By = {
  avg?: Maybe<Horimeter_History_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Horimeter_History_Max_Order_By>;
  min?: Maybe<Horimeter_History_Min_Order_By>;
  stddev?: Maybe<Horimeter_History_Stddev_Order_By>;
  stddev_pop?: Maybe<Horimeter_History_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Horimeter_History_Stddev_Samp_Order_By>;
  sum?: Maybe<Horimeter_History_Sum_Order_By>;
  var_pop?: Maybe<Horimeter_History_Var_Pop_Order_By>;
  var_samp?: Maybe<Horimeter_History_Var_Samp_Order_By>;
  variance?: Maybe<Horimeter_History_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "horimeter_history" */
export type Horimeter_History_Arr_Rel_Insert_Input = {
  data: Array<Horimeter_History_Insert_Input>;
  on_conflict?: Maybe<Horimeter_History_On_Conflict>;
};

/** aggregate avg on columns */
export type Horimeter_History_Avg_Fields = {
  __typename?: 'horimeter_history_avg_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "horimeter_history" */
export type Horimeter_History_Avg_Order_By = {
  current?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "horimeter_history". All fields are combined with a logical 'AND'. */
export type Horimeter_History_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Horimeter_History_Bool_Exp>>>;
  _not?: Maybe<Horimeter_History_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Horimeter_History_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  current?: Maybe<Float8_Comparison_Exp>;
  equipment?: Maybe<Equipment_Bool_Exp>;
  equipment_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  measured_at?: Maybe<Timestamptz_Comparison_Exp>;
  previous?: Maybe<Float8_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "horimeter_history" */
export enum Horimeter_History_Constraint {
  /** unique or primary key constraint */
  HorimeterHistoryPkey = 'horimeter_history_pkey',
}

/** input type for incrementing integer column in table "horimeter_history" */
export type Horimeter_History_Inc_Input = {
  current?: Maybe<Scalars['float8']>;
  previous?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "horimeter_history" */
export type Horimeter_History_Insert_Input = {
  current?: Maybe<Scalars['float8']>;
  equipment?: Maybe<Equipment_Obj_Rel_Insert_Input>;
  equipment_id?: Maybe<Scalars['uuid']>;
  measured_at?: Maybe<Scalars['timestamptz']>;
  previous?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Horimeter_History_Max_Fields = {
  __typename?: 'horimeter_history_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current?: Maybe<Scalars['float8']>;
  equipment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  measured_at?: Maybe<Scalars['timestamptz']>;
  previous?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "horimeter_history" */
export type Horimeter_History_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  equipment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  measured_at?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Horimeter_History_Min_Fields = {
  __typename?: 'horimeter_history_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current?: Maybe<Scalars['float8']>;
  equipment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  measured_at?: Maybe<Scalars['timestamptz']>;
  previous?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "horimeter_history" */
export type Horimeter_History_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  equipment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  measured_at?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "horimeter_history" */
export type Horimeter_History_Mutation_Response = {
  __typename?: 'horimeter_history_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Horimeter_History>;
};

/** input type for inserting object relation for remote table "horimeter_history" */
export type Horimeter_History_Obj_Rel_Insert_Input = {
  data: Horimeter_History_Insert_Input;
  on_conflict?: Maybe<Horimeter_History_On_Conflict>;
};

/** on conflict condition type for table "horimeter_history" */
export type Horimeter_History_On_Conflict = {
  constraint: Horimeter_History_Constraint;
  update_columns: Array<Horimeter_History_Update_Column>;
  where?: Maybe<Horimeter_History_Bool_Exp>;
};

/** ordering options when selecting data from "horimeter_history" */
export type Horimeter_History_Order_By = {
  created_at?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  equipment?: Maybe<Equipment_Order_By>;
  equipment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  measured_at?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "horimeter_history" */
export type Horimeter_History_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "horimeter_history" */
export enum Horimeter_History_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Current = 'current',
  /** column name */
  EquipmentId = 'equipment_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasuredAt = 'measured_at',
  /** column name */
  Previous = 'previous',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "horimeter_history" */
export type Horimeter_History_Set_Input = {
  current?: Maybe<Scalars['float8']>;
  equipment_id?: Maybe<Scalars['uuid']>;
  measured_at?: Maybe<Scalars['timestamptz']>;
  previous?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Horimeter_History_Stddev_Fields = {
  __typename?: 'horimeter_history_stddev_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "horimeter_history" */
export type Horimeter_History_Stddev_Order_By = {
  current?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Horimeter_History_Stddev_Pop_Fields = {
  __typename?: 'horimeter_history_stddev_pop_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "horimeter_history" */
export type Horimeter_History_Stddev_Pop_Order_By = {
  current?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Horimeter_History_Stddev_Samp_Fields = {
  __typename?: 'horimeter_history_stddev_samp_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "horimeter_history" */
export type Horimeter_History_Stddev_Samp_Order_By = {
  current?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Horimeter_History_Sum_Fields = {
  __typename?: 'horimeter_history_sum_fields';
  current?: Maybe<Scalars['float8']>;
  previous?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "horimeter_history" */
export type Horimeter_History_Sum_Order_By = {
  current?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
};

/** update columns of table "horimeter_history" */
export enum Horimeter_History_Update_Column {
  /** column name */
  Current = 'current',
  /** column name */
  EquipmentId = 'equipment_id',
  /** column name */
  MeasuredAt = 'measured_at',
  /** column name */
  Previous = 'previous',
  /** column name */
  Source = 'source',
}

/** aggregate var_pop on columns */
export type Horimeter_History_Var_Pop_Fields = {
  __typename?: 'horimeter_history_var_pop_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "horimeter_history" */
export type Horimeter_History_Var_Pop_Order_By = {
  current?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Horimeter_History_Var_Samp_Fields = {
  __typename?: 'horimeter_history_var_samp_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "horimeter_history" */
export type Horimeter_History_Var_Samp_Order_By = {
  current?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Horimeter_History_Variance_Fields = {
  __typename?: 'horimeter_history_variance_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "horimeter_history" */
export type Horimeter_History_Variance_Order_By = {
  current?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
};

/** columns and relationships of "integration" */
export type Integration = {
  __typename?: 'integration';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  organization_id: Scalars['uuid'];
  setup_completed_at?: Maybe<Scalars['timestamptz']>;
  setup_error_message?: Maybe<Scalars['String']>;
  setup_progress: Scalars['Int'];
  source: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** input type for inserting array relation for remote table "integration" */
export type Integration_Arr_Rel_Insert_Input = {
  data: Array<Integration_Insert_Input>;
};

/** Boolean expression to filter rows from the table "integration". All fields are combined with a logical 'AND'. */
export type Integration_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Integration_Bool_Exp>>>;
  _not?: Maybe<Integration_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Integration_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  setup_completed_at?: Maybe<Timestamptz_Comparison_Exp>;
  setup_error_message?: Maybe<String_Comparison_Exp>;
  setup_progress?: Maybe<Int_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "integration" */
export type Integration_Insert_Input = {
  credentials?: Maybe<Scalars['jsonb']>;
  source?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "integration" */
export type Integration_Mutation_Response = {
  __typename?: 'integration_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Integration>;
};

/** input type for inserting object relation for remote table "integration" */
export type Integration_Obj_Rel_Insert_Input = {
  data: Integration_Insert_Input;
};

/** ordering options when selecting data from "integration" */
export type Integration_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  setup_completed_at?: Maybe<Order_By>;
  setup_error_message?: Maybe<Order_By>;
  setup_progress?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "integration" */
export type Integration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "integration" */
export enum Integration_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  SetupCompletedAt = 'setup_completed_at',
  /** column name */
  SetupErrorMessage = 'setup_error_message',
  /** column name */
  SetupProgress = 'setup_progress',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};

/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** perform the action: "address_by_event_id" */
  address_by_event_id?: Maybe<AddressOutput>;
  /** perform the action: "address_by_position_id" */
  address_by_position_id?: Maybe<AddressOutput>;
  /** delete data from the table: "driver" */
  delete_driver?: Maybe<Driver_Mutation_Response>;
  /** delete single row from the table: "driver" */
  delete_driver_by_pk?: Maybe<Driver>;
  /** delete data from the table: "equipment" */
  delete_equipment?: Maybe<Equipment_Mutation_Response>;
  /** delete single row from the table: "equipment" */
  delete_equipment_by_pk?: Maybe<Equipment>;
  /** delete data from the table: "hermes.workshift" */
  delete_hermes_workshift?: Maybe<Hermes_Workshift_Mutation_Response>;
  /** delete single row from the table: "hermes.workshift" */
  delete_hermes_workshift_by_pk?: Maybe<Hermes_Workshift>;
  /** delete data from the table: "hermes.workshift_event" */
  delete_hermes_workshift_event?: Maybe<
    Hermes_Workshift_Event_Mutation_Response
  >;
  /** delete single row from the table: "hermes.workshift_event" */
  delete_hermes_workshift_event_by_pk?: Maybe<Hermes_Workshift_Event>;
  /** delete data from the table: "horimeter_history" */
  delete_horimeter_history?: Maybe<Horimeter_History_Mutation_Response>;
  /** delete single row from the table: "horimeter_history" */
  delete_horimeter_history_by_pk?: Maybe<Horimeter_History>;
  /** delete data from the table: "integration" */
  delete_integration?: Maybe<Integration_Mutation_Response>;
  /** delete single row from the table: "integration" */
  delete_integration_by_pk?: Maybe<Integration>;
  /** delete data from the table: "odometer_history" */
  delete_odometer_history?: Maybe<Odometer_History_Mutation_Response>;
  /** delete single row from the table: "odometer_history" */
  delete_odometer_history_by_pk?: Maybe<Odometer_History>;
  /** delete data from the table: "vehicle" */
  delete_vehicle?: Maybe<Vehicle_Mutation_Response>;
  /** delete single row from the table: "vehicle" */
  delete_vehicle_by_pk?: Maybe<Vehicle>;
  /** insert data into the table: "driver" */
  insert_driver?: Maybe<Driver_Mutation_Response>;
  /** insert a single row into the table: "driver" */
  insert_driver_one?: Maybe<Driver>;
  /** insert data into the table: "equipment" */
  insert_equipment?: Maybe<Equipment_Mutation_Response>;
  /** insert a single row into the table: "equipment" */
  insert_equipment_one?: Maybe<Equipment>;
  /** insert data into the table: "hermes.position_gadget" */
  insert_hermes_position_gadget?: Maybe<
    Hermes_Position_Gadget_Mutation_Response
  >;
  /** insert a single row into the table: "hermes.position_gadget" */
  insert_hermes_position_gadget_one?: Maybe<Hermes_Position_Gadget>;
  /** insert data into the table: "hermes.workshift" */
  insert_hermes_workshift?: Maybe<Hermes_Workshift_Mutation_Response>;
  /** insert data into the table: "hermes.workshift_event" */
  insert_hermes_workshift_event?: Maybe<
    Hermes_Workshift_Event_Mutation_Response
  >;
  /** insert a single row into the table: "hermes.workshift_event" */
  insert_hermes_workshift_event_one?: Maybe<Hermes_Workshift_Event>;
  /** insert a single row into the table: "hermes.workshift" */
  insert_hermes_workshift_one?: Maybe<Hermes_Workshift>;
  /** insert data into the table: "horimeter_history" */
  insert_horimeter_history?: Maybe<Horimeter_History_Mutation_Response>;
  /** insert a single row into the table: "horimeter_history" */
  insert_horimeter_history_one?: Maybe<Horimeter_History>;
  /** insert data into the table: "integration" */
  insert_integration?: Maybe<Integration_Mutation_Response>;
  /** insert a single row into the table: "integration" */
  insert_integration_one?: Maybe<Integration>;
  /** insert data into the table: "odometer_history" */
  insert_odometer_history?: Maybe<Odometer_History_Mutation_Response>;
  /** insert a single row into the table: "odometer_history" */
  insert_odometer_history_one?: Maybe<Odometer_History>;
  /** insert data into the table: "vehicle" */
  insert_vehicle?: Maybe<Vehicle_Mutation_Response>;
  /** insert a single row into the table: "vehicle" */
  insert_vehicle_one?: Maybe<Vehicle>;
  /** update data of the table: "driver" */
  update_driver?: Maybe<Driver_Mutation_Response>;
  /** update single row of the table: "driver" */
  update_driver_by_pk?: Maybe<Driver>;
  /** update data of the table: "equipment" */
  update_equipment?: Maybe<Equipment_Mutation_Response>;
  /** update single row of the table: "equipment" */
  update_equipment_by_pk?: Maybe<Equipment>;
  /** update data of the table: "hermes.position_gadget" */
  update_hermes_position_gadget?: Maybe<
    Hermes_Position_Gadget_Mutation_Response
  >;
  /** update single row of the table: "hermes.position_gadget" */
  update_hermes_position_gadget_by_pk?: Maybe<Hermes_Position_Gadget>;
  /** update data of the table: "hermes.workshift" */
  update_hermes_workshift?: Maybe<Hermes_Workshift_Mutation_Response>;
  /** update single row of the table: "hermes.workshift" */
  update_hermes_workshift_by_pk?: Maybe<Hermes_Workshift>;
  /** update data of the table: "hermes.workshift_event" */
  update_hermes_workshift_event?: Maybe<
    Hermes_Workshift_Event_Mutation_Response
  >;
  /** update single row of the table: "hermes.workshift_event" */
  update_hermes_workshift_event_by_pk?: Maybe<Hermes_Workshift_Event>;
  /** update data of the table: "horimeter_history" */
  update_horimeter_history?: Maybe<Horimeter_History_Mutation_Response>;
  /** update single row of the table: "horimeter_history" */
  update_horimeter_history_by_pk?: Maybe<Horimeter_History>;
  /** update data of the table: "odometer_history" */
  update_odometer_history?: Maybe<Odometer_History_Mutation_Response>;
  /** update single row of the table: "odometer_history" */
  update_odometer_history_by_pk?: Maybe<Odometer_History>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "vehicle" */
  update_vehicle?: Maybe<Vehicle_Mutation_Response>;
  /** update single row of the table: "vehicle" */
  update_vehicle_by_pk?: Maybe<Vehicle>;
};

/** mutation root */
export type Mutation_RootAddress_By_Event_IdArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootAddress_By_Position_IdArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_DriverArgs = {
  where: Driver_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Driver_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_EquipmentArgs = {
  where: Equipment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Equipment_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Hermes_WorkshiftArgs = {
  where: Hermes_Workshift_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Hermes_Workshift_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Hermes_Workshift_EventArgs = {
  where: Hermes_Workshift_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Hermes_Workshift_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Horimeter_HistoryArgs = {
  where: Horimeter_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Horimeter_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_IntegrationArgs = {
  where: Integration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Integration_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Odometer_HistoryArgs = {
  where: Odometer_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Odometer_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_VehicleArgs = {
  where: Vehicle_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vehicle_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootInsert_DriverArgs = {
  objects: Array<Driver_Insert_Input>;
  on_conflict?: Maybe<Driver_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Driver_OneArgs = {
  object: Driver_Insert_Input;
  on_conflict?: Maybe<Driver_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_EquipmentArgs = {
  objects: Array<Equipment_Insert_Input>;
  on_conflict?: Maybe<Equipment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Equipment_OneArgs = {
  object: Equipment_Insert_Input;
  on_conflict?: Maybe<Equipment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hermes_Position_GadgetArgs = {
  objects: Array<Hermes_Position_Gadget_Insert_Input>;
  on_conflict?: Maybe<Hermes_Position_Gadget_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hermes_Position_Gadget_OneArgs = {
  object: Hermes_Position_Gadget_Insert_Input;
  on_conflict?: Maybe<Hermes_Position_Gadget_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hermes_WorkshiftArgs = {
  objects: Array<Hermes_Workshift_Insert_Input>;
  on_conflict?: Maybe<Hermes_Workshift_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hermes_Workshift_EventArgs = {
  objects: Array<Hermes_Workshift_Event_Insert_Input>;
  on_conflict?: Maybe<Hermes_Workshift_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hermes_Workshift_Event_OneArgs = {
  object: Hermes_Workshift_Event_Insert_Input;
  on_conflict?: Maybe<Hermes_Workshift_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hermes_Workshift_OneArgs = {
  object: Hermes_Workshift_Insert_Input;
  on_conflict?: Maybe<Hermes_Workshift_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Horimeter_HistoryArgs = {
  objects: Array<Horimeter_History_Insert_Input>;
  on_conflict?: Maybe<Horimeter_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Horimeter_History_OneArgs = {
  object: Horimeter_History_Insert_Input;
  on_conflict?: Maybe<Horimeter_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_IntegrationArgs = {
  objects: Array<Integration_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Integration_OneArgs = {
  object: Integration_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Odometer_HistoryArgs = {
  objects: Array<Odometer_History_Insert_Input>;
  on_conflict?: Maybe<Odometer_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Odometer_History_OneArgs = {
  object: Odometer_History_Insert_Input;
  on_conflict?: Maybe<Odometer_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_VehicleArgs = {
  objects: Array<Vehicle_Insert_Input>;
  on_conflict?: Maybe<Vehicle_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vehicle_OneArgs = {
  object: Vehicle_Insert_Input;
  on_conflict?: Maybe<Vehicle_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_DriverArgs = {
  _set?: Maybe<Driver_Set_Input>;
  where: Driver_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Driver_By_PkArgs = {
  _set?: Maybe<Driver_Set_Input>;
  pk_columns: Driver_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_EquipmentArgs = {
  _set?: Maybe<Equipment_Set_Input>;
  where: Equipment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Equipment_By_PkArgs = {
  _set?: Maybe<Equipment_Set_Input>;
  pk_columns: Equipment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hermes_Position_GadgetArgs = {
  _append?: Maybe<Hermes_Position_Gadget_Append_Input>;
  _delete_at_path?: Maybe<Hermes_Position_Gadget_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Hermes_Position_Gadget_Delete_Elem_Input>;
  _delete_key?: Maybe<Hermes_Position_Gadget_Delete_Key_Input>;
  _inc?: Maybe<Hermes_Position_Gadget_Inc_Input>;
  _prepend?: Maybe<Hermes_Position_Gadget_Prepend_Input>;
  _set?: Maybe<Hermes_Position_Gadget_Set_Input>;
  where: Hermes_Position_Gadget_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hermes_Position_Gadget_By_PkArgs = {
  _append?: Maybe<Hermes_Position_Gadget_Append_Input>;
  _delete_at_path?: Maybe<Hermes_Position_Gadget_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Hermes_Position_Gadget_Delete_Elem_Input>;
  _delete_key?: Maybe<Hermes_Position_Gadget_Delete_Key_Input>;
  _inc?: Maybe<Hermes_Position_Gadget_Inc_Input>;
  _prepend?: Maybe<Hermes_Position_Gadget_Prepend_Input>;
  _set?: Maybe<Hermes_Position_Gadget_Set_Input>;
  pk_columns: Hermes_Position_Gadget_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hermes_WorkshiftArgs = {
  _set?: Maybe<Hermes_Workshift_Set_Input>;
  where: Hermes_Workshift_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hermes_Workshift_By_PkArgs = {
  _set?: Maybe<Hermes_Workshift_Set_Input>;
  pk_columns: Hermes_Workshift_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hermes_Workshift_EventArgs = {
  _inc?: Maybe<Hermes_Workshift_Event_Inc_Input>;
  _set?: Maybe<Hermes_Workshift_Event_Set_Input>;
  where: Hermes_Workshift_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hermes_Workshift_Event_By_PkArgs = {
  _inc?: Maybe<Hermes_Workshift_Event_Inc_Input>;
  _set?: Maybe<Hermes_Workshift_Event_Set_Input>;
  pk_columns: Hermes_Workshift_Event_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Horimeter_HistoryArgs = {
  _inc?: Maybe<Horimeter_History_Inc_Input>;
  _set?: Maybe<Horimeter_History_Set_Input>;
  where: Horimeter_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Horimeter_History_By_PkArgs = {
  _inc?: Maybe<Horimeter_History_Inc_Input>;
  _set?: Maybe<Horimeter_History_Set_Input>;
  pk_columns: Horimeter_History_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Odometer_HistoryArgs = {
  _inc?: Maybe<Odometer_History_Inc_Input>;
  _set?: Maybe<Odometer_History_Set_Input>;
  where: Odometer_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Odometer_History_By_PkArgs = {
  _inc?: Maybe<Odometer_History_Inc_Input>;
  _set?: Maybe<Odometer_History_Set_Input>;
  pk_columns: Odometer_History_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _append?: Maybe<User_Append_Input>;
  _delete_at_path?: Maybe<User_Delete_At_Path_Input>;
  _delete_elem?: Maybe<User_Delete_Elem_Input>;
  _delete_key?: Maybe<User_Delete_Key_Input>;
  _prepend?: Maybe<User_Prepend_Input>;
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _append?: Maybe<User_Append_Input>;
  _delete_at_path?: Maybe<User_Delete_At_Path_Input>;
  _delete_elem?: Maybe<User_Delete_Elem_Input>;
  _delete_key?: Maybe<User_Delete_Key_Input>;
  _prepend?: Maybe<User_Prepend_Input>;
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_VehicleArgs = {
  _inc?: Maybe<Vehicle_Inc_Input>;
  _set?: Maybe<Vehicle_Set_Input>;
  where: Vehicle_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vehicle_By_PkArgs = {
  _inc?: Maybe<Vehicle_Inc_Input>;
  _set?: Maybe<Vehicle_Set_Input>;
  pk_columns: Vehicle_Pk_Columns_Input;
};

/** columns and relationships of "odometer_history" */
export type Odometer_History = {
  __typename?: 'odometer_history';
  created_at: Scalars['timestamptz'];
  current: Scalars['float8'];
  id: Scalars['uuid'];
  measured_at: Scalars['timestamptz'];
  previous: Scalars['float8'];
  source: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicle;
  vehicle_id: Scalars['uuid'];
};

/** aggregated selection of "odometer_history" */
export type Odometer_History_Aggregate = {
  __typename?: 'odometer_history_aggregate';
  aggregate?: Maybe<Odometer_History_Aggregate_Fields>;
  nodes: Array<Odometer_History>;
};

/** aggregate fields of "odometer_history" */
export type Odometer_History_Aggregate_Fields = {
  __typename?: 'odometer_history_aggregate_fields';
  avg?: Maybe<Odometer_History_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Odometer_History_Max_Fields>;
  min?: Maybe<Odometer_History_Min_Fields>;
  stddev?: Maybe<Odometer_History_Stddev_Fields>;
  stddev_pop?: Maybe<Odometer_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Odometer_History_Stddev_Samp_Fields>;
  sum?: Maybe<Odometer_History_Sum_Fields>;
  var_pop?: Maybe<Odometer_History_Var_Pop_Fields>;
  var_samp?: Maybe<Odometer_History_Var_Samp_Fields>;
  variance?: Maybe<Odometer_History_Variance_Fields>;
};

/** aggregate fields of "odometer_history" */
export type Odometer_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Odometer_History_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "odometer_history" */
export type Odometer_History_Aggregate_Order_By = {
  avg?: Maybe<Odometer_History_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Odometer_History_Max_Order_By>;
  min?: Maybe<Odometer_History_Min_Order_By>;
  stddev?: Maybe<Odometer_History_Stddev_Order_By>;
  stddev_pop?: Maybe<Odometer_History_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Odometer_History_Stddev_Samp_Order_By>;
  sum?: Maybe<Odometer_History_Sum_Order_By>;
  var_pop?: Maybe<Odometer_History_Var_Pop_Order_By>;
  var_samp?: Maybe<Odometer_History_Var_Samp_Order_By>;
  variance?: Maybe<Odometer_History_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "odometer_history" */
export type Odometer_History_Arr_Rel_Insert_Input = {
  data: Array<Odometer_History_Insert_Input>;
  on_conflict?: Maybe<Odometer_History_On_Conflict>;
};

/** aggregate avg on columns */
export type Odometer_History_Avg_Fields = {
  __typename?: 'odometer_history_avg_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "odometer_history" */
export type Odometer_History_Avg_Order_By = {
  current?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "odometer_history". All fields are combined with a logical 'AND'. */
export type Odometer_History_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Odometer_History_Bool_Exp>>>;
  _not?: Maybe<Odometer_History_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Odometer_History_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  current?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  measured_at?: Maybe<Timestamptz_Comparison_Exp>;
  previous?: Maybe<Float8_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vehicle?: Maybe<Vehicle_Bool_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "odometer_history" */
export enum Odometer_History_Constraint {
  /** unique or primary key constraint */
  OdometerHistoryPkey = 'odometer_history_pkey',
}

/** input type for incrementing integer column in table "odometer_history" */
export type Odometer_History_Inc_Input = {
  current?: Maybe<Scalars['float8']>;
  previous?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "odometer_history" */
export type Odometer_History_Insert_Input = {
  current?: Maybe<Scalars['float8']>;
  measured_at?: Maybe<Scalars['timestamptz']>;
  previous?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  vehicle?: Maybe<Vehicle_Obj_Rel_Insert_Input>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Odometer_History_Max_Fields = {
  __typename?: 'odometer_history_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  measured_at?: Maybe<Scalars['timestamptz']>;
  previous?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "odometer_history" */
export type Odometer_History_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  measured_at?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Odometer_History_Min_Fields = {
  __typename?: 'odometer_history_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  measured_at?: Maybe<Scalars['timestamptz']>;
  previous?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "odometer_history" */
export type Odometer_History_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  measured_at?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "odometer_history" */
export type Odometer_History_Mutation_Response = {
  __typename?: 'odometer_history_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Odometer_History>;
};

/** input type for inserting object relation for remote table "odometer_history" */
export type Odometer_History_Obj_Rel_Insert_Input = {
  data: Odometer_History_Insert_Input;
  on_conflict?: Maybe<Odometer_History_On_Conflict>;
};

/** on conflict condition type for table "odometer_history" */
export type Odometer_History_On_Conflict = {
  constraint: Odometer_History_Constraint;
  update_columns: Array<Odometer_History_Update_Column>;
  where?: Maybe<Odometer_History_Bool_Exp>;
};

/** ordering options when selecting data from "odometer_history" */
export type Odometer_History_Order_By = {
  created_at?: Maybe<Order_By>;
  current?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  measured_at?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle?: Maybe<Vehicle_Order_By>;
  vehicle_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "odometer_history" */
export type Odometer_History_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "odometer_history" */
export enum Odometer_History_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Current = 'current',
  /** column name */
  Id = 'id',
  /** column name */
  MeasuredAt = 'measured_at',
  /** column name */
  Previous = 'previous',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** input type for updating data in table "odometer_history" */
export type Odometer_History_Set_Input = {
  current?: Maybe<Scalars['float8']>;
  measured_at?: Maybe<Scalars['timestamptz']>;
  previous?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Odometer_History_Stddev_Fields = {
  __typename?: 'odometer_history_stddev_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "odometer_history" */
export type Odometer_History_Stddev_Order_By = {
  current?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Odometer_History_Stddev_Pop_Fields = {
  __typename?: 'odometer_history_stddev_pop_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "odometer_history" */
export type Odometer_History_Stddev_Pop_Order_By = {
  current?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Odometer_History_Stddev_Samp_Fields = {
  __typename?: 'odometer_history_stddev_samp_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "odometer_history" */
export type Odometer_History_Stddev_Samp_Order_By = {
  current?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Odometer_History_Sum_Fields = {
  __typename?: 'odometer_history_sum_fields';
  current?: Maybe<Scalars['float8']>;
  previous?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "odometer_history" */
export type Odometer_History_Sum_Order_By = {
  current?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
};

/** update columns of table "odometer_history" */
export enum Odometer_History_Update_Column {
  /** column name */
  Current = 'current',
  /** column name */
  MeasuredAt = 'measured_at',
  /** column name */
  Previous = 'previous',
  /** column name */
  Source = 'source',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** aggregate var_pop on columns */
export type Odometer_History_Var_Pop_Fields = {
  __typename?: 'odometer_history_var_pop_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "odometer_history" */
export type Odometer_History_Var_Pop_Order_By = {
  current?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Odometer_History_Var_Samp_Fields = {
  __typename?: 'odometer_history_var_samp_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "odometer_history" */
export type Odometer_History_Var_Samp_Order_By = {
  current?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Odometer_History_Variance_Fields = {
  __typename?: 'odometer_history_variance_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "odometer_history" */
export type Odometer_History_Variance_Order_By = {
  current?: Maybe<Order_By>;
  previous?: Maybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "driver" */
  driver: Array<Driver>;
  /** fetch aggregated fields from the table: "driver" */
  driver_aggregate: Driver_Aggregate;
  /** fetch data from the table: "driver" using primary key columns */
  driver_by_pk?: Maybe<Driver>;
  /** fetch data from the table: "equipment" */
  equipment: Array<Equipment>;
  /** fetch aggregated fields from the table: "equipment" */
  equipment_aggregate: Equipment_Aggregate;
  /** fetch data from the table: "equipment" using primary key columns */
  equipment_by_pk?: Maybe<Equipment>;
  /** execute function "get_user_by_credentials" which returns "user" */
  get_user_by_credentials: Array<User>;
  /** fetch data from the table: "hermes.device" */
  hermes_device: Array<Hermes_Device>;
  /** fetch data from the table: "hermes.device" using primary key columns */
  hermes_device_by_pk?: Maybe<Hermes_Device>;
  /** fetch data from the table: "hermes.event" */
  hermes_event: Array<Hermes_Event>;
  /** fetch aggregated fields from the table: "hermes.event" */
  hermes_event_aggregate: Hermes_Event_Aggregate;
  /** fetch data from the table: "hermes.event" using primary key columns */
  hermes_event_by_pk?: Maybe<Hermes_Event>;
  /** fetch data from the table: "hermes.event_traccar" */
  hermes_event_traccar: Array<Hermes_Event_Traccar>;
  /** fetch aggregated fields from the table: "hermes.event_traccar" */
  hermes_event_traccar_aggregate: Hermes_Event_Traccar_Aggregate;
  /** fetch data from the table: "hermes.event_traccar" using primary key columns */
  hermes_event_traccar_by_pk?: Maybe<Hermes_Event_Traccar>;
  /** fetch data from the table: "hermes.event_type" */
  hermes_event_type: Array<Hermes_Event_Type>;
  /** fetch aggregated fields from the table: "hermes.event_type" */
  hermes_event_type_aggregate: Hermes_Event_Type_Aggregate;
  /** fetch data from the table: "hermes.event_type" using primary key columns */
  hermes_event_type_by_pk?: Maybe<Hermes_Event_Type>;
  /** fetch data from the table: "hermes.position" */
  hermes_position: Array<Hermes_Position>;
  /** fetch aggregated fields from the table: "hermes.position" */
  hermes_position_aggregate: Hermes_Position_Aggregate;
  /** fetch data from the table: "hermes.position" using primary key columns */
  hermes_position_by_pk?: Maybe<Hermes_Position>;
  /** fetch data from the table: "hermes.position_gadget" */
  hermes_position_gadget: Array<Hermes_Position_Gadget>;
  /** fetch aggregated fields from the table: "hermes.position_gadget" */
  hermes_position_gadget_aggregate: Hermes_Position_Gadget_Aggregate;
  /** fetch data from the table: "hermes.position_gadget" using primary key columns */
  hermes_position_gadget_by_pk?: Maybe<Hermes_Position_Gadget>;
  /** execute function "hermes.search_current_position_by_vehicle" which returns "hermes.position" */
  hermes_search_current_position_by_vehicle: Array<Hermes_Position>;
  /** execute function "hermes.search_current_position_by_vehicle" and query aggregates on result of table type "hermes.position" */
  hermes_search_current_position_by_vehicle_aggregate: Hermes_Position_Aggregate;
  /** fetch data from the table: "hermes.workshift" */
  hermes_workshift: Array<Hermes_Workshift>;
  /** fetch aggregated fields from the table: "hermes.workshift" */
  hermes_workshift_aggregate: Hermes_Workshift_Aggregate;
  /** fetch data from the table: "hermes.workshift" using primary key columns */
  hermes_workshift_by_pk?: Maybe<Hermes_Workshift>;
  /** fetch data from the table: "hermes.workshift_event" */
  hermes_workshift_event: Array<Hermes_Workshift_Event>;
  /** fetch data from the table: "hermes.workshift_event" using primary key columns */
  hermes_workshift_event_by_pk?: Maybe<Hermes_Workshift_Event>;
  /** fetch data from the table: "horimeter_history" */
  horimeter_history: Array<Horimeter_History>;
  /** fetch aggregated fields from the table: "horimeter_history" */
  horimeter_history_aggregate: Horimeter_History_Aggregate;
  /** fetch data from the table: "horimeter_history" using primary key columns */
  horimeter_history_by_pk?: Maybe<Horimeter_History>;
  /** fetch data from the table: "integration" */
  integration: Array<Integration>;
  /** fetch data from the table: "integration" using primary key columns */
  integration_by_pk?: Maybe<Integration>;
  /** fetch data from the table: "odometer_history" */
  odometer_history: Array<Odometer_History>;
  /** fetch aggregated fields from the table: "odometer_history" */
  odometer_history_aggregate: Odometer_History_Aggregate;
  /** fetch data from the table: "odometer_history" using primary key columns */
  odometer_history_by_pk?: Maybe<Odometer_History>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "vehicle" */
  vehicle: Array<Vehicle>;
  /** fetch aggregated fields from the table: "vehicle" */
  vehicle_aggregate: Vehicle_Aggregate;
  /** fetch data from the table: "vehicle" using primary key columns */
  vehicle_by_pk?: Maybe<Vehicle>;
  /** fetch data from the table: "vehicle_type" */
  vehicle_type: Array<Vehicle_Type>;
  /** fetch aggregated fields from the table: "vehicle_type" */
  vehicle_type_aggregate: Vehicle_Type_Aggregate;
  /** fetch data from the table: "vehicle_type" using primary key columns */
  vehicle_type_by_pk?: Maybe<Vehicle_Type>;
};

/** query root */
export type Query_RootDriverArgs = {
  distinct_on?: Maybe<Array<Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Driver_Order_By>>;
  where?: Maybe<Driver_Bool_Exp>;
};

/** query root */
export type Query_RootDriver_AggregateArgs = {
  distinct_on?: Maybe<Array<Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Driver_Order_By>>;
  where?: Maybe<Driver_Bool_Exp>;
};

/** query root */
export type Query_RootDriver_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootEquipmentArgs = {
  distinct_on?: Maybe<Array<Equipment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Equipment_Order_By>>;
  where?: Maybe<Equipment_Bool_Exp>;
};

/** query root */
export type Query_RootEquipment_AggregateArgs = {
  distinct_on?: Maybe<Array<Equipment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Equipment_Order_By>>;
  where?: Maybe<Equipment_Bool_Exp>;
};

/** query root */
export type Query_RootEquipment_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootGet_User_By_CredentialsArgs = {
  args: Get_User_By_Credentials_Args;
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_DeviceArgs = {
  distinct_on?: Maybe<Array<Hermes_Device_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Device_Order_By>>;
  where?: Maybe<Hermes_Device_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Device_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHermes_EventArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Order_By>>;
  where?: Maybe<Hermes_Event_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Order_By>>;
  where?: Maybe<Hermes_Event_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHermes_Event_TraccarArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Traccar_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Traccar_Order_By>>;
  where?: Maybe<Hermes_Event_Traccar_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Event_Traccar_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Traccar_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Traccar_Order_By>>;
  where?: Maybe<Hermes_Event_Traccar_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Event_Traccar_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHermes_Event_TypeArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Type_Order_By>>;
  where?: Maybe<Hermes_Event_Type_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Event_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Type_Order_By>>;
  where?: Maybe<Hermes_Event_Type_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Event_Type_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHermes_PositionArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Order_By>>;
  where?: Maybe<Hermes_Position_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Position_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Order_By>>;
  where?: Maybe<Hermes_Position_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Position_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHermes_Position_GadgetArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Gadget_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Gadget_Order_By>>;
  where?: Maybe<Hermes_Position_Gadget_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Position_Gadget_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Gadget_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Gadget_Order_By>>;
  where?: Maybe<Hermes_Position_Gadget_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Position_Gadget_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHermes_Search_Current_Position_By_VehicleArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Order_By>>;
  where?: Maybe<Hermes_Position_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Search_Current_Position_By_Vehicle_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Order_By>>;
  where?: Maybe<Hermes_Position_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_WorkshiftArgs = {
  distinct_on?: Maybe<Array<Hermes_Workshift_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Workshift_Order_By>>;
  where?: Maybe<Hermes_Workshift_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Workshift_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Workshift_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Workshift_Order_By>>;
  where?: Maybe<Hermes_Workshift_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Workshift_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHermes_Workshift_EventArgs = {
  distinct_on?: Maybe<Array<Hermes_Workshift_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Workshift_Event_Order_By>>;
  where?: Maybe<Hermes_Workshift_Event_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Workshift_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHorimeter_HistoryArgs = {
  distinct_on?: Maybe<Array<Horimeter_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Horimeter_History_Order_By>>;
  where?: Maybe<Horimeter_History_Bool_Exp>;
};

/** query root */
export type Query_RootHorimeter_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Horimeter_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Horimeter_History_Order_By>>;
  where?: Maybe<Horimeter_History_Bool_Exp>;
};

/** query root */
export type Query_RootHorimeter_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootIntegrationArgs = {
  distinct_on?: Maybe<Array<Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Order_By>>;
  where?: Maybe<Integration_Bool_Exp>;
};

/** query root */
export type Query_RootIntegration_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootOdometer_HistoryArgs = {
  distinct_on?: Maybe<Array<Odometer_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Odometer_History_Order_By>>;
  where?: Maybe<Odometer_History_Bool_Exp>;
};

/** query root */
export type Query_RootOdometer_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Odometer_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Odometer_History_Order_By>>;
  where?: Maybe<Odometer_History_Bool_Exp>;
};

/** query root */
export type Query_RootOdometer_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** query root */
export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootVehicleArgs = {
  distinct_on?: Maybe<Array<Vehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_By>>;
  where?: Maybe<Vehicle_Bool_Exp>;
};

/** query root */
export type Query_RootVehicle_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_By>>;
  where?: Maybe<Vehicle_Bool_Exp>;
};

/** query root */
export type Query_RootVehicle_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootVehicle_TypeArgs = {
  distinct_on?: Maybe<Array<Vehicle_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Type_Order_By>>;
  where?: Maybe<Vehicle_Type_Bool_Exp>;
};

/** query root */
export type Query_RootVehicle_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Type_Order_By>>;
  where?: Maybe<Vehicle_Type_Bool_Exp>;
};

/** query root */
export type Query_RootVehicle_Type_By_PkArgs = {
  slug_name: Scalars['String'];
};

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "driver" */
  driver: Array<Driver>;
  /** fetch aggregated fields from the table: "driver" */
  driver_aggregate: Driver_Aggregate;
  /** fetch data from the table: "driver" using primary key columns */
  driver_by_pk?: Maybe<Driver>;
  /** fetch data from the table: "equipment" */
  equipment: Array<Equipment>;
  /** fetch aggregated fields from the table: "equipment" */
  equipment_aggregate: Equipment_Aggregate;
  /** fetch data from the table: "equipment" using primary key columns */
  equipment_by_pk?: Maybe<Equipment>;
  /** execute function "get_user_by_credentials" which returns "user" */
  get_user_by_credentials: Array<User>;
  /** fetch data from the table: "hermes.device" */
  hermes_device: Array<Hermes_Device>;
  /** fetch data from the table: "hermes.device" using primary key columns */
  hermes_device_by_pk?: Maybe<Hermes_Device>;
  /** fetch data from the table: "hermes.event" */
  hermes_event: Array<Hermes_Event>;
  /** fetch aggregated fields from the table: "hermes.event" */
  hermes_event_aggregate: Hermes_Event_Aggregate;
  /** fetch data from the table: "hermes.event" using primary key columns */
  hermes_event_by_pk?: Maybe<Hermes_Event>;
  /** fetch data from the table: "hermes.event_traccar" */
  hermes_event_traccar: Array<Hermes_Event_Traccar>;
  /** fetch aggregated fields from the table: "hermes.event_traccar" */
  hermes_event_traccar_aggregate: Hermes_Event_Traccar_Aggregate;
  /** fetch data from the table: "hermes.event_traccar" using primary key columns */
  hermes_event_traccar_by_pk?: Maybe<Hermes_Event_Traccar>;
  /** fetch data from the table: "hermes.event_type" */
  hermes_event_type: Array<Hermes_Event_Type>;
  /** fetch aggregated fields from the table: "hermes.event_type" */
  hermes_event_type_aggregate: Hermes_Event_Type_Aggregate;
  /** fetch data from the table: "hermes.event_type" using primary key columns */
  hermes_event_type_by_pk?: Maybe<Hermes_Event_Type>;
  /** fetch data from the table: "hermes.position" */
  hermes_position: Array<Hermes_Position>;
  /** fetch aggregated fields from the table: "hermes.position" */
  hermes_position_aggregate: Hermes_Position_Aggregate;
  /** fetch data from the table: "hermes.position" using primary key columns */
  hermes_position_by_pk?: Maybe<Hermes_Position>;
  /** fetch data from the table: "hermes.position_gadget" */
  hermes_position_gadget: Array<Hermes_Position_Gadget>;
  /** fetch aggregated fields from the table: "hermes.position_gadget" */
  hermes_position_gadget_aggregate: Hermes_Position_Gadget_Aggregate;
  /** fetch data from the table: "hermes.position_gadget" using primary key columns */
  hermes_position_gadget_by_pk?: Maybe<Hermes_Position_Gadget>;
  /** execute function "hermes.search_current_position_by_vehicle" which returns "hermes.position" */
  hermes_search_current_position_by_vehicle: Array<Hermes_Position>;
  /** execute function "hermes.search_current_position_by_vehicle" and query aggregates on result of table type "hermes.position" */
  hermes_search_current_position_by_vehicle_aggregate: Hermes_Position_Aggregate;
  /** fetch data from the table: "hermes.workshift" */
  hermes_workshift: Array<Hermes_Workshift>;
  /** fetch aggregated fields from the table: "hermes.workshift" */
  hermes_workshift_aggregate: Hermes_Workshift_Aggregate;
  /** fetch data from the table: "hermes.workshift" using primary key columns */
  hermes_workshift_by_pk?: Maybe<Hermes_Workshift>;
  /** fetch data from the table: "hermes.workshift_event" */
  hermes_workshift_event: Array<Hermes_Workshift_Event>;
  /** fetch data from the table: "hermes.workshift_event" using primary key columns */
  hermes_workshift_event_by_pk?: Maybe<Hermes_Workshift_Event>;
  /** fetch data from the table: "horimeter_history" */
  horimeter_history: Array<Horimeter_History>;
  /** fetch aggregated fields from the table: "horimeter_history" */
  horimeter_history_aggregate: Horimeter_History_Aggregate;
  /** fetch data from the table: "horimeter_history" using primary key columns */
  horimeter_history_by_pk?: Maybe<Horimeter_History>;
  /** fetch data from the table: "integration" */
  integration: Array<Integration>;
  /** fetch data from the table: "integration" using primary key columns */
  integration_by_pk?: Maybe<Integration>;
  /** fetch data from the table: "odometer_history" */
  odometer_history: Array<Odometer_History>;
  /** fetch aggregated fields from the table: "odometer_history" */
  odometer_history_aggregate: Odometer_History_Aggregate;
  /** fetch data from the table: "odometer_history" using primary key columns */
  odometer_history_by_pk?: Maybe<Odometer_History>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "vehicle" */
  vehicle: Array<Vehicle>;
  /** fetch aggregated fields from the table: "vehicle" */
  vehicle_aggregate: Vehicle_Aggregate;
  /** fetch data from the table: "vehicle" using primary key columns */
  vehicle_by_pk?: Maybe<Vehicle>;
  /** fetch data from the table: "vehicle_type" */
  vehicle_type: Array<Vehicle_Type>;
  /** fetch aggregated fields from the table: "vehicle_type" */
  vehicle_type_aggregate: Vehicle_Type_Aggregate;
  /** fetch data from the table: "vehicle_type" using primary key columns */
  vehicle_type_by_pk?: Maybe<Vehicle_Type>;
};

/** subscription root */
export type Subscription_RootDriverArgs = {
  distinct_on?: Maybe<Array<Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Driver_Order_By>>;
  where?: Maybe<Driver_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootDriver_AggregateArgs = {
  distinct_on?: Maybe<Array<Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Driver_Order_By>>;
  where?: Maybe<Driver_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootDriver_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootEquipmentArgs = {
  distinct_on?: Maybe<Array<Equipment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Equipment_Order_By>>;
  where?: Maybe<Equipment_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootEquipment_AggregateArgs = {
  distinct_on?: Maybe<Array<Equipment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Equipment_Order_By>>;
  where?: Maybe<Equipment_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootEquipment_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootGet_User_By_CredentialsArgs = {
  args: Get_User_By_Credentials_Args;
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_DeviceArgs = {
  distinct_on?: Maybe<Array<Hermes_Device_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Device_Order_By>>;
  where?: Maybe<Hermes_Device_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Device_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHermes_EventArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Order_By>>;
  where?: Maybe<Hermes_Event_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Order_By>>;
  where?: Maybe<Hermes_Event_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHermes_Event_TraccarArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Traccar_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Traccar_Order_By>>;
  where?: Maybe<Hermes_Event_Traccar_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Event_Traccar_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Traccar_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Traccar_Order_By>>;
  where?: Maybe<Hermes_Event_Traccar_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Event_Traccar_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHermes_Event_TypeArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Type_Order_By>>;
  where?: Maybe<Hermes_Event_Type_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Event_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Type_Order_By>>;
  where?: Maybe<Hermes_Event_Type_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Event_Type_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHermes_PositionArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Order_By>>;
  where?: Maybe<Hermes_Position_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Position_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Order_By>>;
  where?: Maybe<Hermes_Position_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Position_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHermes_Position_GadgetArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Gadget_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Gadget_Order_By>>;
  where?: Maybe<Hermes_Position_Gadget_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Position_Gadget_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Gadget_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Gadget_Order_By>>;
  where?: Maybe<Hermes_Position_Gadget_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Position_Gadget_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHermes_Search_Current_Position_By_VehicleArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Order_By>>;
  where?: Maybe<Hermes_Position_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Search_Current_Position_By_Vehicle_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Order_By>>;
  where?: Maybe<Hermes_Position_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_WorkshiftArgs = {
  distinct_on?: Maybe<Array<Hermes_Workshift_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Workshift_Order_By>>;
  where?: Maybe<Hermes_Workshift_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Workshift_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Workshift_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Workshift_Order_By>>;
  where?: Maybe<Hermes_Workshift_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Workshift_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHermes_Workshift_EventArgs = {
  distinct_on?: Maybe<Array<Hermes_Workshift_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Workshift_Event_Order_By>>;
  where?: Maybe<Hermes_Workshift_Event_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Workshift_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHorimeter_HistoryArgs = {
  distinct_on?: Maybe<Array<Horimeter_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Horimeter_History_Order_By>>;
  where?: Maybe<Horimeter_History_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHorimeter_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Horimeter_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Horimeter_History_Order_By>>;
  where?: Maybe<Horimeter_History_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHorimeter_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootIntegrationArgs = {
  distinct_on?: Maybe<Array<Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Order_By>>;
  where?: Maybe<Integration_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootIntegration_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootOdometer_HistoryArgs = {
  distinct_on?: Maybe<Array<Odometer_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Odometer_History_Order_By>>;
  where?: Maybe<Odometer_History_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootOdometer_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Odometer_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Odometer_History_Order_By>>;
  where?: Maybe<Odometer_History_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootOdometer_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootVehicleArgs = {
  distinct_on?: Maybe<Array<Vehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_By>>;
  where?: Maybe<Vehicle_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootVehicle_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_By>>;
  where?: Maybe<Vehicle_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootVehicle_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootVehicle_TypeArgs = {
  distinct_on?: Maybe<Array<Vehicle_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Type_Order_By>>;
  where?: Maybe<Vehicle_Type_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootVehicle_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Type_Order_By>>;
  where?: Maybe<Vehicle_Type_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootVehicle_Type_By_PkArgs = {
  slug_name: Scalars['String'];
};

/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  cpf?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  organization_id: Scalars['uuid'];
  settings: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  workshifts: Array<Hermes_Workshift>;
  /** An aggregated array relationship */
  workshifts_aggregate: Hermes_Workshift_Aggregate;
};

/** columns and relationships of "user" */
export type UserSettingsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "user" */
export type UserWorkshiftsArgs = {
  distinct_on?: Maybe<Array<Hermes_Workshift_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Workshift_Order_By>>;
  where?: Maybe<Hermes_Workshift_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserWorkshifts_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Workshift_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Workshift_Order_By>>;
  where?: Maybe<Hermes_Workshift_Bool_Exp>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Append_Input = {
  settings?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  cpf?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  email_verified_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  settings?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  workshifts?: Maybe<Hermes_Workshift_Bool_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserCpfKey = 'user_cpf_key',
  /** unique or primary key constraint */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint */
  UserEmailTokenKey = 'user_email_token_key',
  /** unique or primary key constraint */
  UserPkey = 'user_pkey',
  /** unique or primary key constraint */
  UserRecoveryPasswordHashKey = 'user_recovery_password_hash_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Delete_At_Path_Input = {
  settings?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Delete_Elem_Input = {
  settings?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Delete_Key_Input = {
  settings?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User>;
};

/** on conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

/** ordering options when selecting data from "user" */
export type User_Order_By = {
  cpf?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_verified_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  settings?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  workshifts_aggregate?: Maybe<Hermes_Workshift_Aggregate_Order_By>;
};

/** primary key columns input for table: "user" */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Prepend_Input = {
  settings?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  Cpf = 'cpf',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerifiedAt = 'email_verified_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  settings?: Maybe<Scalars['jsonb']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  Settings = 'settings',
}

/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "vehicle" */
export type Vehicle = {
  __typename?: 'vehicle';
  brand?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  devices: Array<Hermes_Device>;
  /** An array relationship */
  events: Array<Hermes_Event>;
  /** An aggregated array relationship */
  events_aggregate: Hermes_Event_Aggregate;
  id: Scalars['uuid'];
  maximum_volume?: Maybe<Scalars['Int']>;
  maximum_weight?: Maybe<Scalars['Int']>;
  min_speed: Scalars['float8'];
  model?: Maybe<Scalars['String']>;
  /** An array relationship */
  odometer_histories: Array<Odometer_History>;
  /** An aggregated array relationship */
  odometer_histories_aggregate: Odometer_History_Aggregate;
  organization_id: Scalars['uuid'];
  plate: Scalars['String'];
  /** An array relationship */
  position_gadgets: Array<Hermes_Position_Gadget>;
  /** An aggregated array relationship */
  position_gadgets_aggregate: Hermes_Position_Gadget_Aggregate;
  /** An array relationship */
  positions: Array<Hermes_Position>;
  /** An aggregated array relationship */
  positions_aggregate: Hermes_Position_Aggregate;
  type: Vehicle_Type_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle_type: Vehicle_Type;
  /** An array relationship */
  workshifts: Array<Hermes_Workshift>;
  /** An aggregated array relationship */
  workshifts_aggregate: Hermes_Workshift_Aggregate;
  year?: Maybe<Scalars['String']>;
};

/** columns and relationships of "vehicle" */
export type VehicleDevicesArgs = {
  distinct_on?: Maybe<Array<Hermes_Device_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Device_Order_By>>;
  where?: Maybe<Hermes_Device_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehicleEventsArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Order_By>>;
  where?: Maybe<Hermes_Event_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehicleEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Event_Order_By>>;
  where?: Maybe<Hermes_Event_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehicleOdometer_HistoriesArgs = {
  distinct_on?: Maybe<Array<Odometer_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Odometer_History_Order_By>>;
  where?: Maybe<Odometer_History_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehicleOdometer_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Odometer_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Odometer_History_Order_By>>;
  where?: Maybe<Odometer_History_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehiclePosition_GadgetsArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Gadget_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Gadget_Order_By>>;
  where?: Maybe<Hermes_Position_Gadget_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehiclePosition_Gadgets_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Gadget_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Gadget_Order_By>>;
  where?: Maybe<Hermes_Position_Gadget_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehiclePositionsArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Order_By>>;
  where?: Maybe<Hermes_Position_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehiclePositions_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Position_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Position_Order_By>>;
  where?: Maybe<Hermes_Position_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehicleWorkshiftsArgs = {
  distinct_on?: Maybe<Array<Hermes_Workshift_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Workshift_Order_By>>;
  where?: Maybe<Hermes_Workshift_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehicleWorkshifts_AggregateArgs = {
  distinct_on?: Maybe<Array<Hermes_Workshift_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hermes_Workshift_Order_By>>;
  where?: Maybe<Hermes_Workshift_Bool_Exp>;
};

/** aggregated selection of "vehicle" */
export type Vehicle_Aggregate = {
  __typename?: 'vehicle_aggregate';
  aggregate?: Maybe<Vehicle_Aggregate_Fields>;
  nodes: Array<Vehicle>;
};

/** aggregate fields of "vehicle" */
export type Vehicle_Aggregate_Fields = {
  __typename?: 'vehicle_aggregate_fields';
  avg?: Maybe<Vehicle_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Vehicle_Max_Fields>;
  min?: Maybe<Vehicle_Min_Fields>;
  stddev?: Maybe<Vehicle_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicle_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicle_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicle_Sum_Fields>;
  var_pop?: Maybe<Vehicle_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicle_Var_Samp_Fields>;
  variance?: Maybe<Vehicle_Variance_Fields>;
};

/** aggregate fields of "vehicle" */
export type Vehicle_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vehicle_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vehicle" */
export type Vehicle_Aggregate_Order_By = {
  avg?: Maybe<Vehicle_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Vehicle_Max_Order_By>;
  min?: Maybe<Vehicle_Min_Order_By>;
  stddev?: Maybe<Vehicle_Stddev_Order_By>;
  stddev_pop?: Maybe<Vehicle_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Vehicle_Stddev_Samp_Order_By>;
  sum?: Maybe<Vehicle_Sum_Order_By>;
  var_pop?: Maybe<Vehicle_Var_Pop_Order_By>;
  var_samp?: Maybe<Vehicle_Var_Samp_Order_By>;
  variance?: Maybe<Vehicle_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "vehicle" */
export type Vehicle_Arr_Rel_Insert_Input = {
  data: Array<Vehicle_Insert_Input>;
  on_conflict?: Maybe<Vehicle_On_Conflict>;
};

/** aggregate avg on columns */
export type Vehicle_Avg_Fields = {
  __typename?: 'vehicle_avg_fields';
  maximum_volume?: Maybe<Scalars['Float']>;
  maximum_weight?: Maybe<Scalars['Float']>;
  min_speed?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vehicle" */
export type Vehicle_Avg_Order_By = {
  maximum_volume?: Maybe<Order_By>;
  maximum_weight?: Maybe<Order_By>;
  min_speed?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vehicle". All fields are combined with a logical 'AND'. */
export type Vehicle_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Vehicle_Bool_Exp>>>;
  _not?: Maybe<Vehicle_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Vehicle_Bool_Exp>>>;
  brand?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  devices?: Maybe<Hermes_Device_Bool_Exp>;
  events?: Maybe<Hermes_Event_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  maximum_volume?: Maybe<Int_Comparison_Exp>;
  maximum_weight?: Maybe<Int_Comparison_Exp>;
  min_speed?: Maybe<Float8_Comparison_Exp>;
  model?: Maybe<String_Comparison_Exp>;
  odometer_histories?: Maybe<Odometer_History_Bool_Exp>;
  organization_id?: Maybe<Uuid_Comparison_Exp>;
  plate?: Maybe<String_Comparison_Exp>;
  position_gadgets?: Maybe<Hermes_Position_Gadget_Bool_Exp>;
  positions?: Maybe<Hermes_Position_Bool_Exp>;
  type?: Maybe<Vehicle_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vehicle_type?: Maybe<Vehicle_Type_Bool_Exp>;
  workshifts?: Maybe<Hermes_Workshift_Bool_Exp>;
  year?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "vehicle" */
export enum Vehicle_Constraint {
  /** unique or primary key constraint */
  VehiclePkey = 'vehicle_pkey',
  /** unique or primary key constraint */
  VehiclePlateOrganizationIdKey = 'vehicle_plate_organization_id_key',
}

/** input type for incrementing integer column in table "vehicle" */
export type Vehicle_Inc_Input = {
  maximum_volume?: Maybe<Scalars['Int']>;
  maximum_weight?: Maybe<Scalars['Int']>;
  min_speed?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "vehicle" */
export type Vehicle_Insert_Input = {
  brand?: Maybe<Scalars['String']>;
  maximum_volume?: Maybe<Scalars['Int']>;
  maximum_weight?: Maybe<Scalars['Int']>;
  min_speed?: Maybe<Scalars['float8']>;
  model?: Maybe<Scalars['String']>;
  odometer_histories?: Maybe<Odometer_History_Arr_Rel_Insert_Input>;
  plate?: Maybe<Scalars['String']>;
  position_gadgets?: Maybe<Hermes_Position_Gadget_Arr_Rel_Insert_Input>;
  type?: Maybe<Vehicle_Type_Enum>;
  workshifts?: Maybe<Hermes_Workshift_Arr_Rel_Insert_Input>;
  year?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Vehicle_Max_Fields = {
  __typename?: 'vehicle_max_fields';
  brand?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maximum_volume?: Maybe<Scalars['Int']>;
  maximum_weight?: Maybe<Scalars['Int']>;
  min_speed?: Maybe<Scalars['float8']>;
  model?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  plate?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "vehicle" */
export type Vehicle_Max_Order_By = {
  brand?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  maximum_volume?: Maybe<Order_By>;
  maximum_weight?: Maybe<Order_By>;
  min_speed?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  plate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicle_Min_Fields = {
  __typename?: 'vehicle_min_fields';
  brand?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maximum_volume?: Maybe<Scalars['Int']>;
  maximum_weight?: Maybe<Scalars['Int']>;
  min_speed?: Maybe<Scalars['float8']>;
  model?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  plate?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "vehicle" */
export type Vehicle_Min_Order_By = {
  brand?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  maximum_volume?: Maybe<Order_By>;
  maximum_weight?: Maybe<Order_By>;
  min_speed?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  plate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** response of any mutation on the table "vehicle" */
export type Vehicle_Mutation_Response = {
  __typename?: 'vehicle_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Vehicle>;
};

/** input type for inserting object relation for remote table "vehicle" */
export type Vehicle_Obj_Rel_Insert_Input = {
  data: Vehicle_Insert_Input;
  on_conflict?: Maybe<Vehicle_On_Conflict>;
};

/** on conflict condition type for table "vehicle" */
export type Vehicle_On_Conflict = {
  constraint: Vehicle_Constraint;
  update_columns: Array<Vehicle_Update_Column>;
  where?: Maybe<Vehicle_Bool_Exp>;
};

/** ordering options when selecting data from "vehicle" */
export type Vehicle_Order_By = {
  brand?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  events_aggregate?: Maybe<Hermes_Event_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  maximum_volume?: Maybe<Order_By>;
  maximum_weight?: Maybe<Order_By>;
  min_speed?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  odometer_histories_aggregate?: Maybe<Odometer_History_Aggregate_Order_By>;
  organization_id?: Maybe<Order_By>;
  plate?: Maybe<Order_By>;
  position_gadgets_aggregate?: Maybe<Hermes_Position_Gadget_Aggregate_Order_By>;
  positions_aggregate?: Maybe<Hermes_Position_Aggregate_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_type?: Maybe<Vehicle_Type_Order_By>;
  workshifts_aggregate?: Maybe<Hermes_Workshift_Aggregate_Order_By>;
  year?: Maybe<Order_By>;
};

/** primary key columns input for table: "vehicle" */
export type Vehicle_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "vehicle" */
export enum Vehicle_Select_Column {
  /** column name */
  Brand = 'brand',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MaximumVolume = 'maximum_volume',
  /** column name */
  MaximumWeight = 'maximum_weight',
  /** column name */
  MinSpeed = 'min_speed',
  /** column name */
  Model = 'model',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Plate = 'plate',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year',
}

/** input type for updating data in table "vehicle" */
export type Vehicle_Set_Input = {
  brand?: Maybe<Scalars['String']>;
  maximum_volume?: Maybe<Scalars['Int']>;
  maximum_weight?: Maybe<Scalars['Int']>;
  min_speed?: Maybe<Scalars['float8']>;
  model?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  type?: Maybe<Vehicle_Type_Enum>;
  year?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Vehicle_Stddev_Fields = {
  __typename?: 'vehicle_stddev_fields';
  maximum_volume?: Maybe<Scalars['Float']>;
  maximum_weight?: Maybe<Scalars['Float']>;
  min_speed?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vehicle" */
export type Vehicle_Stddev_Order_By = {
  maximum_volume?: Maybe<Order_By>;
  maximum_weight?: Maybe<Order_By>;
  min_speed?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vehicle_Stddev_Pop_Fields = {
  __typename?: 'vehicle_stddev_pop_fields';
  maximum_volume?: Maybe<Scalars['Float']>;
  maximum_weight?: Maybe<Scalars['Float']>;
  min_speed?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vehicle" */
export type Vehicle_Stddev_Pop_Order_By = {
  maximum_volume?: Maybe<Order_By>;
  maximum_weight?: Maybe<Order_By>;
  min_speed?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vehicle_Stddev_Samp_Fields = {
  __typename?: 'vehicle_stddev_samp_fields';
  maximum_volume?: Maybe<Scalars['Float']>;
  maximum_weight?: Maybe<Scalars['Float']>;
  min_speed?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vehicle" */
export type Vehicle_Stddev_Samp_Order_By = {
  maximum_volume?: Maybe<Order_By>;
  maximum_weight?: Maybe<Order_By>;
  min_speed?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Vehicle_Sum_Fields = {
  __typename?: 'vehicle_sum_fields';
  maximum_volume?: Maybe<Scalars['Int']>;
  maximum_weight?: Maybe<Scalars['Int']>;
  min_speed?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "vehicle" */
export type Vehicle_Sum_Order_By = {
  maximum_volume?: Maybe<Order_By>;
  maximum_weight?: Maybe<Order_By>;
  min_speed?: Maybe<Order_By>;
};

/** columns and relationships of "vehicle_type" */
export type Vehicle_Type = {
  __typename?: 'vehicle_type';
  name: Scalars['String'];
  slug_name: Scalars['String'];
  /** An array relationship */
  vehicles: Array<Vehicle>;
  /** An aggregated array relationship */
  vehicles_aggregate: Vehicle_Aggregate;
};

/** columns and relationships of "vehicle_type" */
export type Vehicle_TypeVehiclesArgs = {
  distinct_on?: Maybe<Array<Vehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_By>>;
  where?: Maybe<Vehicle_Bool_Exp>;
};

/** columns and relationships of "vehicle_type" */
export type Vehicle_TypeVehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicle_Order_By>>;
  where?: Maybe<Vehicle_Bool_Exp>;
};

/** aggregated selection of "vehicle_type" */
export type Vehicle_Type_Aggregate = {
  __typename?: 'vehicle_type_aggregate';
  aggregate?: Maybe<Vehicle_Type_Aggregate_Fields>;
  nodes: Array<Vehicle_Type>;
};

/** aggregate fields of "vehicle_type" */
export type Vehicle_Type_Aggregate_Fields = {
  __typename?: 'vehicle_type_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Vehicle_Type_Max_Fields>;
  min?: Maybe<Vehicle_Type_Min_Fields>;
};

/** aggregate fields of "vehicle_type" */
export type Vehicle_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vehicle_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vehicle_type" */
export type Vehicle_Type_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Vehicle_Type_Max_Order_By>;
  min?: Maybe<Vehicle_Type_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "vehicle_type". All fields are combined with a logical 'AND'. */
export type Vehicle_Type_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Vehicle_Type_Bool_Exp>>>;
  _not?: Maybe<Vehicle_Type_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Vehicle_Type_Bool_Exp>>>;
  name?: Maybe<String_Comparison_Exp>;
  slug_name?: Maybe<String_Comparison_Exp>;
  vehicles?: Maybe<Vehicle_Bool_Exp>;
};

export enum Vehicle_Type_Enum {
  /** Carro */
  Car = 'car',
  /** Equipamento */
  Equipment = 'equipment',
  /** Carreta */
  Trailer = 'trailer',
  /** Caminhão */
  Truck = 'truck',
}

/** expression to compare columns of type vehicle_type_enum. All fields are combined with logical 'AND'. */
export type Vehicle_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Vehicle_Type_Enum>;
  _in?: Maybe<Array<Vehicle_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Vehicle_Type_Enum>;
  _nin?: Maybe<Array<Vehicle_Type_Enum>>;
};

/** aggregate max on columns */
export type Vehicle_Type_Max_Fields = {
  __typename?: 'vehicle_type_max_fields';
  name?: Maybe<Scalars['String']>;
  slug_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "vehicle_type" */
export type Vehicle_Type_Max_Order_By = {
  name?: Maybe<Order_By>;
  slug_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicle_Type_Min_Fields = {
  __typename?: 'vehicle_type_min_fields';
  name?: Maybe<Scalars['String']>;
  slug_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "vehicle_type" */
export type Vehicle_Type_Min_Order_By = {
  name?: Maybe<Order_By>;
  slug_name?: Maybe<Order_By>;
};

/** ordering options when selecting data from "vehicle_type" */
export type Vehicle_Type_Order_By = {
  name?: Maybe<Order_By>;
  slug_name?: Maybe<Order_By>;
  vehicles_aggregate?: Maybe<Vehicle_Aggregate_Order_By>;
};

/** primary key columns input for table: "vehicle_type" */
export type Vehicle_Type_Pk_Columns_Input = {
  slug_name: Scalars['String'];
};

/** select columns of table "vehicle_type" */
export enum Vehicle_Type_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  SlugName = 'slug_name',
}

/** update columns of table "vehicle" */
export enum Vehicle_Update_Column {
  /** column name */
  Brand = 'brand',
  /** column name */
  MaximumVolume = 'maximum_volume',
  /** column name */
  MaximumWeight = 'maximum_weight',
  /** column name */
  MinSpeed = 'min_speed',
  /** column name */
  Model = 'model',
  /** column name */
  Plate = 'plate',
  /** column name */
  Type = 'type',
  /** column name */
  Year = 'year',
}

/** aggregate var_pop on columns */
export type Vehicle_Var_Pop_Fields = {
  __typename?: 'vehicle_var_pop_fields';
  maximum_volume?: Maybe<Scalars['Float']>;
  maximum_weight?: Maybe<Scalars['Float']>;
  min_speed?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vehicle" */
export type Vehicle_Var_Pop_Order_By = {
  maximum_volume?: Maybe<Order_By>;
  maximum_weight?: Maybe<Order_By>;
  min_speed?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vehicle_Var_Samp_Fields = {
  __typename?: 'vehicle_var_samp_fields';
  maximum_volume?: Maybe<Scalars['Float']>;
  maximum_weight?: Maybe<Scalars['Float']>;
  min_speed?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vehicle" */
export type Vehicle_Var_Samp_Order_By = {
  maximum_volume?: Maybe<Order_By>;
  maximum_weight?: Maybe<Order_By>;
  min_speed?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Vehicle_Variance_Fields = {
  __typename?: 'vehicle_variance_fields';
  maximum_volume?: Maybe<Scalars['Float']>;
  maximum_weight?: Maybe<Scalars['Float']>;
  min_speed?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vehicle" */
export type Vehicle_Variance_Order_By = {
  maximum_volume?: Maybe<Order_By>;
  maximum_weight?: Maybe<Order_By>;
  min_speed?: Maybe<Order_By>;
};

export type GetVehicleByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetVehicleByIdQuery = { __typename?: 'query_root' } & {
  vehicle_by_pk?: Maybe<{ __typename?: 'vehicle' } & VehicleFragment>;
};

export type ListAllVehiclesQueryVariables = Exact<{ [key: string]: never }>;

export type ListAllVehiclesQuery = { __typename?: 'query_root' } & {
  vehicle: Array<{ __typename?: 'vehicle' } & VehicleFragment>;
};

export type VehicleFragment = { __typename?: 'vehicle' } & Pick<
  Vehicle,
  | 'id'
  | 'model'
  | 'maximum_weight'
  | 'maximum_volume'
  | 'brand'
  | 'created_at'
  | 'updated_at'
  | 'organization_id'
  | 'plate'
  | 'type'
  | 'year'
>;

export const VehicleFragmentDoc = gql`
  fragment Vehicle on vehicle {
    id
    model
    maximum_weight
    maximum_volume
    brand
    created_at
    updated_at
    organization_id
    plate
    type
    year
  }
`;
export const GetVehicleByIdDocument = gql`
  query getVehicleById($id: uuid!) {
    vehicle_by_pk(id: $id) {
      ...Vehicle
    }
  }
  ${VehicleFragmentDoc}
`;

export function useGetVehicleByIdQuery(
  options: Omit<Urql.UseQueryArgs<GetVehicleByIdQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetVehicleByIdQuery>({
    query: GetVehicleByIdDocument,
    ...options,
  });
}
export const ListAllVehiclesDocument = gql`
  query listAllVehicles {
    vehicle {
      ...Vehicle
    }
  }
  ${VehicleFragmentDoc}
`;

export function useListAllVehiclesQuery(
  options: Omit<Urql.UseQueryArgs<ListAllVehiclesQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<ListAllVehiclesQuery>({
    query: ListAllVehiclesDocument,
    ...options,
  });
}
