import React from 'react';
import { AuthContext } from '../../contexts/AuthProvider';
import Providers from './Providers';

export interface Props {}

export default function ProvidersWrapper(props: Props) {
  const { firebaseOrganizationId } = React.useContext(AuthContext);

  if (!firebaseOrganizationId) return <span>Carregando...</span>;

  return (
    <div>
      <Providers organization={firebaseOrganizationId} />
    </div>
  );
}
