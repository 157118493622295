import React from 'react';
import ReactExport from 'react-data-export'; // This lib does not have types
import { generateExcelVehicleFuellings } from './helpers/generate-excel-vehicle-fuellings';
import { Export, Icon } from './styles';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ExcelExportVehicles(props) {
  const fuellings = generateExcelVehicleFuellings(props.fuellings);

  return (
    <ExcelFile
      fileExtension="xlsx"
      filename="Infleet - Abastecimentos de veículos"
      element={
        <Export>
          <Icon />
          Exportar dados
        </Export>
      }
    >
      <ExcelSheet data={fuellings} name="Abastecimentos de veículos">
        <ExcelColumn label="Data" value="date" />
        <ExcelColumn label="Veículo" value="plate" />
        <ExcelColumn label="Motorista" value="driver" />
        <ExcelColumn label="Quantidade (L)" value="fuelAmount" />
        <ExcelColumn label="Odômetro (km)" value="odometer" />
        <ExcelColumn label="Fornecedor" value="provider" />
        <ExcelColumn label="Valor do litro (R$/L)" value="literValue" />
        <ExcelColumn label="Distância percorrida" value="distance" />
        <ExcelColumn label="Autonomia média (km/L)" value="autonomy" />
      </ExcelSheet>
    </ExcelFile>
  );
}
