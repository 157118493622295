import { Col, Divider, message, Popconfirm, Row, Table } from 'antd';
import { EditFilled, DeleteFilled, PlusCircleFilled } from '@ant-design/icons';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { useTransfers } from '../../helpers/hooks';
import { AuthContext } from '../../contexts/AuthProvider';
import {
  ContainerSubtitle,
  Subtitle,
} from '../../components/PageSubtitle/styles';
import AddItemButton from '../../components/AddItemButton/AddItemButton';

import styled from 'styled-components';

export const AddItem = styled(PlusCircleFilled)``;

const { Column } = Table;

const Transfers = () => {
  const { firebaseOrganizationId } = useContext(AuthContext);

  const { transfers, loading } = useTransfers();

  // FIXME: id should never be undefined
  const handleDelete = async (id: string | undefined) => {
    if (!id) return;

    await db
      .collection('organizations')
      .doc(firebaseOrganizationId)
      .collection('transfers')
      .doc(id)
      .delete()
      .then(() => {
        message.success('Transferência removida com sucesso.');
      });
  };

  return (
    <div style={{ marginTop: '1rem' }}>
      <ContainerSubtitle>
        <Subtitle>Transferências</Subtitle>
        <div>
          <Link to="tanks/transfers/new">
            <AddItemButton />
          </Link>
        </div>
      </ContainerSubtitle>
      <Table
        style={{ width: '60%' }}
        rowKey={(record) => record.id || ''}
        pagination={false}
        loading={loading}
        dataSource={transfers}
        locale={{ emptyText: 'Nenhuma transferência cadastrada' }}
      >
        <Column
          title="Origem"
          render={(_, record: any) => {
            return record.origin.name;
          }}
        />
        <Column
          title="Destino"
          render={(_, record: any) => {
            return record.destination.name;
          }}
        />
        <Column title="Quantidade" dataIndex="amount" key="amount" />
        <Column
          title="Ações"
          dataIndex="id"
          key="actions"
          render={(id) => (
            <>
              <Link to={`/tanks/transfers/${id}`}>
                <EditFilled />
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title="Confirmar remoção?"
                onConfirm={(_) => handleDelete(id)}
                okText="Sim"
                cancelText="Não"
              >
                <DeleteFilled />
              </Popconfirm>
            </>
          )}
        />
      </Table>
    </div>
  );
};

export default Transfers;
