import { groupBy } from 'lodash';
import moment from 'moment';
import { Fuelling } from '../../../helpers/types';

export const generateExcelVehicleFuellings = (fuellings: any) => {
  const fuellingsByVehicle = groupBy(fuellings, 'car.plate');

  const result = fuellings.map((fuelling: Fuelling) => {
    const vehicleFuellings = fuellingsByVehicle[fuelling?.car?.plate!];

    const vehicleFuellingsIndex = vehicleFuellings.findIndex(
      (obj) => obj.id === fuelling.id
    );

    const previousFuelling = vehicleFuellings[vehicleFuellingsIndex + 1];

    const distance = previousFuelling
      ? (fuelling.odometer! - previousFuelling.odometer).toFixed(2)
      : '-';

    const autonomy =
      fuelling.filledUp && previousFuelling?.filledUp
        ? (
            (fuelling.odometer! - previousFuelling.odometer) /
            fuelling.fuelAmount
          ).toFixed(2)
        : '-';

    return {
      date: moment(fuelling.date).format('DD/MM/YY'),
      plate: fuelling.car.plate,
      driver: fuelling?.driver?.name ?? 'Abastecimento web',
      fuelAmount: fuelling.fuelAmount,
      odometer: fuelling.odometer,
      provider: fuelling.provider
        ? fuelling.provider.trade
        : fuelling?.tank?.name,
      literValue: fuelling.value
        ? (fuelling?.value / fuelling.fuelAmount).toFixed(2)
        : 'Abastecimento interno',
      distance,
      autonomy,
    };
  });

  return result;
};
