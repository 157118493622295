import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { app } from '../../firebaseConfig';
import authClient from '../../auth-client';
import { useHistory } from 'react-router-dom';

import {
  Container,
  InfoContainer,
  Logout,
  LogoutButton,
  LogoutIcon,
  UserName,
  UserRole,
} from './styles';

export default function ConfigPopover() {
  const history = useHistory();

  return (
    <Container>
      <Grid>
        <Row start="xs" middle="xs">
          <Col>
            <Avatar size={64} icon={<UserOutlined />} />
          </Col>
          <Col>
            <InfoContainer>
              <UserName>ABASTECIMENTO</UserName>
              <UserRole>Usuário</UserRole>
            </InfoContainer>
          </Col>
        </Row>
        <Logout>
          <LogoutButton
            onClick={async () => {
              await Promise.all([app.auth().signOut(), authClient.signOut()]);
              history.push('/');
            }}
          >
            <LogoutIcon />
            Sair
          </LogoutButton>
        </Logout>
      </Grid>
    </Container>
  );
}
