import { uniqBy } from 'lodash';
import React, { useMemo } from 'react';
import { useSpecificContext } from '../../contexts/SpecificContext';
import {
  Container,
  Indicator,
  IndicatorContainer,
  Title,
  TitleContainer,
  Value,
} from '../AutonomyChart/styles';
import VehicleChartCanvas from './VehicleChartCanvas';

interface Props {}

interface ChartData {
  [key: string]: any;
}

export default function AutonomyChart(props: Props) {
  const { specificFuellings } = useSpecificContext();

  const data: ChartData[] = useMemo(() => {
    const mappedVehicles = uniqBy(
      specificFuellings
        .map(({ car: { id, plate } }) => {
          let distance = 0;
          const vehicleFuellings = specificFuellings.filter(
            ({ car }) => car.id === id
          );

          if (!vehicleFuellings.length) return { vehicle: plate, Distância: 0 };

          vehicleFuellings.forEach((item, index, arr) => {
            const latestFuelling = arr[index + 1];
            if (latestFuelling)
              distance = distance + (latestFuelling.odometer - item.odometer);
          });

          return {
            vehicle: plate,
            Distância: distance,
          };
        })
        .filter((item) => item?.Distância),
      'vehicle'
    );

    return mappedVehicles;
  }, [specificFuellings]);

  const distance = useMemo(() => {
    const distanceSum = data.reduce(
      (sum: number, b: ChartData) => sum + b.Distância,
      0
    );

    return distanceSum / data.length || 0;
  }, [data]);

  return (
    <Container>
      <TitleContainer>
        <Title>Gráfico de Distância x Veículos</Title>
      </TitleContainer>
      <IndicatorContainer>
        <Indicator>
          Distância percorrida média:
          <Value>{distance.toFixed(2)} km</Value>
        </Indicator>
      </IndicatorContainer>
      <VehicleChartCanvas data={data} />
    </Container>
  );
}
