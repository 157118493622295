import { uniqBy } from 'lodash';
import React, { useMemo } from 'react';
import { EquipmentFuelling } from '../../../../helpers/types';
import { useSpecificContext } from '../../contexts/SpecificContext';
import {
  Container,
  Indicator,
  IndicatorContainer,
  Title,
  TitleContainer,
  Value,
} from '../AutonomyChart/styles';
import EquipmentChartCanvas from './EquipmentChartCanvas';

interface Props {}

interface ChartData {
  [key: string]: any;
}

export default function EquipmentChart(props: Props) {
  const { specificEquipmentFuellings } = useSpecificContext();

  const data: ChartData[] = useMemo(() => {
    const mappedEquipments = uniqBy(
      specificEquipmentFuellings
        .map(({ car: { id, plate } }) => {
          let horimeter = 0;
          const filteredEquipmentFuellings: EquipmentFuelling[] = specificEquipmentFuellings.filter(
            ({ car }) => car.id === id
          );

          if (!filteredEquipmentFuellings.length)
            return { equipment: plate, Horas: 0 };

          filteredEquipmentFuellings.forEach((item, index, arr) => {
            const latestFuelling = arr[index + 1];
            if (latestFuelling)
              horimeter =
                horimeter + (latestFuelling.horimeter - item.horimeter);
          });

          return {
            equipment: plate,
            Horas: horimeter,
          };
        })
        .filter((item) => item?.Horas),
      'equipment'
    );

    return mappedEquipments;
  }, [specificEquipmentFuellings]);

  const horimeterMean = useMemo(() => {
    const horimeterSum = data.reduce(
      (sum: number, b: ChartData) => sum + b.Horas,
      0
    );

    return horimeterSum / data.length || 0;
  }, [data]);

  return (
    <Container>
      <TitleContainer>
        <Title>Gráfico de Horas x Equipamentos</Title>
      </TitleContainer>
      <IndicatorContainer>
        <Indicator>
          Média de horas gastas:
          <Value>{horimeterMean.toFixed(2)} h</Value>
        </Indicator>
      </IndicatorContainer>
      <EquipmentChartCanvas data={data} />
    </Container>
  );
}
