import styled from 'styled-components';

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  width: 100%;
  border-bottom: 1px solid #c6c6c6;
  margin-bottom: 1.5rem;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 0;
`;
