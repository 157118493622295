import React, { ReactElement } from 'react';
import { useListAllVehiclesQuery } from '../../../../generated/graphql';
import { CardContainer, CardNumber, CardTitle } from './styles';

interface Props {}

export default function CardVehicles({}: Props): ReactElement {
  const [result] = useListAllVehiclesQuery();

  const { data } = result;

  const vehicles = data?.vehicle ?? [];

  return (
    <CardContainer>
      <CardTitle>Número de veículos</CardTitle>
      <CardNumber>{vehicles.length}</CardNumber>
    </CardContainer>
  );
}
