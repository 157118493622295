import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dee2e6;
`;

export const Title = styled.h2`
  margin: 0.5rem 0 0 0;
`;

export const Container = styled.div`
  margin: 1rem 0;
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 1rem;
`;

export const GeneralTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const FilterContainer = styled.div`
  padding: 1rem 0 0 0;
  margin-bottom: 1rem;
`;

export const FilterTitle = styled.p`
  color: gray;
  margin-bottom: 1rem;
`;
