import React from 'react';
import ReactExport from 'react-data-export'; // This lib does not have types
import { generateExcelEquipmentFuellings } from './helpers/generate-excel-equipment-fuellings';
import { Export, Icon } from './styles';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ExcelExportEquipments(props) {
  const fuellings = generateExcelEquipmentFuellings(props.fuellings);

  return (
    <ExcelFile
      fileExtension="xlsx"
      filename="Infleet - Abastecimentos de equipamentos"
      element={
        <Export>
          <Icon />
          Exportar dados
        </Export>
      }
    >
      <ExcelSheet data={fuellings} name="Abastecimentos de equipamentos">
        <ExcelColumn label="Data" value="date" />
        <ExcelColumn label="Veículo" value="plate" />
        <ExcelColumn label="Motorista" value="driver" />
        <ExcelColumn label="Quantidade (L)" value="fuelAmount" />
        <ExcelColumn label="Horímetro (h)" value="horimeter" />
        <ExcelColumn label="Fornecedor" value="provider" />
        <ExcelColumn label="Valor do litro (R$/L)" value="literValue" />
        <ExcelColumn label="Horas corridas" value="hours" />
        <ExcelColumn label="Autonomia média (h/L)" value="autonomy" />
      </ExcelSheet>
    </ExcelFile>
  );
}
