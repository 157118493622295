import styled from 'styled-components';

export const CardContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  border: 1px solid #dee2e6;
`;

export const CardTitle = styled.h3`
  padding: 1rem;
`;

export const CardNumber = styled.p`
  padding: 1rem;
  margin-bottom: 0;
  font-size: 2rem;
  text-align: right;
`;

export const CardSubcontainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardTrigger = styled.span`
  padding-top: 2.5rem;
  margin-left: 1rem;
  cursor: pointer;
`;
