import styled from 'styled-components';
import { Field } from 'formik';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
`;

export const FormContainer = styled.div`
  position: absolute;
  top: 30%;
  padding: 2rem;
  background-color: #fff;
  width: 360px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const FormTitle = styled.h2`
  text-align: center;
  margin-bottom: 2rem;
`;

export const StyledField = styled(Field)`
  margin-bottom: 1rem;
`;

export const SubmitButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #001132;
  color: #fff;
  border: none;
  border-radius: 8px;
  display: block;
  width: 100%;
  cursor: pointer;
`;
