import { ResponsiveBar, Data } from '@nivo/bar';
import React from 'react';

export default function CostsAndQuantityChartCanvas(props: Data) {
  return (
    <ResponsiveBar
      data={props.data}
      keys={['Custo']}
      indexBy="date"
      margin={{ top: 50, right: 130, bottom: 105, left: 60 }}
      padding={0.3}
      colors="#5262b0"
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Período',
        legendPosition: 'middle',
        legendOffset: 40,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 3,
        tickRotation: 0,
        legend: 'Custo (R$)',
        legendPosition: 'middle',
        legendOffset: -50,
        tickValues: 4,
      }}
      label={(d) => `${Number(d.value).toFixed(2)}`}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor="white"
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  );
}
