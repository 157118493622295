import firebase from "firebase";

const config = {
  apiKey: "AIzaSyA8DKFRND4iYw6E5h6KwNBipYdTng1FzRw",
  authDomain: "fueling-app-ff35c.firebaseapp.com",
  databaseURL: "https://fueling-app-ff35c.firebaseio.com",
  projectId: "fueling-app-ff35c",
  storageBucket: "fueling-app-ff35c.appspot.com",
  messagingSenderId: "980079080568",
};

export const app = firebase.initializeApp(config);
export const db = firebase.firestore(app);
