import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Dashboard from '../../modules/Dashboard';
import Fuellings from '../../modules/Fuellings/Fuellings';
import Orders from '../../modules/Orders/Orders';
import ProvidersWrapper from '../../modules/Providers';
import TankForm from '../../modules/Tanks/TankForm';
import Tanks from '../../modules/Tanks/Tanks';
import TransferForm from '../../modules/Tanks/TransferForm';
import Vehicles from '../../modules/Vehicles';
import DetailVehicle from '../../modules/Vehicles/DetailVehicle';
import Content from './Content';

export default function Routes() {
  return (
    <Router>
      <Content>
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route path="/fuellings">
            <Fuellings />
          </Route>
          <Route path="/orders">
            <Orders />
          </Route>
          <Route exact path="/tanks">
            <Tanks />
          </Route>
          <Route exact path="/tanks/new">
            <TankForm />
          </Route>
          <Route exact path="/tanks/:id">
            <TankForm />
          </Route>
          <Route path="/tanks/transfers/new">
            <TransferForm />
          </Route>
          <Route path="/providers">
            <ProvidersWrapper />
          </Route>
          <Route exact path="/vehicles-and-equipments">
            <Vehicles />
          </Route>
          <Route path="/vehicles-and-equipments/:id">
            <DetailVehicle />
          </Route>
        </Switch>
      </Content>
    </Router>
  );
}
