import { Col, message, Row } from 'antd';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import { Form, Input, InputNumber, SubmitButton } from 'formik-antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { AuthContext } from '../../contexts/AuthProvider';
import { db } from '../../firebaseConfig';
import { Tank } from '../../helpers/types';
import { Subtitle } from '../../components/PageSubtitle/styles';
import styled from 'styled-components';

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const TankSchema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  capacity: Yup.number()
    .min(1, 'Capacidade do tanque precisa ser maior que 0')
    .required('Campo obrigatório'),
  initialStock: Yup.number().required('Campo obrigratório'),
  pumps: Yup.array()
    .of(
      Yup.object().shape({
        description: Yup.string().required('Campo obrigatório'),
        odometer: Yup.number().required('Campo obrigatório'),
      })
    )
    .required()
    .min(1, 'É preciso inserir pelo menos uma bomba'),
});

async function createTank(organization: string, tank: Tank) {
  await db
    .collection('organizations')
    .doc(organization)
    .collection('tanks')
    .add(tank)
    .then(() => {
      db.collection('latestTanks').doc(organization).set({
        latestTank: new Date(),
        organization: organization,
      });
    })
    .catch((err) => {
      message.error('Falha ao salvar tanque');
      console.error(err);
      throw err;
    });
}

async function updateTank(
  organization: string,
  id: string | undefined,
  tank: Tank
) {
  await db
    .collection('organizations')
    .doc(organization)
    .collection('tanks')
    .doc(id)
    .set(tank)
    .then(() => {
      db.collection('latestTanks').doc(organization).set({
        latestTank: new Date(),
        organization: organization,
      });
    })
    .catch((err) => {
      message.error('Falha ao atualizar tanque');
      console.error(err);
      throw err;
    });
}

const useTank = (organization: string | undefined, id: string | undefined) => {
  const [loading, setLoading] = useState(true);
  const [tank, setTank] = useState<Tank | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    if (!document || !id) {
      setLoading(false);
      return;
    }

    db.collection('organizations')
      .doc(organization)
      .collection('tanks')
      .doc(id)
      .onSnapshot(
        (doc) => {
          setLoading(false);
          setTank((doc.data() as unknown) as Tank);
        },
        (err) => {
          setError(err);
          throw err;
        }
      );
  }, [organization, id]);

  return {
    tank,
    error,
    loading,
  };
};

const Container = styled.div`
  margin: 1rem 0;
`;

const FormContainer = styled.div`
  margin: 0.75rem 0;
`;

const TankForm = () => {
  const { id } = useParams() as { id?: string };
  const { push } = useHistory();
  const { firebaseOrganizationId } = useContext(AuthContext);

  const {
    loading,
    tank = {
      name: '',
      capacity: 0,
      initialStock: 0,
      pumps: [{ description: '', odometer: 0 }],
    },
  } = useTank(firebaseOrganizationId, id);

  const handleCreate = async (
    values: Tank,
    { resetForm }: FormikHelpers<Tank>
  ) => {
    if (!firebaseOrganizationId) throw Error('Missing organization');

    await createTank(firebaseOrganizationId, {
      ...values,
      pumps: values.pumps.map((pump) => ({ ...pump, id: uuidv4() })),
    });
    resetForm();
    push('/tanks');
  };

  const handleUpdate = async (
    values: Tank,
    { resetForm }: FormikHelpers<Tank>
  ) => {
    if (!firebaseOrganizationId) throw Error('Missing organization');

    await updateTank(firebaseOrganizationId, id, values);
    resetForm();
    push('/tanks');
  };

  const handleSubmit = id ? handleUpdate : handleCreate;

  if (loading) return <p>loading...</p>;

  return (
    <>
      <Link to="/tanks">Voltar</Link>

      <Container>
        <Subtitle>{id ? 'Editar' : 'Adicionar'} Tanque</Subtitle>
        <FormContainer>
          <Formik
            enableReinitialize
            initialValues={tank}
            validationSchema={TankSchema}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="name" label="Nome do tanque">
                      <Input name="name" placeholder="Nome do tanque" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Form.Item name="capacity" label="Capacidade do tanque">
                      <InputNumber
                        name="capacity"
                        style={{ width: '50%' }}
                        placeholder="Capacidade do tanque"
                        min={0}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="initialStock" label="Estoque inicial">
                      <InputNumber
                        name="initialStock"
                        style={{ width: '50%' }}
                        placeholder="Estoque inicial"
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <hr />

                <FieldArray name="pumps">
                  {({ push, remove }) => (
                    <>
                      <h4 style={{ display: 'inline', paddingRight: 30 }}>
                        Bombas
                      </h4>
                      {values.pumps.map((_, index) => (
                        <div key={index}>
                          <Row gutter={8}>
                            <Col span={8}>
                              <Form.Item
                                name="description"
                                label="Descrição da bomba"
                              >
                                <Input
                                  name={`pumps.${index}.description`}
                                  placeholder="Descrição da bomba"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                name="odometer"
                                label="Odometro da bomba"
                              >
                                <InputNumber
                                  name={`pumps.${index}.odometer`}
                                  style={{ width: '50%' }}
                                  placeholder="Odometro da bomba"
                                  min={0}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>

                <Form.Item name="submit">
                  <SubmitButton type="primary">Enviar</SubmitButton>
                </Form.Item>
              </Form>
            )}
          </Formik>
        </FormContainer>
      </Container>
    </>
  );
};

export default TankForm;
