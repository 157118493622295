import moment from 'moment';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../contexts/AuthProvider';
import { db } from '../../../firebaseConfig';
import { EquipmentFuelling, Fuelling, Order } from '../../../helpers/types';

interface IGeneralContext {
  fuellings: Fuelling[];
  equipmentFuellings: EquipmentFuelling[];
  orders: Order[];
  rangeDate: any;
  setRangeDate: (dates: any) => void;
}

interface IGeneralProviderProps {
  children: ReactNode;
}

export const GeneralContext = React.createContext<IGeneralContext>({} as any);

export default function GeneralProvider(props: IGeneralProviderProps) {
  const { firebaseOrganizationId } = useContext(AuthContext);

  const [fuellingsState, setFuellingsState] = useState<Fuelling[]>([]);
  const [equipmentFuellingsState, setEquipmentFuellingsState] = useState<
    EquipmentFuelling[]
  >([]);
  const [rangeDateState, setRangeDateState] = useState<any[]>([
    moment().startOf('month'),
    moment(),
  ]);
  const [ordersState, setOrdersState] = useState<Order[]>([]);

  useEffect(() => {
    if (!firebaseOrganizationId) {
      return;
    }

    db.collection('organizations')
      .doc(firebaseOrganizationId)
      .collection('fuellings')
      .where('date', '>=', rangeDateState[0]?.toDate())
      .where('date', '<=', rangeDateState[1]?.toDate())
      .onSnapshot((querySnapshot) => {
        const fuellings = querySnapshot.docs
          .map((doc) => {
            const filledUp = doc.data().hasOwnProperty('filledUp')
              ? doc.data().filledUp
              : true;
            return {
              ...doc.data(),
              date: doc.data().date.toDate(),
              filledUp: filledUp,
              id: doc.id,
            } as Fuelling;
          })
          .filter((fuelling: Fuelling) => fuelling.car.type !== 'equipment');
        setFuellingsState(fuellings);
      });
  }, [firebaseOrganizationId, rangeDateState]);

  useEffect(() => {
    if (!firebaseOrganizationId) {
      return;
    }

    db.collection('organizations')
      .doc(firebaseOrganizationId)
      .collection('fuellings')
      .where('date', '>=', rangeDateState[0]?.toDate())
      .where('date', '<=', rangeDateState[1]?.toDate())
      .onSnapshot((querySnapshot) => {
        const fuellings = querySnapshot.docs
          .map((doc) => {
            const filledUp = doc.data().hasOwnProperty('filledUp')
              ? doc.data().filledUp
              : true;
            return {
              ...doc.data(),
              date: doc.data().date.toDate(),
              filledUp: filledUp,
              id: doc.id,
            } as EquipmentFuelling;
          })
          .filter(
            (fuelling: EquipmentFuelling) => fuelling.car.type === 'equipment'
          );
        setEquipmentFuellingsState(fuellings);
      });
  }, [firebaseOrganizationId, rangeDateState]);

  useEffect(() => {
    if (!firebaseOrganizationId) {
      return;
    }

    db.collection('organizations')
      .doc(firebaseOrganizationId)
      .collection('orders')
      .where('invoiceDate', '>=', rangeDateState[0]?.toDate())
      .where('invoiceDate', '<=', rangeDateState[1]?.toDate())
      .onSnapshot((querySnapshot) => {
        const orders = querySnapshot.docs.map(
          (doc) =>
            ({
              ...doc.data(),
              id: doc.id,
              invoiceDate: doc.data().invoiceDate.toDate(),
            } as Order)
        );

        setOrdersState(orders);
      });
  }, [firebaseOrganizationId, rangeDateState]);

  const value = {
    fuellings: fuellingsState,
    equipmentFuellings: equipmentFuellingsState,
    orders: ordersState,
    rangeDate: rangeDateState,
    setRangeDate: setRangeDateState,
  };

  return (
    <GeneralContext.Provider value={value}>
      {props.children}
    </GeneralContext.Provider>
  );
}

export function useGeneralContext() {
  const context = useContext(GeneralContext);

  if (typeof context === 'undefined') {
    throw new Error(`useGeneralContext must be used within a GeneralContext`);
  }

  return context;
}
