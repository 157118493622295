import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { VehicleFragment } from '../../generated/graphql';
import { useReferenceOdometer } from '../../helpers/hooks';
import { FormValues } from './TankFuellingForm';

const Instruction = styled.p`
  font-weight: 600;
`;

const Container = styled.div`
  background-color: #efefef;
  padding: 1rem;
  border-radius: 8px;
`;

export const UpdateReferenceFuelling = (props: {
  vehicles: VehicleFragment[];
}) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { vehicle, date } = values;

  const selectedVehicle = props.vehicles?.find(
    ({ id, plate }) => id === vehicle || plate === vehicle
  )!;

  const { minOdometer, maxOdometer, recentOdometer } = useReferenceOdometer(
    selectedVehicle,
    moment(date).toDate(),
    values.id
  );

  const label: String =
    selectedVehicle?.type !== 'equipment' ? 'odômetro' : 'horímetro';

  useEffect(() => {
    const field =
      selectedVehicle?.type !== 'equipment' ? 'odometer' : 'horimeter';

    setFieldValue('minOdometer', minOdometer);
    setFieldValue('maxOdometer', maxOdometer);
    setFieldValue('recentOdometer', recentOdometer);

    if (vehicle && !values.id) {
      setFieldValue(`${field}`, minOdometer);
    }
  }, [
    minOdometer,
    recentOdometer,
    maxOdometer,
    setFieldValue,
    vehicle,
    date,
    values.id,
  ]);

  return minOdometer || recentOdometer || maxOdometer ? (
    selectedVehicle?.type !== 'equipment' ? (
      <>
        <br />
        <Row>
          <Col span={24}>
            <Container>
              <Instruction>
                Insira um {label} válido atentando-se aos valores permitidos
              </Instruction>
              <ul>
                <li>Medida mínima: {minOdometer || '--'} km</li>
                <li>Medida atual: {recentOdometer} km</li>
                <li>
                  Medida máxima: {maxOdometer === Infinity ? '--' : maxOdometer}{' '}
                  km
                </li>
              </ul>
            </Container>
          </Col>
        </Row>
      </>
    ) : (
      <>
        <br />
        <Row>
          <Col span={24}>
            <Container>
              <Instruction>
                Insira um {label} válido atentando-se aos valores permitidos
              </Instruction>
              <ul>
                <li>Medida atual: {recentOdometer} h</li>
                <li>Medida mínima: {minOdometer || '--'} h</li>
                <li>
                  Medida máxima: {maxOdometer === Infinity ? '--' : maxOdometer}{' '}
                  h
                </li>
              </ul>
            </Container>
          </Col>
        </Row>
      </>
    )
  ) : (
    <p></p>
  );
};
