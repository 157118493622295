import { User } from '@infleet/core';
import React, { useEffect, useState } from 'react';
import authClient from '../auth-client';
import { app, db } from '../firebaseConfig';
export interface IAuthProviderProps {
  children: React.ReactNode;
}

export interface IAuthContext {
  isLoggedIn: boolean;
  user?: User;
  firebaseOrganizationId?: string;
  firebaseUser?: firebase.User;
}

export const AuthContext = React.createContext<IAuthContext>({} as any);

export default function AuthProvider(props: IAuthProviderProps) {
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useState<User | undefined>();
  const [firebaseUser, setFirebaseUser] = useState<firebase.User | undefined>(
    app.auth().currentUser ?? void 0,
  );
  const [firebaseOrganizationId, setFirebaseOrganizationId] = useState<string>();

  useEffect(() => {
    authClient.currentUser().then((user) => {
      setUser(user ?? void 0);
      setInitialized(true);
    });

    // Unsubscribe functions
    const unsubscribeCore = authClient.onAuthStateChanged((user) => {
      setUser(user);
    });

    const unsubscribeFirebase = app.auth().onAuthStateChanged((user) => {
      setFirebaseUser(user ?? void 0);
    });

    return () => {
      unsubscribeCore();
      unsubscribeFirebase();
    };
  }, []);

  useEffect(() => {
    if (!firebaseUser) return;

    let mounted = true;

    (async () => {
      const doc = await db.collection('users').doc(firebaseUser.uid).get();
      if (!mounted) return;
      const user = doc.data();
      setFirebaseOrganizationId(user?.organization);
    })();

    return () => {
      mounted = false;
    };
  }, [firebaseUser, firebaseOrganizationId]);

  if (!initialized) {
    return <>Carregando...</>;
  }

  const isLoggedIn = !!user && !!firebaseUser;

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, firebaseUser, firebaseOrganizationId }}>
      {props.children}
    </AuthContext.Provider>
  );
}
