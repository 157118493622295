import React from 'react';
import { FUELLINGS } from '../../constants';
import EquipmentChart from './EquipmentChart';
import VehicleChart from './VehicleChart';

interface Props {
  type: string;
}

export default function DistanceOrHorimeterChart(props: Props) {
  const { type } = props;

  return <>{type === FUELLINGS ? <VehicleChart /> : <EquipmentChart />} </>;
}
