import { DeleteFilled } from '@ant-design/icons';
import { Col, message, Popconfirm, Row, Table, Tooltip } from 'antd';
import { groupBy, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthProvider';
import { db } from '../../firebaseConfig';
import { useFuellings } from '../../helpers/hooks';
import { Fuelling } from '../../helpers/types';
import ExcelExportVehicles from './ExcelExportVehicles';
import { EditFuelling } from './FuellingForm';
import { Container, Title } from './styles';

const { Column } = Table;

export function VehiclesFuellings({
  startDate,
  finalDate,
}: {
  startDate: Date;
  finalDate: Date;
}) {
  const { firebaseOrganizationId } = useContext(AuthContext);

  const { fuellings } = useFuellings();

  const sortedFuellings =
    fuellings
      .filter(({ date }) => date >= startDate && date <= finalDate)
      .sort((a, b) => b.date.getTime() - a.date.getTime()) ?? [];

  const fuellingsByVehicle = groupBy(sortedFuellings, 'car.plate');

  const vehiclesFilters = uniqBy(
    fuellings
      .map(({ car }) => ({
        text: car.plate,
        value: car.plate || '',
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1)),
    'text'
  );

  const handleDelete = (fuelling: Fuelling) => {
    const orgRef = db.collection('organizations').doc(firebaseOrganizationId);
    const fuellingsRef = orgRef.collection('fuellings');

    fuellingsRef
      .doc(fuelling.id)
      .delete()
      .then(() => {
        message.success('Abastecimento removido com sucesso.');
      });
  };

  console.log('fuellings', sortedFuellings);

  return (
    <div className="table-container">
      <Container>
        <Title>Abastecimento de veículos</Title>
        <ExcelExportVehicles fuellings={sortedFuellings} />
      </Container>
      <Row>
        <Col
          style={{
            height: '60vh',
            overflowY: 'auto',
          }}
          span={24}
        >
          <Table pagination={false} dataSource={sortedFuellings} rowKey="id">
            <Column
              title="Data"
              key="date"
              dataIndex="date"
              render={(date: string) => moment(date).format('DD/MM/YY HH:mm')}
            />
            <Column
              title="Veículo"
              filters={vehiclesFilters}
              onFilter={(value, record: any) =>
                record.car.plate.indexOf(value) === 0
              }
              render={(value, record: Fuelling) => record.car.plate}
            />
            <Column
              title="Motorista"
              render={(value, record: Fuelling) => {
                return record?.driver?.name ?? '-';
              }}
            />
            <Column
              title="Quantidade"
              key="fuelAmount"
              dataIndex="fuelAmount"
            />
            <Column title="Odômetro (km)" key="odometer" dataIndex="odometer" />
            <Column
              title="Fornecedor"
              render={(value, record: Fuelling) => {
                if (record.provider) {
                  return record.provider.trade;
                } else {
                  return record?.tank?.name || value;
                }
              }}
            />
            <Column
              title="Valor do litro (R$/L)"
              key="literValue"
              dataIndex="literValue"
              render={(_, record: Fuelling) => {
                const literValue =
                  record.value &&
                  (record?.value / record.fuelAmount).toFixed(2);
                return record?.value ? (
                  <Tooltip title={'Valor total abastecido: R$ ' + record.value}>
                    <span>{literValue + ' *'}</span>
                  </Tooltip>
                ) : (
                  <span>-</span>
                );
              }}
            />
            <Column
              title="Distância percorrida (km)"
              render={(_, record: Fuelling) => {
                const vehicleFuellings =
                  fuellingsByVehicle[record?.car?.plate!];

                const vehicleFuellingsIndex = vehicleFuellings.findIndex(
                  (obj) => obj.id === record.id
                );

                const previousFuelling =
                  vehicleFuellings[vehicleFuellingsIndex + 1];

                if (!previousFuelling) return '-';

                return (record.odometer! - previousFuelling.odometer).toFixed(
                  2
                );
              }}
            />
            <Column
              title="Autonomia média (km/l)"
              dataIndex="autonomy"
              key="autonomy"
              render={(_, record: Fuelling) => {
                const vehicleFuellings =
                  fuellingsByVehicle[record?.car?.plate!];

                const vehicleFuellingsIndex = vehicleFuellings.findIndex(
                  (obj) => obj.id === record.id
                );

                const previousFuelling =
                  vehicleFuellings[vehicleFuellingsIndex + 1];

                if (record.filledUp && previousFuelling?.filledUp) {
                  return (
                    (record.odometer! - previousFuelling.odometer) /
                    record.fuelAmount
                  ).toFixed(2);
                } else {
                  return '-';
                }
              }}
            />
            <Column
              title="Ação"
              key="action"
              render={(_: any, record: Fuelling) => (
                <>
                  <EditFuelling fuelling={record} type="VEHICLE" />

                  <Popconfirm
                    title="Confirmar remoção?"
                    onConfirm={(_) => handleDelete(record)}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <DeleteFilled style={{ cursor: 'pointer' }} />
                  </Popconfirm>
                </>
              )}
            />
          </Table>
        </Col>
      </Row>
    </div>
  );
}
