import moment from 'moment';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../contexts/AuthProvider';
import { db } from '../../../firebaseConfig';
import { EquipmentFuelling, Fuelling } from '../../../helpers/types';

interface ISpecificContext {
  specificFuellings: Fuelling[];
  specificEquipmentFuellings: EquipmentFuelling[];
  specificRangeDate: any;
  setSpecificRangeDate: (dates: any) => void;
}

interface ISpecificProviderProps {
  children: ReactNode;
}

export const SpecificContext = React.createContext<ISpecificContext>({} as any);

export default function SpecificProvider(props: ISpecificProviderProps) {
  const { firebaseOrganizationId } = useContext(AuthContext);
  const [fuellingsState, setFuellingsState] = useState<Fuelling[]>([]);
  const [equipmentFuellingsState, setEquipmentFuellingsState] = useState<
    EquipmentFuelling[]
  >([]);
  const [rangeDateState, setRangeDateState] = useState<any>([
    moment().startOf('month'),
    moment(),
  ]);

  useEffect(() => {
    if (!firebaseOrganizationId) {
      return;
    }

    db.collection('organizations')
      .doc(firebaseOrganizationId)
      .collection('fuellings')
      .where('date', '>=', rangeDateState[0]?.toDate())
      .where('date', '<=', rangeDateState[1]?.toDate())
      .orderBy('date', 'asc')
      .onSnapshot((querySnapshot) => {
        const fuellings = querySnapshot.docs
          .map((doc) => {
            const filledUp = doc.data().hasOwnProperty('filledUp')
              ? doc.data().filledUp
              : true;
            return {
              ...doc.data(),
              date: doc.data().date.toDate(),
              filledUp: filledUp,
              id: doc.id,
            } as Fuelling;
          })
          .filter((fuelling: Fuelling) => fuelling.car.type !== 'equipment');
        setFuellingsState(fuellings);
      });
  }, [firebaseOrganizationId, rangeDateState]);

  useEffect(() => {
    if (!firebaseOrganizationId) {
      return;
    }

    db.collection('organizations')
      .doc(firebaseOrganizationId)
      .collection('fuellings')
      .where('date', '>=', rangeDateState[0]?.toDate())
      .where('date', '<=', rangeDateState[1]?.toDate())
      .orderBy('date', 'asc')
      .onSnapshot((querySnapshot) => {
        const fuellings = querySnapshot.docs
          .map((doc) => {
            const filledUp = doc.data().hasOwnProperty('filledUp')
              ? doc.data().filledUp
              : true;
            return {
              ...doc.data(),
              date: doc.data().date.toDate(),
              filledUp: filledUp,
              id: doc.id,
            } as EquipmentFuelling;
          })
          .filter(
            (fuelling: EquipmentFuelling) => fuelling.car.type === 'equipment'
          );
        setEquipmentFuellingsState(fuellings);
      });
  }, [firebaseOrganizationId, rangeDateState]);

  const value = {
    specificFuellings: fuellingsState,
    specificEquipmentFuellings: equipmentFuellingsState,
    specificRangeDate: rangeDateState,
    setSpecificRangeDate: setRangeDateState,
  };

  return (
    <SpecificContext.Provider value={value}>
      {props.children}
    </SpecificContext.Provider>
  );
}

export function useSpecificContext() {
  const context = useContext(SpecificContext);

  if (typeof context === 'undefined') {
    throw new Error(`useSpecificContext must be used within a SpecificContext`);
  }

  return context;
}
