import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { Instruction } from '../../components/Instruction/';
import { ContainerTitle, Title } from '../../components/PageTitle/styles';
import { EquipmentsFuellings } from './EquipmentsFuellings';
import NewFuelling from './FuellingForm';
import { VehiclesFuellings } from './VehiclesFuellings';

const { RangePicker } = DatePicker;

const CustomRangePicker = styled(RangePicker)`
  margin: 0.5rem 0;
`;

export default function Fuellings() {
  const [startDate, setStartDate] = useState(
    moment().startOf('day').startOf('month').toDate()
  );
  const [finalDate, setFinalDate] = useState(moment().endOf('day').toDate());

  return (
    <div>
      <ContainerTitle>
        <Title>Abastecimentos</Title>
        <div>
          <NewFuelling />
        </div>
      </ContainerTitle>
      <Instruction>
        Selecione o período para consultar o histórico de abastecimentos
      </Instruction>
      <CustomRangePicker
        defaultValue={[moment(startDate), moment(finalDate)]}
        onChange={(dates) => {
          setStartDate(dates[0].toDate());
          setFinalDate(dates[1].toDate());
        }}
      />
      <VehiclesFuellings startDate={startDate} finalDate={finalDate} />
      <EquipmentsFuellings startDate={startDate} finalDate={finalDate} />
    </div>
  );
}
