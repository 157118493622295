import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthProvider';
import GeneralProvider from './contexts/GeneralContext';
import SpecificProvider from './contexts/SpecificContext';
import DashboardContent from './DashboardContent';

interface Props {}

export default function Dashboard(props: Props) {
  const { firebaseOrganizationId } = useContext(AuthContext);

  if (!firebaseOrganizationId) {
    return <p>Carregando...</p>;
  }

  return (
    <GeneralProvider>
      <SpecificProvider>
        <DashboardContent />
      </SpecificProvider>
    </GeneralProvider>
  );
}
