import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Divider, message, Popconfirm, Table } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthProvider';
import { db } from '../../firebaseConfig';
import {
  useFuellings,
  useOrders,
  useTanks,
  useTransfers,
} from '../../helpers/hooks';
import { ContainerTitle, Title } from '../../components/PageTitle/styles';
import Transfers from './Transfers';
import { Tank } from '../../types';

const { Column } = Table;

const Tanks = () => {
  const { firebaseOrganizationId } = useContext(AuthContext);

  const { tanks, loading } = useTanks();
  const { transfers } = useTransfers();
  const { fuellings } = useFuellings();
  const { orders } = useOrders();

  const handleDelete = async (id: string | undefined) => {
    if (!id) return;

    await db
      .collection('organizations')
      .doc(firebaseOrganizationId)
      .collection('tanks')
      .doc(id)
      .delete()
      .then(() => {
        message.success('Tanque removido com sucesso.');
      });
  };

  const fuellingsByTank = (tankId: string | undefined): number => {
    return fuellings
      .filter(({ tank }: any) => Boolean(tank))
      .filter(({ tank }: any) => tank.id === tankId)
      .map(({ fuelAmount }: any) => fuelAmount)
      .reduce((a, b) => a + b, 0);
  };

  const ordersByTank = (tankId: string | undefined): number => {
    return orders
      .filter((order) => (order && order.tank && order.tank.id) === tankId)
      .map(({ quantity }) => quantity)
      .reduce((a, b) => a + b, 0);
  };

  const transfersByTank = (tankId: string | undefined): number => {
    const inward = transfers
      .filter((t) => (t && t.destination && t.destination.id) === tankId)
      .map(({ amount }) => amount)
      .reduce((a, b) => a + b, 0);

    const outward = transfers
      .filter((t) => (t && t.origin && t.origin.id) === tankId)
      .map(({ amount }) => amount)
      .reduce((a, b) => a + b, 0);

    return inward - outward;
  };

  const tanksWithStock = tanks.map((tank) => ({
    ...tank,
    stock:
      tank.initialStock +
      ordersByTank(tank.id) -
      fuellingsByTank(tank.id) +
      transfersByTank(tank.id),
  }));

  return (
    <div style={{ marginBottom: 30, padding: 20 }}>
      <ContainerTitle>
        <Title>Tanques</Title>
        <Link to="tanks/new">
          <div>
            <button className="submitButton">Adicionar tanque</button>
          </div>
        </Link>
      </ContainerTitle>

      <Table
        rowKey={({ id = '' }) => id}
        pagination={false}
        loading={loading}
        dataSource={tanksWithStock}
        locale={{ emptyText: 'Nenhum tanque cadastrado' }}
      >
        <Column title="Nome" dataIndex="name" key="name" />
        <Column title="Capacidade" dataIndex="capacity" key="capacity" />
        <Column
          title="Bombas"
          render={(text: string, record: Tank) => record.pumps.length}
        />
        <Column title="Estoque atual" dataIndex="stock" key="stock" />
        <Column
          title="Ações"
          dataIndex="id"
          key="actions"
          render={(id) => (
            <>
              <Link to={`/tanks/${id}`}>
                <EditFilled style={{ color: '#000', marginRight: '0.5rem' }} />
              </Link>
              <Popconfirm
                title="Confirmar remoção?"
                onConfirm={(_) => handleDelete(id)}
                okText="Sim"
                cancelText="Não"
              >
                <DeleteFilled />
              </Popconfirm>
            </>
          )}
        />
      </Table>
      <Transfers />
    </div>
  );
};

export default Tanks;
