import { Form, InputNumber, Select, SubmitButton } from 'formik-antd';
import { message } from 'antd';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import React, { useContext } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { db } from '../../firebaseConfig';
import { useTransfer } from '../../helpers/hooks';
import { useTanks } from '../../helpers/hooks';
import { Transfer } from '../../helpers/types';
import { AuthContext } from '../../contexts/AuthProvider';
import { Subtitle } from '../../components/PageSubtitle/styles';
import styled from 'styled-components';

interface TransferFetch {
  [key: string]: any;
}

const Container = styled.div`
  margin: 1rem 0;
`;

const FormContainer = styled.div`
  margin: 0.75rem 0;
`;

const { Option } = Select;

const TransferSchema = Yup.object().shape({
  origin: Yup.string().required('Campo obrigatorio'),
  destination: Yup.string().required('Campo obrigatorio'),
  amount: Yup.number()
    .min(1, 'Quantidade não pode ser menor que 1')
    .required('Campo obrigatorio'),
});

const formItemLayout = {
  labelCol: {
    xl: { span: 24 },
    lg: { span: 24 },
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xl: { span: 24 },
    lg: { span: 24 },
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const createTransfer = async (organization: string, transfer: Transfer) => {
  try {
    return db
      .collection('organizations')
      .doc(organization)
      .collection('transfers')
      .add(transfer);
  } catch (err) {
    throw err;
  }
};

const updateTransfer = async (
  organization: string,
  id: string | undefined,
  transfer: Transfer
) => {
  try {
    return db
      .collection('organizations')
      .doc(organization)
      .collection('transfers')
      .doc(id)
      .set(transfer);
  } catch (err) {
    throw err;
  }
};

const TransferForm = () => {
  const { id } = useParams() as { id?: string };
  const { push } = useHistory();
  const { firebaseOrganizationId } = useContext(AuthContext);

  console.log(firebaseOrganizationId);

  const { tanks } = useTanks();
  const { transfer }: any = useTransfer(id ?? '');

  const handleCreate = async (
    values: FormikValues,
    { resetForm }: FormikHelpers<FormikValues>
  ) => {
    const origin = tanks.find(({ id }) => id === values.origin);
    const destination = tanks.find(({ id }) => id === values.destination);
    try {
      if (!firebaseOrganizationId) throw new Error('Invalid organization');

      await createTransfer(firebaseOrganizationId, ({
        amount: values.amount,
        origin,
        destination,
      } as unknown) as Transfer);
      message.success('Transferencia realizada com sucesso');
    } catch (err) {
      message.error('Falha ao fazer transferencia');
      console.error(err);
    } finally {
      resetForm();
      push('/tanks');
    }
  };

  const handleUpdate = async (
    values: FormikValues,
    { resetForm }: FormikHelpers<FormikValues>
  ) => {
    const origin = tanks.find(({ id }) => id === values.origin);
    const destination = tanks.find(({ id }) => id === values.destination);
    try {
      if (!firebaseOrganizationId) throw new Error('Invalid organization');

      await updateTransfer(firebaseOrganizationId, id, ({
        amount: values.amount,
        origin,
        destination,
      } as unknown) as Transfer);
      message.success('Transferencia atualizada com sucesso');
    } catch (err) {
      message.error('Falha ao atualizar transferencia');
      console.error(err);
    } finally {
      resetForm();
      push('/tanks');
    }
  };

  const handleSubmit = id ? handleUpdate : handleCreate;

  const initialValues = id
    ? {
        ...transfer,
        origin: transfer && transfer && transfer.origin.id,
        destination: transfer && transfer && transfer.destination.id,
      }
    : {};

  return (
    <>
      <Link to="/tanks">Voltar</Link>
      <Container>
        <Subtitle>{id ? 'Editar' : 'Nova'} transferencia</Subtitle>
        <FormContainer>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={TransferSchema}
            onSubmit={handleSubmit}
          >
            <Form {...formItemLayout}>
              <Form.Item name="origin" label="Origem">
                <Select
                  name="origin"
                  showArrow={true}
                  allowClear={true}
                  placeholder="Origem"
                  style={{ width: 200 }}
                >
                  {tanks.map(({ id, name }, key) => (
                    // TODO: remove ts-ignore
                    // @ts-ignore
                    <Option value={id} key={key}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="destination" label="Destino">
                <Select
                  name="destination"
                  showArrow={true}
                  allowClear={true}
                  placeholder="Destino"
                  style={{ width: 200 }}
                >
                  {tanks.map(({ id, name }, key) => (
                    // TODO: remove ts-ignore
                    // @ts-ignore
                    <Option value={id} key={key}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="amount" label="Quantidade">
                <InputNumber name="amount" />
              </Form.Item>

              <Form.Item name="submit">
                <SubmitButton type="primary">Enviar</SubmitButton>
              </Form.Item>
            </Form>
          </Formik>
        </FormContainer>
      </Container>
    </>
  );
};

export default TransferForm;
