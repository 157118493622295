import React, { useMemo } from 'react';
import { EquipmentFuelling, Fuelling } from '../../../../helpers/types';
import { FUELLINGS } from '../../constants';
import { useSpecificContext } from '../../contexts/SpecificContext';
import EquipmentChartCanvas from './EquipmentChartCanvas';
import {
  Container,
  Indicator,
  IndicatorContainer,
  Title,
  TitleContainer,
  Value,
} from './styles';
import VehicleChartCanvas from './VehicleChartCanvas';
import { uniqBy } from 'lodash';

interface Props {
  type: string;
}

interface ChartData {
  [key: string]: any;
  Autonomia: number;
}

export default function AutonomyChart(props: Props) {
  const { type } = props;
  const {
    specificFuellings,
    specificEquipmentFuellings,
  } = useSpecificContext();

  const dataFuellings: ChartData[] = useMemo(() => {
    const mappedVehicles = uniqBy(
      specificFuellings
        .map(({ car: { id, plate } }) => {
          let fuellingsAmount = 0;
          let autonomy = 0;
          const vehicleFuellings = specificFuellings.filter(
            (fuelling: Fuelling) => fuelling.car.id === id
          );

          if (!vehicleFuellings.length) return { vehicle: plate, Autonomia: 0 };

          vehicleFuellings.forEach(
            (item: Fuelling, index: number, arr: Fuelling[]) => {
              const latestFuelling = arr[index - 1];
              if (item.filledUp && latestFuelling?.filledUp) {
                const odometerDiff = item.odometer - latestFuelling.odometer;
                autonomy = autonomy + odometerDiff / item.fuelAmount;
                fuellingsAmount = fuellingsAmount + 1;
              }
            }
          );

          return {
            vehicle: plate,
            Autonomia: autonomy / fuellingsAmount,
          };
        })
        .filter((item) => item.Autonomia),
      'vehicle'
    );

    return mappedVehicles;
  }, [specificFuellings]);

  const dataEquipmentFuellings: ChartData[] = useMemo(() => {
    const mappedEquipments = uniqBy(
      specificEquipmentFuellings.map(({ car: { id, plate } }) => {
        let fuellingsAmount = 0;
        let autonomy = 0;
        const filteredEquipmentFuellings = specificEquipmentFuellings.filter(
          (fuelling: EquipmentFuelling) => fuelling.car.id === id
        );

        if (!filteredEquipmentFuellings.length)
          return { equipment: plate, Autonomia: 0 };

        filteredEquipmentFuellings.forEach(
          (
            item: EquipmentFuelling,
            index: number,
            arr: EquipmentFuelling[]
          ) => {
            const latestFuelling = arr[index - 1];
            if (item.filledUp && latestFuelling?.filledUp) {
              const horimeterDiff = item.horimeter - latestFuelling.horimeter;
              autonomy = autonomy + horimeterDiff / item.fuelAmount;
              fuellingsAmount = fuellingsAmount + 1;
            }
          }
        );

        return {
          equipment: plate,
          Autonomia: autonomy / fuellingsAmount,
        };
      }),
      'equipment'
    ).filter((item) => item.Autonomia);

    return mappedEquipments;
  }, [specificEquipmentFuellings]);

  const autonomyOdometerMean = useMemo(() => {
    const autonomySum = dataFuellings.reduce(
      (sum: number, b: ChartData) => sum + b.Autonomia,
      0
    );

    return !dataFuellings?.length ? 0 : autonomySum / dataFuellings.length;
  }, [dataFuellings]);

  const autonomyHorimeterMean = useMemo(() => {
    const autonomySum = dataEquipmentFuellings.reduce(
      (sum: number, b: ChartData) => sum + b.Autonomia,
      0
    );

    return !dataEquipmentFuellings?.length
      ? 0
      : autonomySum / dataEquipmentFuellings.length;
  }, [dataEquipmentFuellings]);

  return (
    <Container>
      <TitleContainer>
        <Title>
          Gráfico de Autonomia x
          {`${type === FUELLINGS ? ' Veículos' : ' Equipamentos'}`}
        </Title>
      </TitleContainer>
      <IndicatorContainer>
        <Indicator>
          Autonomia média:
          <Value>
            {type === FUELLINGS
              ? `${autonomyOdometerMean.toFixed(2)} km/l`
              : `${autonomyHorimeterMean.toFixed(2)} h/l`}
          </Value>
        </Indicator>
      </IndicatorContainer>
      {type === FUELLINGS ? (
        <VehicleChartCanvas data={dataFuellings} />
      ) : (
        <EquipmentChartCanvas data={[]} />
      )}
    </Container>
  );
}
