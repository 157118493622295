import { Col, Input, Row } from 'antd';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import { ErrorMessage, Field, Formik, FormikHelpers } from 'formik';
import { Checkbox, DatePicker, Form, Select } from 'formik-antd';
import React, { useContext, useMemo } from 'react';
import * as Yup from 'yup';
import { AuthContext } from '../../contexts/AuthProvider';
import { db } from '../../firebaseConfig';
import { useListAllVehiclesQuery } from '../../generated/graphql';
import { useTanks } from '../../helpers/hooks';
import { Fuelling } from '../../helpers/types';
import { Option } from './FuellingForm';
import { UpdateReferenceFuelling } from './UpdateReferenceFuelling';

interface Props {
  fuelling?: Fuelling;
  type?: 'EQUIPMENT' | 'VEHICLE';
  handleCloseModal: () => void;
}

export type FormValues = {
  id?: string;
  date?: string;
  vehicle: string;
  fuelAmount: number;
  odometer: number;
  horimeter: number;
  tank: string;
  filledUp: boolean;
  minOdometer: number;
  maxOdometer: number;
  recentOdometer: number;
};

const validationSchema = Yup.object().shape({
  date: Yup.string().required('Campo obrigatório'),
  vehicle: Yup.string().required('Campo obrigatório'),
  fuelAmount: Yup.number().required('Campo obrigatório'),
  odometer: Yup.number()
    .when(
      'minOdometer',
      (refOdometer: number, schema: { [key: string]: any }) =>
        schema.min(refOdometer, 'Insira um valor maior')
    )
    .when(
      'maxOdometer',
      (refOdometer: number = Infinity, schema: { [key: string]: any }) =>
        schema.max(refOdometer, 'Insira um valor menor')
    )
    .required('Campo obrigatório'),
  tank: Yup.string().required('Campo obrigatório'),
  filledUp: Yup.boolean().required('Campo obrigatório'),
});

export function TankFuellingForm(props: Props) {
  const { fuelling, type, handleCloseModal } = props;

  const { firebaseOrganizationId } = useContext(AuthContext);
  const { tanks } = useTanks();

  const [result] = useListAllVehiclesQuery();

  const { data } = result;

  const vehicles = data?.vehicle ?? [];

  const convertToFormValues = (values: Fuelling): FormValues => {
    const { car, ...rest } = values;
    return {
      ...rest,
      odometer: car.type !== 'equipment' ? rest.odometer : 0,
      horimeter: car.type === 'equipment' ? rest.horimeter : 0,
      date: rest.date.toISOString(),
      vehicle: car?.plate || '',
      tank: rest?.tank?.id || '',
      minOdometer: 0,
      maxOdometer: 0,
      recentOdometer: 0,
    };
  };

  const convertToFuelling = (values: FormValues): Fuelling => {
    const { vehicle, ...rest } = values;
    // SUPPORT TO OLDER FUELLINGS
    const car = vehicles.find(
      ({ id, plate }) => id === vehicle || plate === vehicle
    )!;

    return {
      ...rest,
      odometer: car.type !== 'equipment' ? rest.odometer : 0,
      horimeter: car.type === 'equipment' ? rest.odometer : 0,
      date: new Date(rest.date!),
      car,
      tank: tanks.find(({ id }) => id === values.tank),
    };
  };

  const initialValues: FormValues = useMemo(
    () =>
      fuelling
        ? convertToFormValues(fuelling)
        : {
            date: undefined,
            vehicle: '',
            fuelAmount: 0,
            horimeter: 0,
            odometer: 0,
            tank: '',
            filledUp: true,
            recentOdometer: 0,
            minOdometer: 0,
            maxOdometer: 0,
          },
    [fuelling]
  );

  const saveNewFuelling = async (fuelling: Fuelling) => {
    try {
      await db
        .collection('organizations')
        .doc(firebaseOrganizationId)
        .collection('fuellings')
        .add(fuelling);
    } catch (e) {
      console.error(e);
    }
  };

  const updateFuelling = async (fuelling: Fuelling) => {
    try {
      await db
        .collection('organizations')
        .doc(firebaseOrganizationId)
        .collection('fuellings')
        .doc(fuelling.id)
        .set(fuelling);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const newFuelling = convertToFuelling(values);
    if (fuelling) {
      updateFuelling(newFuelling);
    } else {
      saveNewFuelling(newFuelling);
    }
    setSubmitting(false);
    handleCloseModal();
  };

  // SUPPORT TO OLDER FUELLINGS
  const carOrEquipment = (vehiclePlateOrId: string) => {
    const selectedVehicle = vehicles.find(
      ({ id, plate }) => id === vehiclePlateOrId || plate === vehiclePlateOrId
    )!;

    return selectedVehicle?.type !== 'equipment' ? 'car' : 'equipment';
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form>
          <Row gutter={16}>
            <Col span={12}>
              <label className="label-form" htmlFor="date">
                Data do abastecimento:
              </label>
              <DatePicker
                name="date"
                showTime={true}
                style={{ width: '100%' }}
                allowClear={false}
                locale={locale}
                format="DD/MM/YYYY HH:mm"
              />
              <ErrorMessage name="date">
                {(errorMessage) => (
                  <div className="yup-error">{errorMessage}</div>
                )}
              </ErrorMessage>
            </Col>
            <Col span={12}>
              <label className="label-form" htmlFor="vehicle">
                Veículo:
              </label>
              <Select name="vehicle" showSearch style={{ width: '100%' }}>
                {vehicles.map((vehicle) => (
                  <Option key={vehicle.id} value={vehicle.plate}>
                    {vehicle.plate}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <UpdateReferenceFuelling vehicles={vehicles} />
          <Row gutter={16}>
            <Col span={12}>
              <label className="label-form" htmlFor="fuelAmount">
                Quantidade (L):
                <ErrorMessage name="fuelAmount">
                  {(errorMessage) => (
                    <div className="yup-error">{errorMessage}</div>
                  )}
                </ErrorMessage>
              </label>
              <Field
                name="fuelAmount"
                render={({ field }: any) => (
                  <Input {...field} type="number" placeholder="Ex.: 55 L" />
                )}
              />
            </Col>
            <Col span={12}>
              {carOrEquipment(values.vehicle as any) === 'car' ? (
                <>
                  <label className="label-form" htmlFor="odometer">
                    Odômetro:
                    <ErrorMessage name="odometer">
                      {(errorMessage) => (
                        <div className="yup-error">{errorMessage}</div>
                      )}
                    </ErrorMessage>
                  </label>
                  <Field
                    name="odometer"
                    render={({ field }: any) => (
                      <Input
                        {...field}
                        type="number"
                        placeholder="Ex.: 550000"
                      />
                    )}
                  />
                </>
              ) : (
                <>
                  <label className="label-form" htmlFor="odometer">
                    Horímetro:
                    <ErrorMessage name="odometer">
                      {(errorMessage) => (
                        <div className="yup-error">{errorMessage}</div>
                      )}
                    </ErrorMessage>
                  </label>
                  <Field
                    name="odometer"
                    render={({ field }: any) => (
                      <Input
                        {...field}
                        type="number"
                        placeholder="Ex.: 550000"
                      />
                    )}
                  />
                </>
              )}
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <label className="label-form" htmlFor="tank">
                Tanque:
                <ErrorMessage name="tank">
                  {(errorMessage) => (
                    <div className="yup-error">{errorMessage}</div>
                  )}
                </ErrorMessage>
              </label>
              <Select name="tank" showSearch style={{ width: '100%' }}>
                {tanks.map((tank) => (
                  // TODO: remove ts-ignore
                  // @ts-ignore
                  <Option key={tank.id} value={tank.id}>
                    {tank.name}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label className="label-form" htmlFor="value">
                Tanque cheio?:
              </label>
              <Checkbox name="filledUp">Tanque cheio?</Checkbox>
            </Col>
          </Row>

          <div className="footer-container">
            <button className="newFuelling" type="submit">
              Adicionar
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
