import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  height: 40vh;
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.75rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IndicatorContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
`;

export const Indicator = styled.p`
  font-size: 14px;
  color: gray;
  display: flex;
  margin-right: 1rem;
`;

export const Value = styled.span`
  margin-left: 0.5rem;
  color: black;
  font-weight: bold;
`;

export const Tooltip = styled.p`
  margin-bottom: 0;
  padding: 0.5rem;
`;
