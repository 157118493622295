import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Col, message, Modal, Popconfirm, Row, Table } from 'antd';
import { orderBy } from 'lodash';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import ReactExport from 'react-data-export';
import { ContainerTitle, Title } from '../../components/PageTitle/styles';
import { AuthContext } from '../../contexts/AuthProvider';
import { db } from '../../firebaseConfig';
import { useOrders, useTanks } from '../../helpers/hooks';
import OrderForm from './OrderForm';

const { Column } = Table;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const removeOrder = (organization, id) => {
  db.collection('organizations')
    .doc(organization)
    .collection('orders')
    .doc(id)
    .delete()
    .catch((error) => {
      console.error('Error removing document: ', error);
    });
};

const createOrder = (organization, order) => {
  db.collection('organizations')
    .doc(organization)
    .collection('orders')
    .add(order)
    .then(() => {
      db.collection('latestOrders').doc(organization).set({
        latestOrder: new Date(),
        organization: organization,
      });
    })
    .catch((error) => {
      console.error('Error creating document: ', error);
    });
};

const updateOrder = (organization, id, order) => {
  db.collection('organizations')
    .doc(organization)
    .collection('orders')
    .doc(id)
    .set(order)
    .then(() => {
      db.collection('latestOrders').doc(organization).set({
        latestFuelling: new Date(),
        organization: organization,
      });
    })
    .catch((error) => {
      console.error('Error creating document: ', error);
    });
};

const Orders = () => {
  const [modal, setModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(undefined);

  const { firebaseOrganizationId } = useContext(AuthContext);

  const { orders, loading } = useOrders(firebaseOrganizationId);
  const { tanks } = useTanks(firebaseOrganizationId);

  const handleModalClose = () => {
    setModal(false);
    setSelectedOrder(undefined);
  };

  const handleEdit = (order) => {
    setModal(true);
    setSelectedOrder(order);
  };

  const handleDelete = (id) => removeOrder(firebaseOrganizationId, id);

  const handleCreate = (values, { resetForm }) => {
    const order = {
      ...values,
      invoiceDate: new Date(values.invoiceDate),
      tank: tanks.find(({ id }) => id === values.tank),
    };

    createOrder(firebaseOrganizationId, order);
    resetForm();
    message.success('Compra salva com sucesso.');
    handleModalClose();
  };

  const handleUpdate = (values, { resetForm }) => {
    const { id, ...rest } = values;
    const order = {
      ...rest,
      invoiceDate: new Date(values.invoiceDate),
      tank: tanks.find(({ id }) => id === values.tank),
    };

    updateOrder(firebaseOrganizationId, id, order);
    resetForm();
    message.success('Compra atualizada com sucesso.');
    handleModalClose();
  };

  const handleSubmit = selectedOrder ? handleUpdate : handleCreate;

  const descOrders = orderBy(orders, ['invoiceDate'], ['desc']);

  return (
    <div>
      <ContainerTitle>
        <Title>Compras</Title>
        <div>
          <ExcelFile
            filename="INTERAKT - Abastecimentos"
            element={<button className="exportData">Exportar dados</button>}
          >
            <ExcelSheet data={descOrders} name="Abastecimentos">
              <ExcelColumn
                label="Data"
                value={(col) => moment(col.date).format('DD/MM/YY')}
              />
              <ExcelColumn label="N° da nota" value="invoice" />
              <ExcelColumn label="Data de compra" value="invoiceDate" />
              <ExcelColumn label="Valor (R$)" value="cost" />
              <ExcelColumn label="Quantidade (L)" value="quantity" />
            </ExcelSheet>
          </ExcelFile>
          <button onClick={() => setModal(true)} className="submitButton">
            Nova compra
          </button>
        </div>
      </ContainerTitle>

      <Row>
        <Col span={24}>
          <Table
            rowKey={({ id }) => id}
            pagination={false}
            loading={loading}
            dataSource={descOrders}
          >
            <Column title="N° da nota" dataIndex="invoice" key="invoice" />
            <Column
              title="Data de compra"
              dataIndex="invoiceDate"
              key="invoiceDate"
              render={(_, record) =>
                moment(record.invoiceDate).format('DD/MM/YY')
              }
            />
            <Column title="Valor (R$)" dataIndex="cost" key="cost" />
            <Column
              title="Quantidade (L)"
              dataIndex="quantity"
              key="quantity"
            />
            <Column
              title="Ação"
              key="action"
              render={(_, record) => (
                <>
                  <EditFilled
                    style={{
                      cursor: 'pointer',
                      color: '#000',
                      marginRight: '0.5rem',
                    }}
                    onClick={() => handleEdit(record)}
                  />
                  <Popconfirm
                    title="Confirmar remoção?"
                    onConfirm={(_) => handleDelete(record.id)}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <DeleteFilled style={{ cursor: 'pointer' }} />
                  </Popconfirm>
                </>
              )}
            />
          </Table>
        </Col>
      </Row>
      <Modal
        visible={modal}
        onCancel={handleModalClose}
        title={selectedOrder ? 'Editar uma compra' : 'Nova compra'}
        footer={null}
      >
        <OrderForm handleSubmit={handleSubmit} selectedOrder={selectedOrder} />
      </Modal>
    </div>
  );
};
// }

export default Orders;
