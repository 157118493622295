import styled from 'styled-components';

export const Subtitle = styled.h2`
  font-size: 1rem;
  margin-bottom: 0;
`;

export const ContainerSubtitle = styled.div`
  display: flex;
  justify-content: start;
  justify-items: center;
  padding-bottom: 0.5rem;
  width: 100%;
  margin-top: 1.75rem;
  margin-bottom: 0.5rem;
`;
