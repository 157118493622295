import { Dropdown } from 'antd';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { FaChevronDown, FaGasPump } from 'react-icons/fa';
import {
  useFuellings,
  useOrders,
  useTanks,
  useTransfers,
} from '../../../../helpers/hooks';
import {
  CardContainer,
  CardSubcontainer,
  CardTitle,
  CardNumber,
  CardTrigger,
} from './styles';

export const CardStock = () => {
  const { tanks } = useTanks();
  const { transfers } = useTransfers();
  const { fuellings } = useFuellings();
  const { orders } = useOrders();

  const fuellingsByTank = (tankId) => {
    return fuellings
      .filter(({ tank }) => tank && tank.id === tankId)
      .map(({ fuelAmount }) => fuelAmount)
      .reduce((a, b) => a + b, 0);
  };

  const ordersByTank = (tankId) => {
    return orders
      .filter((order) => (order && order.tank && order.tank.id) === tankId)
      .map(({ quantity }) => quantity)
      .reduce((a, b) => a + b, 0);
  };

  const transfersByTank = (tankId) => {
    const inward = transfers
      .filter((t) => t?.destination?.id === tankId)
      .map(({ amount }) => amount)
      .reduce((a, b) => a + b, 0);

    const outward = transfers
      .filter((t) => t?.origin?.id === tankId)
      .map(({ amount }) => amount)
      .reduce((a, b) => a + b, 0);

    return inward - outward;
  };

  const tanksWithStock = tanks.map((tank) => ({
    ...tank,
    stock:
      tank.initialStock +
      ordersByTank(tank.id) -
      fuellingsByTank(tank.id) +
      transfersByTank(tank.id),
  }));

  const stock = tanksWithStock
    .map(({ stock }) => stock)
    .reduce((sum, tankStock) => sum + tankStock, 0);

  return (
    <CardContainer>
      <div>
        <CardTitle>Estoque</CardTitle>
      </div>

      <CardSubcontainer>
        <Dropdown
          overlay={tanksList(tanksWithStock)}
          trigger={['click']}
          placement="bottomLeft"
        >
          <CardTrigger>
            <FaChevronDown style={{ color: '#7b7b7b' }} />
          </CardTrigger>
        </Dropdown>

        <CardNumber style={{ color: '#404244' }} className="text-right">
          {stock < 0 || stock === Infinity ? (
            <span>Erro </span>
          ) : (
            <CurrencyFormat
              value={stock}
              displayType={'text'}
              thousandSeparator={'.'}
              prefix={''}
              decimalSeparator={','}
            />
          )}
          <span style={{ fontSize: '1.25rem' }}> L</span>
        </CardNumber>
      </CardSubcontainer>
    </CardContainer>
  );
};

const tanksList = (tanks) => (
  <div className="apps">
    <ul className="app-list">
      <p className="app-instruction">Verifique seu estoque parcial</p>
      {tanks.map((tank, index) => (
        <li key={index} className="app-item">
          <div className="app-item-container">
            <FaGasPump
              style={{
                fontSize: '1.5rem',
                color: '#001132',
              }}
            />
            <span className="app-link">
              {tank.name} -{' '}
              <span>
                <strong>{tank.stock + ' L'}</strong>
              </span>
            </span>
          </div>
        </li>
      ))}
    </ul>
  </div>
);
