import React from 'react';
import { CardStock } from './CardStock';
import { useTanks } from '../../../../helpers/hooks';
import { useListAllVehiclesQuery } from '../../../../generated/graphql';
import { Row, Col } from 'antd';
import CardVehicles from './CardVehicles';

export default function Cards() {
  const { tanks } = useTanks();

  return (
    <div>
      {!!tanks.length && (
        <Row gutter={16}>
          <Col span={12}>
            <CardVehicles />
          </Col>
          <Col span={12}>
            <CardStock />
          </Col>
        </Row>
      )}
    </div>
  );
}
