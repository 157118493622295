import { FaFileExcel } from 'react-icons/fa';
import styled from 'styled-components';

export const Export = styled.span`
  font-size: 0.75rem;
  color: #1890ff;
  margin-left: 1rem;
  cursor: pointer;
`;

export const Icon = styled(FaFileExcel)`
  margin-right: 0.25rem;
`;

export const Title = styled.h3`
  margin-bottom: 1rem;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;
