import React, { Component } from 'react';
import { Input, Row, Col, Modal } from 'antd';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SubmitButton } from './styles';

import { db } from '../../firebaseConfig';

export default function NewProvider(props) {
  const { visible, handleClose, editableProvider, organization } = props;

  const handleSubmit = async (values) => {
    console.log(editableProvider);
    console.log(values);
    try {
      if (editableProvider) {
        const { id, ...rest } = values;
        console.log('rest', rest);
        await db
          .collection('organizations')
          .doc(organization)
          .collection('providers')
          .doc(id)
          .set(rest);
      } else {
        await db
          .collection('organizations')
          .doc(organization)
          .collection('providers')
          .add(values);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const ProviderSchema = Yup.object().shape({
    CNPJ: Yup.number()
      .typeError('Apenas números são permitidos')
      .required('Campo obrigatório'),
    address: Yup.string().required('Campo obrigatório'),
    company: Yup.string().required('Campo obrigatório'),
    trade: Yup.string().required('Campo obrigatório'),
  });

  return (
    <div>
      <Formik
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          await handleSubmit(values);
          setSubmitting(false);
          resetForm({});
          handleClose();
        }}
        validationSchema={ProviderSchema}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={
          editableProvider ?? {
            CNPJ: null,
            address: null,
            company: null,
            trade: null,
          }
        }
      >
        {({ handleSubmit }) => (
          <Modal
            visible={visible}
            onCancel={handleClose}
            title={editableProvider ? 'Editar fornecedor' : 'Novo fornecedor'}
            footer={
              <div>
                <SubmitButton onClick={handleSubmit}>Submeter</SubmitButton>
              </div>
            }
          >
            <Form>
              <Row gutter={16}>
                <Col span={24}>
                  <label className="label-form" htmlFor="company">
                    Razão Social:
                    <ErrorMessage name="company">
                      {(errorMessage) => (
                        <div className="yup-error">{errorMessage}</div>
                      )}
                    </ErrorMessage>
                  </label>

                  <Field
                    name="company"
                    render={({ field /* _form */ }) => (
                      <Input
                        {...field}
                        placeholder="Ex.: Continental Pneus LTDA"
                      />
                    )}
                  />
                </Col>
              </Row>
              <br />
              <Row gutter={16}>
                <Col span={12}>
                  <label className="label-form" htmlFor="trade">
                    Nome fantasia:
                    <ErrorMessage name="trade">
                      {(errorMessage) => (
                        <div className="yup-error">{errorMessage}</div>
                      )}
                    </ErrorMessage>
                  </label>
                  <Field
                    name="trade"
                    render={({ field /* _form */ }) => (
                      <Input {...field} placeholder="Ex.: Continental" />
                    )}
                  />
                </Col>
                <Col span={12}>
                  <label className="label-form" htmlFor="CNPJ">
                    CNPJ:
                    <ErrorMessage name="CNPJ">
                      {(errorMessage) => (
                        <div className="yup-error">{errorMessage}</div>
                      )}
                    </ErrorMessage>
                  </label>
                  <Field
                    name="CNPJ"
                    render={({ field /* _form */ }) => (
                      <Input {...field} placeholder="Ex.:11111111111111" />
                    )}
                  />
                </Col>
              </Row>
              <br />
              <Row gutter={16}>
                <Col span={24}>
                  <label className="label-form" htmlFor="address">
                    Endereço:
                    <ErrorMessage name="address">
                      {(errorMessage) => (
                        <div className="yup-error">{errorMessage}</div>
                      )}
                    </ErrorMessage>
                  </label>
                  <Field
                    name="address"
                    render={({ field /* _form */ }) => (
                      <Input
                        {...field}
                        placeholder="Ex.: Rua João Gomes 751, 4584120, Bloco 2"
                      />
                    )}
                  />
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Formik>
    </div>
  );
}
