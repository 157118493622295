import { Col, Popconfirm, Row, Table } from 'antd';
import React, { useState } from 'react';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { ContainerTitle, Title } from '../../components/PageTitle/styles';
import { db } from '../../firebaseConfig';
import ProviderForm from './ProviderForm';
import { useProviders } from '../../helpers/hooks';

export default function Providers(props) {
  const { organization } = props;

  const [visible, setVisible] = useState(false);
  const [editableProvider, setEditableProvider] = useState(null);

  const { providers } = useProviders();

  const handleCloseForm = () => {
    setVisible(false);
    setEditableProvider(null);
  };

  const handleShowForm = () => {
    setVisible(true);
  };

  const handleDelete = async (deletableProvider) => {
    const id = deletableProvider.id;
    await db
      .collection('organizations')
      .doc(organization)
      .collection('providers')
      .doc(id)
      .delete()
      .catch((err) => console.log(err));
  };

  //Deixa em ordem alfabética
  const orderedProviders = providers
    .sort((a, b) => {
      const textA = a.company.toUpperCase();
      const textB = b.company.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    })
    .map((provider, index) => ({
      ...provider,
      key: index + 1,
    }));

  const columnsTable = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Razão Social',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Nome Fantasia',
      dataIndex: 'trade',
      key: 'trade',
    },
    {
      title: 'CNPJ',
      dataIndex: 'CNPJ',
      key: 'CNPJ',
    },
    {
      title: 'Endereço',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Ações',
      key: 'action',
      render: (text, record) => {
        return (
          <>
            <EditFilled
              style={{ marginRight: '0.5rem' }}
              onClick={() => {
                setVisible(true);
                setEditableProvider(record);
              }}
            />
            <Popconfirm title="Deseja excluir este fornecedor?" onConfirm={() => handleDelete(record)}>
              <DeleteFilled />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <ContainerTitle>
        <Title>Fornecedores</Title>
        <div>
          <button onClick={handleShowForm} className="submitButton">
            Novo fornecedor
          </button>
        </div>
      </ContainerTitle>
      <Row>
        <Col span={24}>
          <Table
            pagination={false}
            dataSource={orderedProviders}
            columns={columnsTable}
          />
        </Col>
      </Row>
      {visible ? (
        <ProviderForm
          organization={organization}
          visible={visible}
          handleClose={handleCloseForm}
          editableProvider={editableProvider}
        />
      ) : null}
    </div>
  );
}
