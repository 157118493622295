import styled from 'styled-components';

export const SubmitButton = styled.button`
  background-color: rgb(50, 182, 80);
  padding: 12px;
  font-size: 14px;
  border: 1px solid rgb(46, 165, 73);
  border-radius: 4px;
  color: #fff;
`;
