import {
  BankOutlined,
  BarsOutlined, CarFilled,
  ContainerOutlined,
  FileOutlined, HomeFilled
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const ContainerItem = styled.div`
  display: flex;
  align-items: center;
`;

export default function Sidebar() {
  const router = useLocation();

  const [collapsed, setCollapsed] = useState(false);

  const pathname = router.pathname;

  // TODO: Add drivers integrated with core
  return (
    <Layout.Sider
      data-testid="menu"
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
    >
     <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['/']}
        selectedKeys={[pathname]}
      >
        <Menu.Item key="/">
          <Link to="/">
            <ContainerItem>
              <HomeFilled />
              <span>Página inicial</span>
            </ContainerItem>
          </Link>
        </Menu.Item>
        <Menu.Item key="/fuellings">
          <Link to="/fuellings">
            <ContainerItem>
              <BarsOutlined />
              <span>Abastecimentos</span>
            </ContainerItem>
          </Link>
        </Menu.Item>
        <Menu.Item key="/orders">
          <Link to="/orders">
            <ContainerItem>
              <FileOutlined />
              <span>Compras</span>
            </ContainerItem>
          </Link>
        </Menu.Item>
        <Menu.Item key="/tanks">
          <Link to="/tanks">
            <ContainerItem>
              <ContainerOutlined />
              <span>Tanques</span>
            </ContainerItem>
          </Link>
        </Menu.Item>
        <Menu.Item key="/providers">
          <Link to="/providers">
            <ContainerItem>
              <BankOutlined />
              <span>Fornecedores</span>
            </ContainerItem>
          </Link>
        </Menu.Item>
        <Menu.Item key="/vehicles-and-equipments">
          <Link to="/vehicles-and-equipments">
            <ContainerItem>
              <CarFilled />
              <span>Ativos</span>
            </ContainerItem>
          </Link>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
}
