import { Col, Row } from 'antd';
import React from 'react';
import { FaArrowLeft, FaTruck } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Subtitle, Title } from '../../components/DetailPageTitle/styles';
import { useGetVehicleByIdQuery } from '../../generated/graphql';

const Info = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

export default function DetailVehicle() {
  const history = useHistory();
  const { id } = useParams() as { id?: string };

  const [{ data }] = useGetVehicleByIdQuery({
    variables: {
      id,
    },
  });

  const vehicle = data?.vehicle_by_pk;

  console.log(vehicle);

  if (!vehicle) return <p>Carregando...</p>;

  return (
    <div>
      <div className="arrow-left">
        <span style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
          <FaArrowLeft style={{ fontSize: '2em', color: '#AAA' }} />
        </span>
      </div>
      <Title>Detalhes do veículo</Title>
      <Row gutter={20}>
        <Col span={8}>
          <Subtitle>
            <FaTruck style={{ marginRight: '0.5rem' }} />
            Informações gerais
          </Subtitle>
          <div className="detail-info-container">
            <div className="detail-main-info">
              <label className="detail-label">Placa</label>
              <Info>{vehicle?.plate}</Info>
              <label className="detail-label">Tipo</label>
              <Info>{vehicle?.type}</Info>
            </div>
            <div className="secondary-info">
              <label className="detail-label">Marca</label>
              <Info>{vehicle?.brand}</Info>
              <label className="detail-label">Modelo</label>
              <Info>{vehicle?.model}</Info>
              <label className="detail-label">Ano</label>
              <Info>{vehicle?.year}</Info>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
