import React from 'react';
import { Layout } from 'antd';
import styles from './Content.module.css';
import Sidebar from './Sidebar';
import Header from './Header';

export default function Content(props: any) {
  return (
    <Layout className={styles.container}>
      <Header />
      <Layout>
        <Sidebar />
        <Layout>
          <Layout.Content className={styles.content}>{props.children}</Layout.Content>
          <Layout.Footer style={{ textAlign: 'center' }}>
            <a href="https://infleet.com.br" className={styles.footer}>
              © 2020 Infleet | Tecnologia para frotas
            </a>
          </Layout.Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}
